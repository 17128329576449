import API from 'services/api'

// A rather ugly way of doing initial user login.
// I don't love these chained .then statements so this should be refactored.

const Session = {
  login(
    username,
    password,
    rememberMe,
    appBased2Fa,
    token,
    loginErrorCallback,
    twoFASuccessCallback,
    twoFAFailureCallback,
    twoFARegistrationCallback,
    successfulRedirectURL = '/',
    redirectOnSuccess,
  ) {
    if (token === '') {
      // this is the case where a non-2fa user is attempting to login, OR the first step of a 2fa login
      return API.auth
        .login(username, password, rememberMe)
        .then((response) => {
          const { access, refresh, twoFa, needsAuthyRegistration } = response.data
          if (!twoFa || needsAuthyRegistration) {
            localStorage.setItem('accessToken', access)
            localStorage.setItem('refreshToken', refresh)
          }
          return response
        })
        .then((response) => {
          const { twoFa, needsAuthyRegistration, appBased2Fa, rememberMe } = response.data
          if (needsAuthyRegistration === true) {
            const { userPhone } = response.data
            twoFARegistrationCallback(userPhone)
          } else {
            let lastPage = localStorage.getItem('redirect') || '/'
            if (redirectOnSuccess !== false) {
              twoFa
              ? twoFASuccessCallback(rememberMe, appBased2Fa)
              : window.location.replace(lastPage)
            }
          }
        })
        .catch((error) => {
          const { status } = error.response

          if (status === 401 || status === 429) {
            loginErrorCallback(status)
          } else if (status === 503) {
            twoFAFailureCallback()
          }
        })
    } else {
      // this is the case where a 2fa user is inputting their code for step 2.
      return API.auth
        .verifyToken(username, password, rememberMe, token)
        .then((response) => {
          const { access, refresh } = response.data
          localStorage.setItem('accessToken', access)
          localStorage.setItem('refreshToken', refresh)
        })
        .then(() => {
          window.location.replace(successfulRedirectURL)
        })
        .catch(() => {
          // Could be either 401 incorrect code or 400 bad request.
          // Here we just throw a general error (for now)
          loginErrorCallback()
        })
    }
  },

  logout() {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
  },
}

export default Session
