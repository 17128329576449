const styles = {
    Animation: {
        transitionStyles: `
            -moz-transition: all .2s ease-in;
            -o-transition: all .2s ease-in;
            -webkit-transition: all .2s ease-in;
            transition: all .2s ease-in;
        `
    },
    ColumnGrid: {
        MarginContainer: `
            /* 16px fixed up to 480px */
            @media screen and (max-width: 480px) {
                padding: 0 16px;
            }
            /* 40px fixed from 481px - 992px */
            @media screen and (min-width: 481px) and (max-width: 992px) {
                padding: 0 40px;
            }
            /* 80px fixed from 993px - 1440px */
            @media screen and (min-width: 993px) and (max-width: 1440px) {
                padding: 0 80px;
            }
            /* flexible margins above 1440px, reserving 1280px between margins for content */
            @media screen and (min-width: 1441px) {
                padding: 0 calc(calc(100dvw - 1280px) / 2);
            }
        `,
        ColumnWidth: `
            /* up to 480px: (Device width - (2 * 16px margins) - (3 * 16px gutters)) / 4 columns */
            @media screen and (max-width: 480px) {
                width: calc(calc(100dvw - 32px - 48px) / 4);
            }
            /* 481px - 992px: (Device width - (2 * 40px margins) - (11 * 16px gutters)) / 12 columns */
            @media screen and (min-width: 481px) and (max-width: 992px) {
                width: calc(calc(100dvw - 80px - 176px) / 12);
            }
            /* 993px - 1440px: (Device width - (2 * 80px margins) - (11 * 16px gutters)) / 12 columns */
            @media screen and (min-width: 993px) and (max-width: 1440px) {
                width: calc(calc(100dvw - 160px - 176px) / 12);
            }
            /* 92px fixed column width above 1440px */
            @media screen and (min-width: 1441px) {
                width: 92px;
            }
        `,
        Gutter: `
            width: 16px;
        `
    },
    MediaQueries: {
        Mobile: `@media screen and (max-width: 768px)`,
        Tablet: `@media screen and (max-width: 992px) and (min-width: 768px)`,
        Desktop: `@media screen and (min-width: 768px)`,
        Print: `@media print`,
    },
    Spacing: {
        XXXS: '2px',
        XXS: '5px',
        XS: '10px',
        S: '20px',
        M: '40px',
        L: '60px',
    },
    Scrollbar: {
        transparent: `
            &::-webkit-scrollbar { display: none; }
            &::-webkit-scrollbar {
                height: 0;
                width: 0;
            }
            &::-webkit-scrollbar-thumb {
                background-color: transparent;
            }
        `,
        defaultScrollbarStyles: `
            &::-webkit-scrollbar {
                width: 0.4em;
                height: 0.4em;
                background-color: #D9D9D9;
                @media print {
                    display: none;
                }
            }
            &::-webkit-scrollbar-thumb {
                background-color: #7C3D76;
                border: 4px solid transparent;
                border-radius: 16px;
                @media print {
                    display: none;
                }
            }
        `,
        TaekusGrey: `
            &::-webkit-scrollbar {
                width: 4px;
                background-color: #F9F9F9;
                @media print {
                    display: none;
                }
            }
            &::-webkit-scrollbar-thumb {
                background-color: #E9E9E9;
                border: 4px solid transparent;
                @media print {
                    display: none;
                }
            }
        `
    },
    BorderRadius: {
        S: '2px',
        M: '4px',
        L: '8px',
        Half: '50%',
    },
    Font: {
        Size: {
            Small: '14px',
            Medium: '32px',
            Large: '42px',
        },
        Weight: {
            400: '400',
            500: '500',
            600: '600'
        },
        Family: {
            MonumentGrotesk: "Monument Grotesk",
            MonumentGroteskMono: "'Monument Grotesk Mono'"
        }
    },
    Text: {
        BodySmall: `
            font-family: 'Monument Grotesk';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
            /* identical to box height, or 20px */
            letter-spacing: 0.02em;
            color: #0E0E0E;
        `,
        BodyLarge: `
            font-family: 'Monument Grotesk';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 138%;
            /* identical to box height, or 22px */
            text-align: center;
            letter-spacing: 0.02em;
        `,
        DisplaySmall: `
            font-family: 'Monument Grotesk';
            font-style: normal;
            font-weight: 400;
            font-size: 28px;
            line-height: 127%;
            /* identical to box height, or 36px */
            letter-spacing: 0.01em;
            color: #000000;        
        `,
        DisplayMedium: `
            font-family: 'Monument Grotesk';
            font-style: normal;
            font-weight: 400;
            font-size: 32px;
            line-height: 124%;
            /* identical to box height, or 40px */
            letter-spacing: 0.01em;
        `,
        DisplayLarge: `
            font-family: 'Monument Grotesk';
            font-style: normal;
            font-weight: 400;
            line-height: 124%;
            /* or 52px */
            letter-spacing: 0.01em;
            @media (max-width: 768px) { 
                font-size: 2em;
            }
            
            @media (min-width: 768px) {
                font-size: 42px;
            }
        `,
        HeadingMedium: `
            font-family: 'Monument Grotesk';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 138%;
            /* identical to box height, or 28px */
            letter-spacing: 0.02em;
        `,
    },
    Color: {
        Black: '#000000',
        GoldStar: '#FFC702',
        Grey: '#D9D9D9',
        GreyText: '#BFBFBF',
        NearBlack: '#0E0E0E',
        Transparent: 'rgba(0,0,0,0)',
        White: '#FFFFFF',
        SuccessGreen: '#00BF0C',
        FailureRed: '#FF0000',
        TaekusBlack: '#010202',
        TaekusBlue: '#1A2278',
        TaekusCream: '#F9F8F5',
        TaekusGreen: '#71E8BD',
        TaekusGreenText: '#157A55',
        TaekusGrey1: '#4D4D4D',
        TaekusGrey2: '#787878',
        TaekusGrey3: '#999898',
        TaekusGrey4: '#E9E9E9',
        TaekusGrey5: '#F9F9F9',
        TaekusPurple: '#7C3D76',
        TaekusRed: '#C91616',
        TaekusSeaFoam: '#F7927C',
    }
}

export default styles;