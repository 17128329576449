import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { styled } from "styled-components";

import { ScreenSize } from "redux/features/global/types";
import { Actions as TripActions } from 'redux/features/trips'

import Button, { ButtonSize, ButtonType } from "components/common/Button";
import Modal from "components/common/Modal"
import Spinner from "components/common/Spinner";

import { Trip } from "components/pages/Trips/types";

import styles from "styles/styles";

import { ReactComponent as TaekusIcon } from "assets/svg/TaekusIcon.svg";

type CancelFlightModalProps = {
    trip: Trip,
    onClose: () => void,
}

const CancelFlightModal = (props: CancelFlightModalProps) => {
    const { trip, onClose } = props;
    
    const dispatch = useDispatch()

    const isMobile = useSelector((state: any) => state.global.screenSize) === ScreenSize.Mobile
    const isCostLoading = useSelector((state: any) => state.trips.cancel.isCostLoading)
    const isCostError = useSelector((state: any) => state.trips.cancel.isCostError)
    const cancelCost = useSelector((state: any) => state.trips.cancel.data)
    const isTripCancelled = useSelector((state: any) => state.trips.cancel.success)

    const isButtonDisabled = isCostLoading || isCostError

    const cancelFlight = () => {
        dispatch(TripActions.cancelTrip({
            uuid: trip.uuid,
            cancelType: 'refund'
        }))
    }

    const fetchCancelCost = () => {
        dispatch(TripActions.fetchRefundCost({
            uuid: trip.uuid,
        }))
    }

    useEffect(() => {
        fetchCancelCost()
    }, []) // eslint-disable-line

    return <Modal title='Cancel Flight' onClose={onClose}>
        <Container>
            {isTripCancelled ? <SuccessContainer>
                You’ve successfully submitted your cancel request.
            </SuccessContainer> : <>
                <Terms>
                    <TermsText>
                        <div>Please confirm your cancellation.</div>
                        <div>By proceeding, you acknowledge the terms: full refunds are typically available if canceled within 24 hours of booking; otherwise, a fee may apply.</div>
                    </TermsText>
                    {!isMobile && <ButtonContainer>
                        <Button
                            onClick={cancelFlight}
                            buttonType={ButtonType.Purple}
                            size={ButtonSize.Auto}
                            label="Process Cancelation"
                            disabled={isButtonDisabled}
                        />
                    </ButtonContainer>}
                </Terms>
                {isCostLoading ? <SpinnerContainer>
                    <Spinner size={40} />
                </SpinnerContainer> : <CostBreakdown>
                    {isCostError ? <IneligibleContainer>This trip is not eligible for cancelation.</IneligibleContainer> : <>
                        <CostItemsContainer>
                            <CostItem>
                                <div>Fare</div>
                                <PointsContainer>
                                    <StyledTaekusIcon/>
                                    <div>{(cancelCost?.refundableAmountPoints + cancelCost?.penaltyPoints).toLocaleString()} pts</div>
                                </PointsContainer>
                            </CostItem>
                            {!!cancelCost?.penaltyPoints && <CostItem>
                                <div>Cancellation Fee</div>
                                <div>-{cancelCost?.penaltyPoints?.toLocaleString()} pts</div>
                            </CostItem>}
                        </CostItemsContainer>
                        <Total>
                            <div>Total</div>
                            <div>{cancelCost?.refundableAmountPoints?.toLocaleString()} pts</div>
                        </Total>
                    </>}
                </CostBreakdown>}
                {isMobile && <ButtonContainer>
                    <Button 
                        onClick={cancelFlight}
                        buttonType={ButtonType.Purple}
                        size={ButtonSize.Auto}
                        label="Process Cancelation"
                        disabled={isButtonDisabled}
                    />
                </ButtonContainer>}
            </>}
        </Container>
    </Modal>
}

const IneligibleContainer = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    opacity: 0.6;
`

const SuccessContainer = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
`

const SpinnerContainer = styled.div`
    flex: 1;
    padding-left: 40px;
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const PointsContainer = styled.div`
    display: flex;
    align-items: center;
`

const TermsText = styled.div`
    margin-bottom: 24px;
`

const StyledTaekusIcon = styled(TaekusIcon)`
    margin-right: 8px;
    height: 12px;
    width: auto;
`

const Total = styled.div`
    margin-top: 24px;
    padding-top: 16px;
    border-top: 1px solid ${styles.Color.NearBlack};
    font-weight: 500;
    display: flex;
    justify-content: space-between;
`

const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 42px;
    ${styles.MediaQueries.Mobile} {
        margin-top: 16px;
    }
`

const Terms = styled.div`
    flex: 1;
    overflow: hidden;
    text-wrap: wrap;
    padding-right: 40px;
    border-right: 1px solid ${styles.Color.Grey};
    ${styles.MediaQueries.Mobile} {
        border-right: 1px solid transparent;
        border-bottom: 1px solid ${styles.Color.Grey};
        padding: 0;
    }
`

const CostItemsContainer = styled.div`
    flex: 1;
`

const CostItem = styled.div`
    display: flex;
    justify-content: space-between;
    &:not(&:last-child) {
        margin-bottom: 8px;
    }
`

const CostBreakdown = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;
    overflow: hidden;
    text-wrap: wrap;
    padding-left: 40px;
    ${styles.MediaQueries.Mobile} {
        flex: none;
        padding: 0;
        margin-top: 24px;
        min-height: min-content;
    }
`

const Container = styled.div`
    flex: 1;
    display: flex;
    max-width: 584px;
    margin-top: 15px;
    color: ${styles.Color.NearBlack};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.28px;
    ${styles.MediaQueries.Mobile} {
        max-width: none;
        flex-direction: column;
    }
`

export default CancelFlightModal