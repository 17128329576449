import React from "react"

import { styled } from "styled-components"

import styles from "styles/styles"

import { ReactComponent as BasicArrowRight } from "assets/svg/BasicArrowRight.svg";

export enum ArrowDirection {
    Up,
    Down,
    Left,
    Right
}

type ArrowButtonProps = {
    direction: ArrowDirection,
    onClick: any,
}

const ArrowButton = (props: ArrowButtonProps) => {
    return <Container type='button' onClick={props.onClick}>
        <StyledArrowIcon direction={props.direction} />
    </Container>
}

const getRotationFromDirection = (direction: ArrowDirection) => {
    switch (direction) {
        case ArrowDirection.Up:
            return '270deg'
        case ArrowDirection.Down:
            return '90deg'
        case ArrowDirection.Left:
            return '180deg';
        case ArrowDirection.Right:
        default:
            return 0;
    }
}

type StyledArrowIconProps = {
    direction: ArrowDirection,
}

const StyledArrowIcon = styled(BasicArrowRight)<StyledArrowIconProps>`
    width: 24px;
    height: 24px;
    stroke: #4D4D4D;
    transform: rotateZ(${props => getRotationFromDirection(props.direction)});
`

const Container = styled.button`
    width: 40px;
    height: 40px;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    border: 1px solid ${styles.Color.TaekusGrey4};
    background: #FFF;
    outline-offset: 1px;
    &:hover {
        box-shadow: 0px 0px 16px 0px rgba(124, 61, 118, 0.15);
    }
    &:focus {
        outline: 1px solid ${styles.Color.TaekusPurple};
    }
    -moz-transition: box-shadow .2s ease-in;
    -o-transition: box-shadow .2s ease-in;
    -webkit-transition: box-shadow .2s ease-in;
    transition: box-shadow .2s ease-in;
`

export default ArrowButton