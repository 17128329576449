import { useEffect, useState } from 'react';

const KONAMI_CODE = [
  'ArrowUp',
  'ArrowUp',
  'ArrowDown',
  'ArrowDown',
  'ArrowLeft',
  'ArrowRight',
  'ArrowLeft',
  'ArrowRight',
  'b',
  'a',
  'Enter',
]

export const useKonamiCode = () => {
  const [inputIndex, setInputIndex] = useState(0)
  const [hasFailed, setHasFailed] = useState(false)
  const [hasWon, setHasWon] = useState(false)

  useEffect(() => {
    if (hasFailed) return;

    const handleKeyDown = (e: any) => {
      const key = e.key;

      if (key === KONAMI_CODE[inputIndex]) {
        if (inputIndex === KONAMI_CODE.length - 1) {
          setHasWon(true)
        }

        setInputIndex(inputIndex + 1)
      } else {
        setHasFailed(true)
        window.removeEventListener('keydown', handleKeyDown)
      }
    }

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [inputIndex, hasFailed]);
  
  return hasWon;
};