import React, { useEffect } from "react"

import { styled } from "styled-components"

import StepContainer, { getStepContainerMotionProps } from "components/signup/StepContainer";
import { StepDirection } from "components/signup/types";

import styles from "styles/styles";
import { useDispatch, useSelector } from "react-redux";
import { onboardUser } from "../signupSlice";

type VerifyEmailProps = {
}

const VerifyEmail = (props: VerifyEmailProps) => {
    const dispatch = useDispatch()

    const application = useSelector((state: any) => state.signup.application)
    const offerUuid = useSelector((state: any) => state.signup.offer?.uuid)
    const stepDirection = useSelector((state: any) => state.signup.direction)

    useEffect(() => {
        dispatch(onboardUser({
            uuid: offerUuid,
            username: application.username,
            password: application.password,
            cardHolderAgreementAcknowledgedUrl: 'https://google.com',
            creditTermsAcknowledgedUrl: 'https://google.com',
            debitTermsAcknowledgedUrl: 'https://google.com',
            electronicFundsTransferAcknowledgedUrl: 'https://google.com',
            electronicDisclosureAcknowledgedUrl: 'https://google.com',
            rewardTermsAcknowledgedUrl: 'https://google.com',
            privacyPolicyAcknowledgedUrl: 'https://google.com',
        }))
    }, [dispatch]) // eslint-disable-line

    return <StepContainer
        {...getStepContainerMotionProps(stepDirection)}
        key='VerifyEmail'
    >
        <div className="d-flex flex-column align-items-center">
            <Title>We sent you an email</Title>
            <Text>Please click on the link in your email to confirm your email address.</Text>
            <Retry>Didn’t receive an email?</Retry>
        </div>
    </StepContainer>
}

const Retry = styled.div`
    color: ${styles.Color.TaekusPurple};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 138%; /* 22.08px */
    letter-spacing: 0.32px;
    cursor: pointer;
`

const Text = styled.div`
    color: ${styles.Color.TaekusGrey2};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%; /* 22.08px */
    letter-spacing: 0.32px;
    margin-bottom: 16px;
`

const Title = styled.div`
    color: ${styles.Color.TaekusGrey1};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 49.6px */
    letter-spacing: 0.4px;
    margin-bottom: 32px;
`

export default VerifyEmail