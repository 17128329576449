export const fadeDownwardMotionProps = {
    initial: {
        opacity: 0,
        y: -10
    },
    exit: {
        opacity: 0,
        y: -10
    },
    animate: {
        opacity: 1,
        y: 0
    },
    transition: {
        type: 'Inertia'
    }
}

export const fadeUpwardMotionProps = {
    initial: {
        opacity: 0,
        y: 10
    },
    exit: {
        opacity: 0,
        y: 10
    },
    animate: {
        opacity: 1,
        y: 0
    },
    transition: {
        type: 'Inertia'
    }
}

export const slideFromRightMotionProps = {
    initial: {x: 100},
    exit: {x: 100},
    animate: {x: 0},
}

export const fadeInOutMotionProps = {
    initial: {opacity: 0},
    exit: {opacity: 0},
    animate: {opacity: 1},
}

export const expandVerticallyMotionProps = {
    initial: { height: 0, opacity: 0 },
    exit: { height: 0, opacity: 0 },
    animate: { height: 'auto', opacity: 1 }
}

export const expandHorizontallyMotionProps = {
    initial: {width: 0},
    exit: {width: 0},
    animate: {width: 'auto'}
}