import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux";

import { AnimatePresence, motion } from "framer-motion";
import { styled } from "styled-components"

import { Application, HousingType, StepDirection, ProductCategory } from "components/signup/types";

import ArrowButton, { ArrowDirection } from "components/signup/components/ArrowButton";
import Button from "components/signup/components/Button";
import CurrencyInput from "components/signup/components/CurrencyInput";
import Selectable from "components/signup/components/Selectable";
import StepContainer, { getStepContainerMotionProps } from "components/signup/StepContainer";

import { expandVerticallyMotionProps } from "styles/motionConstants";
import styles from "styles/styles";
import { updateApplication, updateLead, updateStep } from "../signupSlice";
import { SignupStep } from "../constants";
import { formatAsUSD } from "../utils";

type CaptureHousingProps = {
}

const CaptureHousing = (props: CaptureHousingProps) => {
    const dispatch = useDispatch()
    
    const inputRef = useRef(null)

    const leadUuid = useSelector((state: any) => state.signup.leadUuid)
    const application = useSelector((state: any) => state.signup.application)
    const products = useSelector((state: any) => state.signup.products)
    const stepDirection = useSelector((state: any) => state.signup.direction)

    const [isInputDisabled, setIsInputDisabled] = useState(false)
    const [housingType, setHousingType] = useState<HousingType | undefined>(application.residenceType || undefined)
    const [housingPayment, setHousingPayment] = useState(Number(application.monthlyHousingPayment) ? Number(application.monthlyHousingPayment)?.toLocaleString() : '')

    const selectedProduct = products?.find((product: any) => product.productCode === application.productCode)
    const isButtonDisabled = housingType === undefined || (housingType === HousingType.Rent && housingPayment === '') || isInputDisabled

    const updateHousingType = (ev: React.MouseEvent<HTMLButtonElement>) => {
        setHousingType((ev.target as any).id)
    }

    const goBack = (ev: any) => {
        ev.stopPropagation();
        dispatch(updateStep({ step: SignupStep.Income, direction: StepDirection.Left }))
    }
    
    const handleSubmit = (ev: React.FormEvent) => {
        ev.preventDefault();
        setIsInputDisabled(true)
        const isBusinessApplication = selectedProduct.productCategory === ProductCategory.Business
        const applicationChanges = { 
            residenceType: housingType, 
            monthlyHousingPayment: housingType === HousingType.Rent ? housingPayment.replaceAll(',','') : undefined
        }

        dispatch(updateApplication(applicationChanges))
        dispatch(updateLead({ leadUuid, application: applicationChanges }))
        dispatch(updateStep({
            step: isBusinessApplication ? SignupStep.BizType : SignupStep.FinalReview,
            direction: StepDirection.Right
        }))
    }

    const handleInputChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        const asNumber = Number(ev.target.value.replaceAll(',',''))
        if (ev.target.value === '' || (asNumber > 0 && String(asNumber).length <= 9)) {
            const formattedValue = formatAsUSD(ev.target.value);
            setHousingPayment(formattedValue);
        }
    }

    // Focus rent input if Rent housing type is pre-selected
    useEffect(() => {
        if (housingType === HousingType.Rent) {
            (inputRef.current as any)?.focus()
        }
    }, [housingType])

    return <StepContainer
        {...getStepContainerMotionProps(stepDirection)}
        key='Housing'
    >
        <form className="d-flex flex-column align-items-center" onSubmit={handleSubmit}>
            <Title>Do you rent or own your housing?</Title>
            <Text>Taekus is an invite-only community. To get started with your application for a Taekus product, enter your invite code. </Text>
            <SelectableMargin>
                <Selectable id={HousingType.Own} onClick={updateHousingType} selected={housingType === HousingType.Own} label='Own'/>
            </SelectableMargin>
            <Selectable id={HousingType.Rent} onClick={updateHousingType} selected={housingType === HousingType.Rent} label='Rent'/>
            <AnimatePresence mode='wait'>
                {housingType === HousingType.Rent && <motion.div {...expandVerticallyMotionProps}>
                    <InputContainer>
                        <CurrencyInput value={housingPayment} onChange={handleInputChange} parentRef={inputRef} label="Monthly housing payment"/>
                    </InputContainer>
                </motion.div>}
            </AnimatePresence>
            <ButtonContainer>
                <BackButtonContainer>
                    <ArrowButton onClick={goBack} direction={ArrowDirection.Left}/>
                </BackButtonContainer>
                <Button disabled={isButtonDisabled} type="submit">Next</Button>
            </ButtonContainer>
        </form>
    </StepContainer>
}

const BackButtonContainer = styled.div`
    margin-right: 16px;
`

const ButtonContainer = styled.div`
    margin-top: 32px;
    display: flex;
`

const InputContainer = styled.div`
    margin-top: 32px;
`

const SelectableMargin = styled.div`
    margin-bottom: 8px;
`

const Text = styled.div`
    color: ${styles.Color.TaekusGrey2};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%; /* 22.08px */
    letter-spacing: 0.32px;
    margin-bottom: 16px;
    ${styles.MediaQueries.Mobile} {
        font-size: 14px;
    }
`

const Title = styled.div`
    color: ${styles.Color.TaekusGrey1};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 49.6px */
    letter-spacing: 0.4px;
    margin-bottom: 32px;
    ${styles.MediaQueries.Mobile} {
        font-size: 24px;
    }
`

export default CaptureHousing