import React from 'react'

import Select, { components, CSSObjectWithLabel } from 'react-select';

import styled from "styled-components"

import { CardStatus, messages } from "components/pages/Cards/constants"

import styles from "styles/styles"

type CardStatusDropdownProps = {
    card?: any,
    cards?: any[],
    onChange: (value: any) => void,
    status?: CardStatus,
}

const selectableOptions = [
    { value: CardStatus.ACTIVE, label: 'Active' },
    { value: CardStatus.SUSPENDED, label: 'Suspended' },
]

const options = [
    ...selectableOptions,
    { value: CardStatus.TERMINATED, label: 'Terminated' },
    { value: CardStatus.UNACTIVATED, label: 'Unactivated' },
]

const CardStatusDropdown = (props: CardStatusDropdownProps) => {

    const { Option } = components

    const CustomOption = (props: any) => {
        return <Option className='d-flex align-items-center' {...props}>
            <StatusIndicator backgroundColor={getStatusColor(props.value)}/>
            <div>{props.label}</div>
        </Option>
    }

    const CustomSingleValue = (selectProps: any) => {
        return <StyledSingleValue  {...selectProps}>
            <div className='d-flex align-items-center'>
                <StatusIndicator backgroundColor={getStatusColor(props.status)}/>
                <div>{messages.CardStatusLabel[props.status as CardStatus]}</div>
            </div>
        </StyledSingleValue>
    }

    const getStatusColor = (status?: CardStatus) => {
        switch (status) {
            case CardStatus.ACTIVE:
                return '#00F780'
            case CardStatus.SUSPENDED:
                return '#F70080'
            case CardStatus.TERMINATED:
            default:
                return styles.Color.Grey;
        }
    }

    return <Select
        isSearchable={false}
        onChange={props.onChange}
        value={options.find(option => option.value === props.status)}
        placeholder='Status'
        isDisabled={props.status === CardStatus.UNACTIVATED || props.status === CardStatus.TERMINATED}
        // menuPortalTarget and menuPosition allows the dropdown menu to position on top of other components
        menuPortalTarget={document.body}
        menuPosition='fixed'
        components={{ 
            Option: CustomOption,
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
            SingleValue: CustomSingleValue
        }}
        styles={{
            container: (defaultStyles: CSSObjectWithLabel) => ({
                ...defaultStyles,
                border: 0,
                outline: 0,
                boxShadow: 'none',
                height: '30px'
            }),
            singleValue: (defaultStyles: CSSObjectWithLabel) => ({
                ...defaultStyles,
                fontSize: styles.Font.Size.Small,
                outline: 0,
                boxShadow: 'none',
                minHeight: '30px',
                maxHeight: '30px',
                padding: '0 4px',
                height: '30px',
                border: `1px solid transparent`,
                width: '100%',
                ':hover': {
                    border: `1px solid ${styles.Color.Grey}`,
                    boxShadow: 'none'
                },
            }),
            control: (defaultStyles: CSSObjectWithLabel) => ({
                ...defaultStyles,
                backgroundColor: 'transparent',
                outline: 0,
                boxShadow: 'none',
                borderRadius: 0,
                maxWidth: '120px',
                width: 'min-content',
                minHeight: '30px',
                height: 'min-content',
                border: 0,
                cursor: 'pointer',
                textAlign: 'right',
                ':focus-visible': {},
            }),
            input: (defaultStyles: CSSObjectWithLabel) => ({
                ...defaultStyles,
                border: 0,
                outline: 0,
                boxShadow: 'none',
                margin: 0
            }),
            menu: (defaultStyles: CSSObjectWithLabel) => ({
                ...defaultStyles,
                minWidth: '120px',
                borderRadius: 0,
            }),
            menuList: (defaultStyles: CSSObjectWithLabel) => ({
                ...defaultStyles,
                minWidth: '120px',
                padding: 0,
                fontSize: styles.Font.Size.Small,
                borderRadius: 0,
            }),
            valueContainer: (defaultStyles: CSSObjectWithLabel) => ({
                ...defaultStyles,
                padding: '0 4px',
                height: '30px',
            }),
            placeholder: (defaultStyles: CSSObjectWithLabel) => ({
                ...defaultStyles,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '0 4px',
                height: '30px',
                width: '100%',
                border: `1px solid ${styles.Color.Grey}`,
            }),
        }} 
        options={selectableOptions}
    /> 
}

const StyledSingleValue = styled.div`
    color: black;
    border: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${styles.Animation.transitionStyles}
    font-size: ${styles.Font.Size.Small};
    outline: 0;
    box-shadow: none;
    max-height: 30px;
    padding: 0 4px;
    height: 30px;
    border: 1px solid transparent;
    width: 100%;
    grid-area: 1 / 1 / 2 / 3;
    &:hover {
        border: 1px solid ${styles.Color.Grey};
        box-shadow: none;
    }
`

type StatusIndicatorProps = {
    backgroundColor: string
}

const StatusIndicator = styled.div<StatusIndicatorProps>`
    width: 11px;
    height: 11px;
    margin-right: 4px;
    border-radius: ${styles.BorderRadius.Half};
    background-color: ${props => props.backgroundColor};
`

export default CardStatusDropdown