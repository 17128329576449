import { combineReducers } from 'redux'

import { FlightSearchReducer } from './features/flightSearch'
import { FlightBookReducer } from './features/flightBook'
import { TripsReducer } from './features/trips'
import { BankingReducer } from './features/banking/banking'
import { StatementsReducer } from './features/banking/statements'
import { PayeesReducer } from './features/banking/payees'
import { LinkedAccountsReducer } from './features/banking/linkedAccounts'
import { DisputesReducer } from './features/banking/disputes'
import { AutopayReducer } from './features/banking/autopay'
import { RewardsTransactionsReducer } from './features/banking/rewardsTransactions'
import { PaymentCardsReducer } from './features/banking/paymentCards'
import { MarqetaReducer } from './features/banking/marqeta'
import { TransfersReducer } from './features/banking/transfers'
import { CurrentUserReducer } from './currentUser'

// Slices
import homeSlice from 'components/pages/Home/homeSlice'
import cardsSlice from 'components/pages/Cards/cardsSlice'
import flightsSlice from 'components/pages/Rewards/Flights/flightsSlice'
import globalSlice from 'redux/features/global/globalSlice'
import SignupSlice from 'components/signup/signupSlice'

const RootReducer = combineReducers({
  autopay: AutopayReducer,
  banking: BankingReducer,
  cards: cardsSlice,
  currentUser: CurrentUserReducer,
  disputes: DisputesReducer,
  flights: flightsSlice,
  flightBook: FlightBookReducer,
  flightSearch: FlightSearchReducer,
  global: globalSlice,
  home: homeSlice,
  linkedAccounts: LinkedAccountsReducer,
  marqeta: MarqetaReducer,
  payees: PayeesReducer,
  paymentCards: PaymentCardsReducer,
  rewardsTransactions: RewardsTransactionsReducer,
  signup: SignupSlice,
  statements: StatementsReducer,
  transfers: TransfersReducer,
  trips: TripsReducer,
})


export default RootReducer
