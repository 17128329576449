import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useParams } from "react-router-dom"

import moment from "moment"

import { Img } from "react-image"

import { motion } from "framer-motion"
import styled from "styled-components"

import { USDCurrencyOptions, Locale } from "utils/constants"
import { getDurationAsString } from "utils/utils"

import { ScreenSize } from "redux/features/global/types"
import { Actions as TripActions } from 'redux/features/trips'

import { AppPath } from "components/appRouter/constants"
import Footer from "components/common/Footer"
import Spinner from "components/common/Spinner"
import Navigation, { NavColor } from "components/navbar/Navigation"

import {TravelClass, messages, } from "components/pages/Rewards/constants"
import { FlightLeg } from "components/pages/Rewards/Flights/types"
import { TicketStatus } from "components/pages/Trips/constants"
import { 
    formatPhoneNumber,
    getTicketStatusLabel,
    getTripCashTotal,
    getTripPointTotal,
    isAmenitiesPopulated
} from "components/pages/Trips/utils"

import AmenitiesModal from "components/pages/Trips/AmenitiesModal"
import CancelFlightModal from "components/pages/Trips/CancelFlightModal"
import ChangeFlightModal from "components/pages/Trips/ChangeFlightModal"

import { ReactComponent as ArrowLeftIcon } from "assets/svg/ArrowLeft.svg";
import { ReactComponent as InfoIcon } from "assets/svg/InfoWarning.svg";
import { ReactComponent as LinkArrow } from "assets/svg/LinkArrow.svg";
import { ReactComponent as PrintIcon } from "assets/svg/Print.svg";
import { ReactComponent as TaekusIcon } from "assets/svg/TaekusIcon.svg";
import { ReactComponent as TaekusLogoSVG } from "assets/svg/TaekusLogo.svg";

import { fadeInOutMotionProps } from "styles/motionConstants"
import styles from "styles/styles"

const TripInspector = () => {
    const dispatch = useDispatch()
    const { tripID } = useParams<{ tripID: string }>();

    // Redux state
    const screenSize = useSelector((state: any) => state.global.screenSize)
    const trip = useSelector((state: any) => state.trips.trips).find((trip: any) => trip.uuid === tripID)
    const isLoadingTrips = useSelector((state: any) => state.trips.isLoading)

    // Copmonent state
    const [navIndex, setNavIndex] = useState(0)
    const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)
    const [isChangeModalOpen, setIsChangeModalOpen] = useState(false)
    const [inspectedAmenitiesLeg, setInspectedAmenitiesLeg] = useState<FlightLeg | null>(null)

    const imageRef = useRef(null)
    const containerRef = useRef(null)
    const departingRef = useRef(null)
    const returningRef = useRef(null)
    const travelersRef = useRef(null)
    const paymentRef = useRef(null)

    const itineraries = trip?.reservations?.map((reservation: any) => reservation?.itineraries).flat()
    const outboundFlights = itineraries?.filter((itinerary: any) => itinerary.direction === 'outbound') || []
    const inboundFlights = itineraries?.filter((itinerary: any) => itinerary.direction === 'inbound') || []

    const departingText = `Departing Flight${outboundFlights.length > 1 ? 's' : ''}`
    const returningText = `Returning Flight${inboundFlights.length > 1 ? 's' : ''}`
    const travelerText = `${trip?.reservations[0].travellers.length} traveler${trip?.reservations[0].travellers.length > 1 ? 's' : ''}`

    const scrollUpdate = () => {
        const scrollAmount = (containerRef.current as any)?.scrollTop;

        // Update parallax scroll for hero image
        (imageRef.current as any).style.backgroundPositionY = `calc(50% + ${(Math.max(scrollAmount, 0) / 2)}px)`;

        // Update nav index for sidebar
        if (scrollAmount >= (paymentRef.current as any)?.offsetTop) {
            setNavIndex(4)
        } else if (scrollAmount >= (travelersRef.current as any)?.offsetTop) {
            setNavIndex(3)
        } else if (scrollAmount >= (returningRef.current as any)?.offsetTop) {
            setNavIndex(2)
        } else if (scrollAmount >= (departingRef.current as any)?.offsetTop) {
            setNavIndex(1)
        } else {
            setNavIndex(0)
        }
    }

    const addScrollListener = () => {
        (containerRef.current as any)?.addEventListener("scroll", scrollUpdate);
    }

    const toggleCancelModal = () => {
        setIsCancelModalOpen(!isCancelModalOpen)
    }

    const toggleChangeModal = () => {
        setIsChangeModalOpen(!isChangeModalOpen)
    }

    const closeAmenitiesModal = () => {
        setInspectedAmenitiesLeg(null)
    }

    const mapItinerariesToLegs = (itineraries: any[]) => {
        return itineraries?.map((itinerary: any) => itinerary.segments).flat()?.map((segment: any) => segment?.legs).flat()
    }
    
    const convertDurationStringToMinutes = (duration: string) => {
        const [hours, minutes, seconds] = duration.split(':')
        // Ignore seconds
        const hourMinutes = Number(hours) * 60
        return  hourMinutes + Number(minutes)
    }

    const getLegFlexRatio = (leg: any, itineraries: any[]) => {
        const legLength = convertDurationStringToMinutes(leg.duration)
        const totalSumOfLegLengths = mapItinerariesToLegs(itineraries).reduce((counter: number, leg: any) => counter + convertDurationStringToMinutes(leg.duration), 0)

        return legLength / totalSumOfLegLengths
    }

    const handleNavClick = (ev: React.MouseEvent<HTMLDivElement>) => {
        const refs = [imageRef, departingRef, returningRef, travelersRef, paymentRef];
        
        // We update nav index twice here, once on button click, and again after the smooth scroll
        setNavIndex(Number((ev.target as any).id));
        (refs[Number((ev.target as any).id)].current as any).scrollIntoView({ behavior: 'smooth' })
        setTimeout(() => {setNavIndex(Number((ev.target as any).id))}, 800);
    }

    const getLegParentReservation = (leg: any) => {
        return trip.reservations.find((reservation: any) => reservation.itineraries.some((itin: any) => itin.segments.some((seg: any) => seg.legs.some((l: any) => leg.uuid === l.uuid))))
    }

    const getLegParentSegment = (leg: any) => {
        return itineraries.map((itinerary: any) => itinerary.segments).flat().find((segment: any) => segment.legs.some((l: any) => leg.uuid === l.uuid))
    }

    const mapLegToDetailCard = (leg: any, index: number) => {
        const parentReservation = getLegParentReservation(leg)
        const parentSegment = getLegParentSegment(leg)

        const carryOnBaggageText = Number(parentReservation.carryonBaggageAllowance) > 0 ? `${parentReservation.carryonBaggageAllowance} Carry-on${Number(parentReservation.carryonBaggageAllowance) > 1 ? 's' : ''}` : ''
        const checkedBaggageText = Number(parentReservation.checkedBaggageAllowance) > 0 ? `${parentReservation.checkedBaggageAllowance} Checked Bag${Number(parentReservation.checkedBaggageAllowance) > 1 ? 's' : ''}` : ''
        const baggageText = `${carryOnBaggageText} ${(Number(parentReservation.carryonBaggageAllowance) > 0 && Number(parentReservation.checkedBaggageAllowance) > 0) ? '+' : ''} ${checkedBaggageText}`

        const handleAmenitiesClick = () => {
            setInspectedAmenitiesLeg(leg)
        }

        return <DetailCard key={index}>
            <DetailCardTitle>{parentSegment.operatingCarrierName}</DetailCardTitle>
            <DetailCardList>
                <DetailCardListItem>
                    <DetailCardLabel>Flight Record Locators</DetailCardLabel>
                    <DetailCardValue>{parentSegment.partnerPnr || parentReservation.pnr || <LightText>Pending</LightText>}</DetailCardValue>
                </DetailCardListItem>
                <DetailCardListItem>
                    <DetailCardLabel>Flight number</DetailCardLabel>
                    <DetailCardValue>{`${parentSegment.operatingCarrierCode} ${parentSegment.fltNumber}`}</DetailCardValue>
                </DetailCardListItem>
                <DetailCardListItem>
                    <DetailCardLabel>Class</DetailCardLabel>
                    <DetailCardValue>{messages.SearchOptions.TravelClass[parentSegment.cabin as TravelClass]}</DetailCardValue>
                </DetailCardListItem>
                {parentSegment.aircraft && <DetailCardListItem>
                    <DetailCardLabel>Model</DetailCardLabel>
                    <DetailCardValue>{parentSegment.aircraft}</DetailCardValue>
                </DetailCardListItem>}
            </DetailCardList>
            <DetailCardLabel>Baggage Allowance</DetailCardLabel>
            <DetailCardValue>{baggageText.trim() === '' ? <LightText>No baggage allowance</LightText> : baggageText}</DetailCardValue>
            {trip?.nonrefundable && <RefundabilityContainer>
                <CustomInfoIcon/>
                <Refundability>
                    This ticket is nonrefundable and are bound by the rules and regulations of {parentSegment.operatingCarrierName}.
                </Refundability>
            </RefundabilityContainer>}
            <div className="d-flex">
                {isAmenitiesPopulated(leg) && <DetailCardButton onClick={handleAmenitiesClick}>
                    Amenities<CustomLinkIcon/>
                </DetailCardButton>}
                {/* <DetailCardButton>
                    Baggage Details<CustomLinkIcon/>
                </DetailCardButton> */}
                {/* <DetailCardButton>
                    Seats<CustomLinkIcon/>
                </DetailCardButton> */}
            </div>
        </DetailCard>
    }

    const mapItinerariesToView = (itineraries: any[]) => {
        const flightLegs = mapItinerariesToLegs(itineraries)
        const totalTravelTime = flightLegs?.reduce(
            (counter: number, leg: any, index: number) => {
                const connectionInMinutes = !!flightLegs[index + 1] ? moment(flightLegs[index + 1].localDepartureTimeDate).diff(moment(leg.localArrivalTimeDate), 'minutes') : 0
                return counter + convertDurationStringToMinutes(leg.duration) + connectionInMinutes
            },
            0
        )
        
        return <div className="d-flex w-100">
            <ItineraryContainer>
                <ItineraryDate>{moment(itineraries?.at(0)?.segments[0]?.localDepartureTimeDate).format('ddd, MMM D')}</ItineraryDate>
                <ItineraryPathContainer>
                    <div className="d-flex justify-content-between">
                        <ItineraryPathAirport>{itineraries?.at(0)?.segments[0]?.legs[0]?.originCityName}</ItineraryPathAirport>
                        <ItineraryPathAirport>{itineraries?.at(-1)?.segments.at(-1)?.legs.at(-1)?.destinationCityName}</ItineraryPathAirport>
                    </div>
                    <div className="d-flex">
                        <ItineraryPathAirportCode>{itineraries?.at(0)?.segments[0]?.legs[0]?.origin}</ItineraryPathAirportCode>
                        {<ItineraryLineContainer>
                            {(mapItinerariesToLegs(itineraries)?.map((leg: any, index: number) => <>
                                {index !== 0 && <ItineraryPoint key={`point:${index}`}/>}
                                <ItineraryLine $segmentRatio={getLegFlexRatio(leg, itineraries)} key={`line:${index}`}/>
                            </>))}
                        </ItineraryLineContainer>}
                        <ItineraryPathAirportCode>{itineraries?.at(-1)?.segments.at(-1)?.legs.at(-1)?.destination}</ItineraryPathAirportCode>
                    </div>
                    <ItineraryPathDate>
                        <div>{moment(itineraries?.at(0)?.segments?.at(0)?.localDepartureTimeDate).format('h:mm A')}</div>
                        <PreviewTravelTime>Total Travel Time: {getDurationAsString(totalTravelTime)}</PreviewTravelTime>
                        <div>{moment(itineraries?.at(-1)?.segments?.at(-1)?.localArrivalTimeDate).format('h:mm A')}</div>
                    </ItineraryPathDate>
                </ItineraryPathContainer>
                {mapItinerariesToLegs(itineraries)?.map((leg: any, index: number) => <div key={index}>
                    <div className="d-flex">
                        <LogoContainer>
                            <LogoPadding>
                                <Logo src={[
                                    `/static/img/airlineLogos/mini/${itineraries?.map((itin: any) => itin.segments).flat().find((segment: any) => segment?.legs.some((l: any) => leg.uuid === l.uuid))?.carrierCode.toUpperCase()}.png`,
                                    '/static/img/airlineLogos/mini/default.png'
                                ]} />
                            </LogoPadding>
                        </LogoContainer>
                        <div className='d-flex'>
                            <VerticalLegBar>
                                <LargeDot/>
                                <SmallDot/>
                                <SmallDot/>
                                <SmallDot/>
                                <SmallDot/>
                                <SmallDot/>
                                <SmallDot/>
                                <LargeDot/>
                            </VerticalLegBar>
                            <div className="d-flex flex-column justify-content-between">
                                <LegDetail>{moment(leg.localDepartureTimeDate).format('h:mm A')} • {leg.originCityName} ({leg.origin})</LegDetail>
                                <TravelTime>{getDurationAsString(convertDurationStringToMinutes(leg.duration))}</TravelTime>
                                <LegDetail>{moment(leg.localArrivalTimeDate).format('h:mm A')} • {leg.destinationCityName} ({leg.destination})</LegDetail>
                            </div>
                        </div>
                    </div>
                    {screenSize !== ScreenSize.Desktop && mapLegToDetailCard(leg, index)}
                    {index !== mapItinerariesToLegs(itineraries).length - 1 && <Connection>
                        <LightText>{getDurationAsString(moment(flightLegs[index + 1].localDepartureTimeDate).diff(moment(flightLegs[index].localArrivalTimeDate), 'minutes'))} layover • {leg.destinationCityName} ({leg.destination})</LightText>
                    </Connection>}
                </div>)}
            </ItineraryContainer>
            {screenSize === ScreenSize.Desktop && <DesktopLegDetailCardContainer>
                {mapItinerariesToLegs(itineraries)?.map(mapLegToDetailCard)}
            </DesktopLegDetailCardContainer>}
        </div>   
    }

    const mapTravelerToView = (traveler: any, index: number) => {
        return <TravelerDetails key={index}>
            <TravelerDetailColumn>
                <TravelerName>{traveler.firstName} {traveler.lastName}</TravelerName>
                <TravelerSubtitle>Adult • {traveler.gender === 'M' ? 'Male' : 'Female'}</TravelerSubtitle>
            </TravelerDetailColumn>
            <TravelerDetailColumn>
                <TravelerDetailLabel>Ticket Number</TravelerDetailLabel>
                <TravelerDetail>{traveler.ticketNumber}</TravelerDetail>
            </TravelerDetailColumn>
            <TravelerDetailColumn>
                <TravelerDetailLabel>Date of Birth</TravelerDetailLabel>
                <TravelerDetail>{moment(traveler.dateOfBirth).format('MMM D, YYYY')}</TravelerDetail>
            </TravelerDetailColumn>
            <TravelerDetailColumn $isHidden={!traveler.knownTravellerNumber}>
                <TravelerDetailLabel>Known Traveler Number</TravelerDetailLabel>
                <TravelerDetail>{traveler.knownTravellerNumber}</TravelerDetail>
            </TravelerDetailColumn>
            <TravelerDetailColumn $isHidden={!traveler.redressNumber}>
                <TravelerDetailLabel>Redress Number</TravelerDetailLabel>
                <TravelerDetail>{traveler.redressNumber}</TravelerDetail>
            </TravelerDetailColumn>
        </TravelerDetails>   
    }

    /*
     *  Load images via Javascript to see if an error occurs
     *  • If it does, set the placeholder image and boolean to true to render Taekus logo
     *  • If it doesn't fail, set the unique destination image we loaded
     * 
     *  note: When the image is used for multiple trips, it is only loaded via network once,
     *    but there will be a network failure for each placeholder
     */
    useEffect(() => {
        if (trip && imageRef.current) {
            const image = new Image();
            const airportCode = trip?.reservations[0]?.itineraries[0]?.segments.at(-1)?.destinationCity

            image.onload = () => {
                (imageRef.current as any).style.backgroundImage = 'linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.8) ), url(' + image.src + ')';
                (imageRef.current as any).style.opacity = 1;
            }
            image.onerror = () => {
                (imageRef.current as any).style.backgroundImage = `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.8)), url("https://taekus-static.s3.us-west-2.amazonaws.com/img/backgroundImages/tripPlaceholder.jpg")`;
                (imageRef.current as any).style.opacity = 1;
            }
            image.src = `https://taekus-image-assets.s3.us-east-2.amazonaws.com/destinations/${airportCode}1.jpg`;
        }
    }, [trip]) // eslint-disable-line

    useEffect(() => {
        // If trip is not found in the list of trips on page mount, fetch the individual trip
        if (!trip) {
            dispatch(TripActions.fetchTrip(tripID))
        }
    }, [dispatch]) // eslint-disable-line

    useEffect(() => {
        addScrollListener()
    }, []) // eslint-disable-line

    const pointTotal = getTripPointTotal(trip)
    const cashTotal = getTripCashTotal(trip)

    return <Container ref={containerRef}>
        <div>
            <Header>
                <Navigation color={NavColor.White} />
                {trip && <HeaderContent {...fadeInOutMotionProps}>
                    <HeaderTitle>{trip?.tripName || `${outboundFlights[0]?.segments[0]?.originCityName} to ${outboundFlights?.at(-1)?.segments.at(-1)?.destinationCityName}`}</HeaderTitle>
                    <HeaderText>{outboundFlights[0]?.segments[0]?.originCityName} ({outboundFlights[0]?.segments[0]?.originCity}) to {outboundFlights?.at(-1)?.segments.at(-1)?.destinationCityName} ({outboundFlights?.at(-1)?.segments.at(-1)?.destinationCity}) •  {moment(outboundFlights?.at(0)?.segments.at(0)?.localDepartureTimeDate).format('ddd, MMM D')} {inboundFlights?.length > 0 && `- ${moment(inboundFlights?.at(0)?.segments.at(0)?.localDepartureTimeDate).format('ddd, MMM D')}`}</HeaderText>
                    <HeaderText>{pointTotal?.toLocaleString()} pts {!!cashTotal && `+ ${cashTotal.toLocaleString(Locale.English, USDCurrencyOptions)}`} • {getTicketStatusLabel(trip?.ticketStatus)} • {trip?.taekusId} • {!!inboundFlights?.length ? 'Round-trip' : 'One-way'} • {travelerText}</HeaderText>
                </HeaderContent>}
                <HeaderImage ref={imageRef}/>
            </Header>
            <TripsContainer>
                {isLoadingTrips ? <LoadingContainer>
                    <Spinner size={40}/>
                    <LoadingText>Fetching your trip details...</LoadingText>
                </LoadingContainer> : <Main>
                    {screenSize !== ScreenSize.Mobile && <Sidebar>
                        <StickySidebarContent>
                            <BackButtonContainer as={Link} to={AppPath.MyTrips}>
                                <StyledArrowLeft fill={styles.Color.TaekusPurple}/>
                                <BackButtonLabel>Back to all trips</BackButtonLabel>
                            </BackButtonContainer>
                            <div>
                                <SubnavItem onClick={handleNavClick} id='0' selected={navIndex === 0}>Summary</SubnavItem>
                                <SubnavItem onClick={handleNavClick} id='1' selected={navIndex === 1}>{departingText}</SubnavItem>
                                {inboundFlights.length > 0 && <SubnavItem onClick={handleNavClick} id='2' selected={navIndex === 2}>{returningText}</SubnavItem>}
                                <SubnavItem onClick={handleNavClick} id='3' selected={navIndex === 3}>Travelers</SubnavItem>
                                <SubnavItem onClick={handleNavClick} id='4' selected={navIndex === 4}>Payment Info</SubnavItem>
                            </div>
                        </StickySidebarContent>
                    </Sidebar>}
                    <Content {...fadeInOutMotionProps}>
                        <ActionsHeader>
                            <Title>You're All Set for Your Trip</Title>
                            <div className="d-flex">
                                {/* <DownloadIcon style={{width: '24px', height: '24px', margin: '8px', stroke: 'black'}}/> */}
                                <CustomPrintIcon onClick={() => { window.print() }}/>
                            </div>
                        </ActionsHeader>
                        <PrintHeader>
                            <PrintTitleContainer>
                                <Title>Travel Review</Title>
                                <TaekusLogoSVG/>
                            </PrintTitleContainer>
                            <PrintItem>{outboundFlights[0]?.segments[0]?.originCityName} ({outboundFlights[0]?.segments[0]?.originCity}) to {outboundFlights?.at(-1)?.segments.at(-1)?.destinationCityName} ({outboundFlights?.at(-1)?.segments.at(-1)?.destinationCity}) •  {moment(outboundFlights?.at(0)?.segments.at(0)?.localDepartureTimeDate).format('ddd, MMM D')} {inboundFlights?.length && `- ${moment(inboundFlights?.at(0)?.segments.at(0)?.localDepartureTimeDate).format('ddd, MMM D')}`}</PrintItem>
                            <PrintItem>{pointTotal?.toLocaleString()} pts {!!cashTotal && `+ ${cashTotal.toLocaleString(Locale.English, USDCurrencyOptions)}`} • {getTicketStatusLabel(trip?.ticketStatus)} • {trip?.taekusId} • {!!inboundFlights?.length ? 'Round-trip' : 'One-way'} • {travelerText}</PrintItem>
                        </PrintHeader>
                        <DetailsHeader>
                            <BookingDetails>
                                <BookingDetail>
                                    <DetailLabel>Taekus Booking Code</DetailLabel>
                                    <DetailValue><Bold>{trip?.taekusId}</Bold></DetailValue>
                                </BookingDetail>
                                <BookingDetail>
                                    <DetailLabel>Contact Information</DetailLabel>
                                    <DetailValue>{trip?.primaryEmail || trip?.reservations[0].travellers[0]?.email} &bull; {formatPhoneNumber(trip?.primaryPhoneNumber || trip?.reservations[0].travellers[0]?.phone)}</DetailValue>
                                </BookingDetail>
                            </BookingDetails>
                            <div className="d-flex align-items-center">
                                <FlightButton color={styles.Color.TaekusPurple} onClick={toggleChangeModal}>Change Flight</FlightButton>
                                {trip?.ticketStatus === TicketStatus.TICKETED && <FlightButton color='#AB0634' onClick={toggleCancelModal}>Cancel Flight</FlightButton>}
                            </div>
                        </DetailsHeader>
                        <div>
                            <PathContainer ref={departingRef}>
                                <ItineraryTitle>{departingText}</ItineraryTitle>
                                {mapItinerariesToView(outboundFlights)}
                            </PathContainer>
                            {inboundFlights.length > 0 && <PathContainer ref={returningRef}>
                                <ItineraryTitle>{returningText}</ItineraryTitle>
                                {mapItinerariesToView(inboundFlights)}
                            </PathContainer>}
                            <PathContainer ref={travelersRef}>
                                <ItineraryTitle>Travelers</ItineraryTitle>
                                {trip?.reservations[0].travellers.map(mapTravelerToView)}
                            </PathContainer>
                            <PathContainer ref={paymentRef}>
                                <ItineraryTitle>Payment Info</ItineraryTitle>
                                <PaymentInfo>
                                    <CostTotal>
                                        <CostTotalTitle>Total</CostTotalTitle>
                                        <CostTotalValue>{pointTotal?.toLocaleString()} points {!!cashTotal && `+ ${cashTotal.toLocaleString(Locale.English, USDCurrencyOptions)}`}</CostTotalValue>
                                    </CostTotal>
                                    <CostBreakdown>
                                        <CostItem>
                                            <div>Trip Fare</div>
                                            <div>
                                                <CostValue>{pointTotal?.toLocaleString()} pts.</CostValue>
                                                <PaymentSource>
                                                    <CustomTaekusIcon/>
                                                    <span>Taekus Points</span>
                                                </PaymentSource>
                                            </div>
                                        </CostItem>
                                        {!!cashTotal && <CostItem>
                                            <div>Taxes, Fees, and Charges</div>
                                            <div>
                                                <CostValue>{cashTotal.toLocaleString(Locale.English, USDCurrencyOptions)}</CostValue>
                                                <PaymentSource>
                                                    <CustomTaekusIcon/>
                                                    <span>Personal Debit (...{trip.cardAccountLast4})</span>
                                                </PaymentSource>
                                            </div>
                                        </CostItem>}
                                    </CostBreakdown>
                                </PaymentInfo>
                            </PathContainer>
                        </div>
                    </Content>
                </Main>}
            </TripsContainer>
            <Footer/>
        </div>
        {isCancelModalOpen && <CancelFlightModal trip={trip} onClose={toggleCancelModal}/>}
        {isChangeModalOpen && <ChangeFlightModal onClose={toggleChangeModal}/>}
        {!!inspectedAmenitiesLeg && <AmenitiesModal leg={inspectedAmenitiesLeg} onClose={closeAmenitiesModal}/>}
    </Container>
}

const Bold = styled.span`
    font-weight: 500;
`

const LightText = styled.span`
    opacity: 0.6;
`

const PaymentInfo = styled.div`
    display: flex;
    ${styles.MediaQueries.Mobile} {
        flex-direction: column-reverse;
    }
`

const LogoContainer = styled.div`
    width: 92px;
    height: 92px;
    margin-right: 16px;
    ${styles.MediaQueries.Mobile} {
        width: 39px;
        height: auto;
    }
`

/* 
 * Normally padding is just CSS style, but we use an extra element
 * so that the Logo can have a static width and automatically size height.
 */
const LogoPadding = styled.div`
    height: 64px;
    width: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    ${styles.MediaQueries.Mobile} {
        width: 39px;
        height: auto;
    }
`

const PreviewTravelTime = styled.div`
    display: flex;
    justify-content: center;
    opacity: 0.5;
    ${styles.MediaQueries.Mobile} {
        max-width: 120px;
        text-wrap: wrap;
    }
`

const Title = styled.div`
    ${styles.MediaQueries.Mobile} {
        flex: 1;
        text-wrap: wrap;
    }
`

const DesktopLegDetailCardContainer = styled.div`
    margin-left: 124px;
    width: 308px;
    padding: 24px 0;
    background-color: ${styles.Color.TaekusCream};
`

const RefundabilityContainer = styled.div`
    display: flex;
    font-size: 12px;
    max-width: 100%;
    overflow: hidden;
    margin-bottom: 24px;
`

const Refundability = styled.div`
    flex: 1;
    color: #787878;
    text-wrap: wrap;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 14px */
    letter-spacing: 0.2px;
`

const DetailCardButton = styled.button`
    background-color: transparent;
    border: 0;
    color: ${styles.Color.NearBlack};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 12px;
    height: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 16.8px */
    letter-spacing: 0.24px;
    text-decoration-line: underline;
    padding: 0;
    &:not(&:last-child) {
        margin-right: 8px;
    }
`

const DetailCardTitle = styled.div`
    margin-bottom: 12px;
`

const DetailCardList = styled.div`
    display: flex;
    flex-wrap: wrap;
`

const DetailCardLabel = styled.div`
    color: #787878;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 12.4px */
    letter-spacing: 0.1px;
    margin-bottom: 4px;
`

const DetailCardValue = styled.div`
    color: ${styles.Color.NearBlack};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.28px;
    margin-bottom: 12px;
`

const DetailCardListItem = styled.div`
    display: flex;
    flex-direction: column;
    ${styles.MediaQueries.Tablet} {
        flex: 1;
    }
    ${styles.MediaQueries.Mobile} {
        flex-basis: 50%;
    }
    @media screen and (min-width: 992px) {
        flex-basis: 50%;
    }
    ${styles.MediaQueries.Print} {
        flex-basis: 50%;
    }
`

const DetailCard = styled.div`
    margin: 0 24px;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    &:not(&:first-child) {
        padding-top: 32px;
    }
    &:not(&:last-child) {
        padding-bottom: 32px;
        border-bottom: 1px solid #ffffff;
    }
    ${styles.MediaQueries.Tablet} {
        flex: 1;
        margin-top: 24px;
        margin-left: 108px;
        padding: 24px;
        background-color: ${styles.Color.TaekusCream};
    }
    ${styles.MediaQueries.Mobile} {
        flex: 1;
        margin: 24px 0 0 0;
        padding: 24px;
        background-color: ${styles.Color.TaekusCream};
    }
`

const PaymentSource = styled.div`
    color: #787878;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    text-align: right;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.28px;
    display: flex;
    justify-content: end;
    align-items: center;
`

const CostValue = styled.div`
    color: ${styles.Color.NearBlack};
    text-align: right;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 127%; /* 30.48px */
    letter-spacing: 0.24px;
    margin-bottom: 4px;
`

const CostItem = styled.div`
    display: flex;
    border-top: 1px solid ${styles.Color.Grey};
    padding: 16px 0 24px;
    display: flex;
    justify-content: space-between;
    color: ${styles.Color.NearBlack};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.28px;
`

const CostTotalValue = styled.div`
    font-weight: 500;
    text-align: right;
    text-wrap: wrap;
`

const CostTotalTitle = styled.div`
    width: 92px;
    margin-right: 16px;
`

const CostBreakdown = styled.div`
    flex: 6;
`

const CostTotal = styled.div`
    flex: 4;
    margin-right: 16px;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid ${styles.Color.NearBlack};
    padding: 16px 0;
    color: ${styles.Color.NearBlack};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 24px;
    font-style: normal;
    line-height: 127%; /* 30.48px */
    letter-spacing: 0.24px;
    ${styles.MediaQueries.Mobile} {
        margin-right: 0;
    }
`

const TravelerDetails = styled.div`
    display: flex;
    ${styles.MediaQueries.Mobile} {
        flex-wrap: wrap;
    }
`

type TravelerDetailColumnProps = {
    $isHidden?: boolean,
}

const TravelerDetailColumn = styled.div<TravelerDetailColumnProps>`
    ${props => props.$isHidden && `visibility: hidden;`}
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: end;
    &:not(&:last-child) {
        margin-right: 16px;
    }
    ${styles.MediaQueries.Mobile} {
        margin: 0 !important;
        &:not(&:nth-last-child(-n+2)) {
            margin: 0 0 16px 0 !important;
        }
        flex-basis: 100%;
        &:not(&:first-child) {
            flex-basis: 50%;
        }
    }
`

const TravelerName = styled.div`
    color: ${styles.Color.NearBlack};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 127%; /* 30.48px */
    letter-spacing: 0.24px;
    margin-bottom: 8px;
    ${styles.MediaQueries.Mobile} {
        font-size: 20px;
    }
`

const TravelerSubtitle = styled.div`
    color: ${styles.Color.NearBlack};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.28px;
`

const TravelerDetailLabel = styled.div`
    color: ${styles.Color.NearBlack};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 14px */
    letter-spacing: 0.2px;
    margin-bottom: 8px;
`

const TravelerDetail = styled.div`
    color: ${styles.Color.NearBlack};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    height: 22px;
    line-height: 138%; /* 22.08px */
    letter-spacing: 0.32px;
`

const Connection = styled.div`
    height: 36px;
    display: flex;
    align-items: center;
    color: ${styles.Color.NearBlack};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.28px;
    border-top: 1px solid ${styles.Color.Grey};
    border-bottom: 1px solid ${styles.Color.Grey};
    margin: 24px 0 24px 132px;
    ${styles.MediaQueries.Mobile} {
        margin: 24px 0 24px 55px;
    }
`

type FlightButtonProps = {
    color: string,
}

const FlightButton = styled.button<FlightButtonProps>`
    height: 22px;
    border: 0;
    background-color: transparent;
    text-decoration-line: underline;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 138%; /* 22.08px */
    letter-spacing: 0.32px;
    color: ${props => props.color};
    padding: 0;
    &:not(&:last-child) {
        margin-right: 24px;
    }
    ${styles.MediaQueries.Print} {
        display: none;
    }
`

const BookingDetail = styled.div`
    margin-right: 16px;
    @media screen and (min-width: 992px) {
        min-width: 200px;        
    }
    ${styles.MediaQueries.Tablet} {
        min-width: 160px;
    }
    ${styles.MediaQueries.Mobile} {
        margin-bottom: 16px;
    }
    ${styles.MediaQueries.Print} {
        min-width: 260px;
    }
`

const BookingDetails = styled.div`
    display: flex;
    ${styles.MediaQueries.Mobile} {
        flex-direction: column;
    }
`

const DetailsHeader = styled.div`
    border-top: 1px solid ${styles.Color.Grey};
    border-bottom: 1px solid ${styles.Color.Grey};
    padding: 24px 0;
    display: flex;
    justify-content: space-between;
    ${styles.MediaQueries.Mobile} {
        flex-direction: column;
    }
`

type SubnavItemProps = {
    selected: boolean,
}

const SubnavItem = styled.div<SubnavItemProps>`
    color: ${props => props.selected ? styles.Color.TaekusPurple : styles.Color.NearBlack};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 14px;
    font-style: normal;
    font-weight: ${props => props.selected ? 500: 400};
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.28px;
    padding-left: 7px;
    border-left: 2px solid ${props => props.selected ? styles.Color.TaekusPurple : 'transparent'};
    -moz-transition: border .2s ease-in;
    -o-transition: border .2s ease-in;
    -webkit-transition: border .2s ease-in;
    transition: border .2s ease-in;
    cursor: pointer;
    &:not(&:last-child) {
        margin-bottom: 11px;
    }
    &:hover {
        font-weight: 500;
        color: ${styles.Color.TaekusPurple};
        border-left: 2px solid ${styles.Color.TaekusPurple};
    }
`

const HeaderContent = styled(motion.div)`
    color: white;
    margin: 33px 40px;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    line-height: 138%; /* 22.08px */
    letter-spacing: 0.32px;
    overflow: hidden;
    height: min-content;
    z-index: 500;
`

const HeaderText = styled.div`
    text-wrap: wrap;
    margin-bottom: 8px;
`

const HeaderTitle = styled.div`
    font-size: 42px;
    line-height: 42px;
    text-wrap: wrap;
    overflow: hidden;
    ${styles.MediaQueries.Mobile} {
        font-size: 28px;
        line-height: 28px;
    }
    margin-bottom: 16px;
`

const PrintItem = styled.div`
    color: #787878;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 17.5px */
    letter-spacing: 0.14px;
    &:not(&:last-child) {
        margin-bottom: 8px;
    }
`

const PrintTitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
`

const PrintHeader = styled.div`
    color: ${styles.Color.NearBlack};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 32px;
    font-style: normal;
    max-width: 100%;
    font-weight: 400;
    line-height: 124%; /* 39.68px */
    letter-spacing: 0.32px;
    margin-bottom: 40px;
    @media not print {
        display: none;
    }
`

const Header = styled.div`
    background-color: ${styles.Color.TaekusPurple};
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media screen and (min-width: 992px) {
        aspect-ratio: 1440 / 515;
    }
    ${styles.MediaQueries.Tablet} {
        aspect-ratio: 992 / 355;
    }
    ${styles.MediaQueries.Mobile} {
        min-height: 80dvh;
    }
    ${styles.MediaQueries.Print} {
        display: none;
    }
`

const HeaderImage = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: ${styles.Color.TaekusPurple};
    background-size: cover;
    background-position: center;
    opacity: 0;
    -moz-transition: opacity .2s ease-in;
    -o-transition: opacity .2s ease-in;
    -webkit-transition: opacity .2s ease-in;
    transition: opacity .2s ease-in;
`

const StickySidebarContent = styled.div`
    top: 48px;
    position: sticky;
`

const Sidebar = styled.div`
    position: 'sticky',
    top: '48px',.
    display: flex;
    flex-direction: column;
    ${styles.MediaQueries.Tablet} {
        padding-left: ${styles.Spacing.M};
        padding-top: 48px;
        min-width: 155px;
    }
    @media screen and (min-width: 992px) {
        padding-left: ${styles.Spacing.M};
        padding-right: ${styles.Spacing.M};    
        padding-top: 48px;
        min-width: 256px;
    }
    ${styles.MediaQueries.Print} {
        display: none;
    }
`

const StyledArrowLeft = styled(ArrowLeftIcon)`
    min-width: ${styles.Spacing.S};
    width: ${styles.Spacing.S};
`

const TripsContainer = styled.div`
    min-height: 100vh;
`

const Main = styled.div`
    flex: 1;
    ${styles.MediaQueries.Desktop} {
        display: flex;
    }
`

const BackButtonLabel = styled.div`
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-weight: ${styles.Font.Weight[400]};
    margin-left: ${styles.Spacing.XS};
    color: ${styles.Color.NearBlack};
    font-style: normal;
    font-size: ${styles.Font.Size.Small};
    line-height: 140%;
    /* identical to box height, or 20px */
    letter-spacing: 0.02em;
`

const BackButtonContainer = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 20px;
`

const PathContainer = styled.div`
    padding: 32px 0;
    &:not(&:first-child) {
        border-top: 1px solid ${styles.Color.Grey};
    }
`

const Logo = styled(Img)`
    width: 100%;
    height: height;
    ${styles.MediaQueries.Mobile} {
        width: 39px;
        height: auto;
    }
`

const TravelTime = styled.div`
    color: ${styles.Color.NearBlack};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.28px;
    opacity: 0.5;
`

const LargeDot = styled.div`
    height: 16px;
    width: 16px;
    border-radius: 50%;
    border: 2px solid ${styles.Color.Grey};
    &:first-child {
        margin-bottom: 5px;
    }
    &:last-child {
        margin-top: 5px;
    }
`

const SmallDot = styled.div`
    background-color: ${styles.Color.Grey};
    width: 4px;
    height: 4px;
    border-radius: 50%;
    margin: 2px 0;
`

const VerticalLegBar = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 8px;
    padding: 2px 0;
`

const CustomTaekusIcon = styled(TaekusIcon)`
    height: auto;
    width: 19px;
    fill: #787878;
    margin-right: 8px;
`

const CustomPrintIcon = styled(PrintIcon)`
    cursor: pointer;
    width: 24px;
    height: 24px;
    margin: 8px;
    stroke: black;
`

const CustomLinkIcon = styled(LinkArrow)`
    width: 16px;
    height: 16px;
`

const CustomInfoIcon = styled(InfoIcon)`
    min-width: 14px;
    max-width: 14px;
    min-height: 14px;
    max-height: 14px;
    margin-right: 4px;
    fill: #787878;
`

const ItineraryLineContainer = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
    margin: 6px;
`

const ItineraryPoint = styled.div`
    border-radius: 50%;
    background-color: ${styles.Color.TaekusBlue};
    width: 4px;
    height: 4px;
    margin: 0 8px;
`

type ItineraryLineProps = {
    $segmentRatio: number,
}

const ItineraryLine = styled.div<ItineraryLineProps>`
    flex: ${props => props.$segmentRatio};
    height: 2px;
    background-color: ${styles.Color.TaekusBlue};
    ${styles.MediaQueries.Mobile} {
        height: 1px;
    }
`

const ItineraryPathDate = styled.div`
    display: flex;
    justify-content: space-between;
    color: ${styles.Color.NearBlack};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.28px;
`

const LegDetail = styled.div`
    color: ${styles.Color.NearBlack};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.28px;
`

const ItineraryContainer = styled.div`
    flex: 1;
`

const ItineraryPathAirportCode = styled.div`
    color: ${styles.Color.NearBlack};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 127%; /* 30.48px */
    letter-spacing: 0.24px;
    width: 60px;
    display: flex;
    &:first-child {
        margin-right: 16px;
    }
    &:last-child {
        justify-content: end;
        margin-left: 16px;
    }
`

const ItineraryPathAirport = styled.div`
    color: ${styles.Color.NearBlack};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 12.4px */
    letter-spacing: 0.1px;
`

const ItineraryPathContainer = styled.div`
    margin: 24px 0;
    width: 100%;
    height: 78px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

const ItineraryDate = styled.div`
    margin-top: 16px;
    color: ${styles.Color.NearBlack};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 138%; /* 22.08px */
    letter-spacing: 0.32px;
`

const ItineraryTitle = styled.div`
    color: ${styles.Color.NearBlack};
    /* HeadingLarge */
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 32px;
    margin-bottom: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 31.68px */
    letter-spacing: 0.32px;
    ${styles.MediaQueries.Print} {
        margin-bottom: 24px;
    }
`


const DetailValue = styled.div`
    color: ${styles.Color.Black};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    text-wrap: wrap;
    font-weight: 400;
    flex-wrap: wrap;
    line-height: 138%; /* 22.08px */
    letter-spacing: 0.32px;
`

const DetailLabel = styled.div`
    color: ${styles.Color.Black};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 12.4px */
    letter-spacing: 0.1px;
    margin-bottom: 8px;
`

const Content = styled(motion.div)`
    flex: 1;
    ${styles.MediaQueries.Desktop} {
        margin: ${styles.Spacing.M};
    }
    ${styles.MediaQueries.Mobile} {
        margin: ${styles.Spacing.S} ${styles.Spacing.S};
    }
`

const LoadingText = styled.div`
    font-family: ${styles.Font.Family.MonumentGrotesk};
    margin: ${styles.Spacing.XS};
`

const ActionsHeader = styled.div`
    color: ${styles.Color.NearBlack};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 32px;
    font-style: normal;
    max-width: 100%;
    font-weight: 400;
    line-height: 124%; /* 39.68px */
    letter-spacing: 0.32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${styles.MediaQueries.Desktop} {
        height: 40px;
        margin-bottom: 32px;
    }
    ${styles.MediaQueries.Mobile} {
        margin-bottom: ${styles.Spacing.S};
    }
    ${styles.MediaQueries.Print} {
        display: none;
    }
`

const LoadingContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 40px 0;
`

const Container = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    white-space: nowrap;
    overflow-x: hidden;
    ${styles.Scrollbar.defaultScrollbarStyles}
    ${styles.MediaQueries.Print} {
        height: auto;
    }
`

export default TripInspector