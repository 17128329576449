import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import { geocodeByPlaceId } from "react-google-places-autocomplete";

import { AnimatePresence, motion } from "framer-motion";
import { styled } from "styled-components"

import { useIsFirstRender } from "hooks/useIsFirstRender";

import { parseGoogleAddress } from "components/common/util";

import AddressInput from "components/signup/components/AddressInput";
import ArrowButton, { ArrowDirection } from "components/signup/components/ArrowButton";
import Button from "components/signup/components/Button";
import Checkbox from "components/signup/components/Checkbox";
import Input from "components/signup/components/Input";
import StepContainer, { getStepContainerMotionProps } from "components/signup/StepContainer";
import { StepDirection } from "components/signup/types";

import styles from "styles/styles";
import { updateApplication, updateLead, updateStep } from "../signupSlice";
import { SignupStep } from "../constants";

type CaptureBizInfoProps = {
}

const CaptureBizInfo = (props: CaptureBizInfoProps) => {
    const dispatch = useDispatch()
    
    const dateRef = useRef(null)
    const isFirstRender = useIsFirstRender()

    const application = useSelector((state: any) => state.signup.application)
    const leadUuid = useSelector((state: any) => state.signup.leadUuid)
    const stepDirection = useSelector((state: any) => state.signup.direction)

    const [isSameAddress, setIsSameAddress] = useState(
        application.businessAddressStreet === application.addressStreet &&
        application.businessAddressCity === application.addressCity &&
        application.businessAddressState === application.addressState &&
        application.businessAddressZip === application.addressZip
    )
    const [address1, setAddress1] = useState<any>(application.businessAddressStreet ? {
        label: `${application.businessAddressStreet}, ${application.businessAddressCity}, ${application.businessAddressState}`,
        value: undefined,
    } : undefined)
    const [address2, setAddress2] = useState(application.businessAddressLineTwo || '')
    const [date, setDate] = useState(application.formationDate ? moment(application.formationDate).format('YYYY-MM-DD') : '')
    const [percent, setPercent] = useState(Number(application.businessPercentOwnership) ? Number(application.businessPercentOwnership).toLocaleString() : '')
    const [isInputDisabled, setIsInputDisabled] = useState(false)

    const todaysDate = moment().format('YYYY-MM-DD')
    const isAddressInvalid = address1 === undefined
    const isFormationDateInvalid = date === '' || moment(date).isAfter(moment())
    const isPercentOwnershipInvalid = percent === ''
    const isButtonDisabled = (!isSameAddress && isAddressInvalid) || isFormationDateInvalid || isPercentOwnershipInvalid || isInputDisabled

    const handleBack = (ev: any) => { 
        ev.stopPropagation();
        dispatch(updateStep({ step: SignupStep.BizNAICS, direction: StepDirection.Left }))
    }

    const handleSubmit = async (ev: React.FormEvent) => {
        ev.preventDefault();
        setIsInputDisabled(true)

        const parsedBizAddress = address1?.value?.place_id && await geocodeByPlaceId(address1?.value?.place_id)
            .then((gAddress) => parseGoogleAddress(gAddress))
            .catch(() => undefined)

        const applicationChanges = {
            businessAddressStreet: isSameAddress ? application.addressStreet : parsedBizAddress?.street || application.businessAddressStreet,
            businessAddressCity: isSameAddress ? application.addressCity : parsedBizAddress?.city || application.businessAddressCity,
            businessAddressState: isSameAddress ? application.addressState : parsedBizAddress?.state || application.businessAddressState,
            businessAddressZip: isSameAddress ? application.addressZip : parsedBizAddress?.zip || application.businessAddressZip,
            businessAddressLineTwo: isSameAddress ? application.addressLineTwo : address2,
            formationDate: date !== '' ? date : undefined,
            businessPercentOwnership: Number(percent)
        }

        dispatch(updateApplication(applicationChanges))
        dispatch(updateLead({ leadUuid, application: applicationChanges }))
        dispatch(updateStep({ step: SignupStep.FinalReview, direction: StepDirection.Right }))
    }

    const toggleSameAddress = () => {
        setIsSameAddress(!isSameAddress)
    }

    const handleAddress1Change = (option: any) => {
        setAddress1(option)
    }

    const handleAddress2Change = (ev: React.ChangeEvent<HTMLInputElement>) => {
        setAddress2(ev.target.value)
    }

    const handleDateChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        setDate(ev.target.value)
    }

    const handlePercentChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        const asNumber = Number(ev.target.value)
        if (isNaN(asNumber) || asNumber > 100 || asNumber < 0 || ev.target.value.length > 5) return;

        setPercent(ev.target.value)
    }

    const addressMotionProps = {
        initial: isFirstRender ? { opacity: 1, height: 'auto', marginBottom: '8px' } : { opacity: 0, height: 0, marginBottom: 0 },
        exit: { opacity: 0, height: 0, marginBottom: 0 },
        animate: { opacity: 1, height: 'auto', marginBottom: '8px' }
    }

    // Autofocus the date input if isSameAddress
    useEffect(() => {
        if (isSameAddress) {
            (dateRef.current as any)?.focus()
        }
    }, []) // eslint-disable-line

    return <StepContainer
        {...getStepContainerMotionProps(stepDirection)}
        key='BusinessDetails'
    >
        <form className="d-flex flex-column align-items-center" onSubmit={handleSubmit}>
            <Title>We just need the last few details about {application.companyName || 'your business'}.</Title>
            <Content>
                <AddressCheckboxContainer htmlFor='check'>
                    <Checkbox
                        id='check'
                        checked={isSameAddress}
                        onClick={toggleSameAddress}
                    />
                    <CheckLabel>My personal/home address is the same as my business address.</CheckLabel>
                </AddressCheckboxContainer>
                <AnimatePresence mode='wait'>
                    {!isSameAddress && <motion.div {...addressMotionProps}>
                        <InputMargin>
                            <AddressInput autoFocus onChange={handleAddress1Change} value={address1} placeholder='Business Address' />
                        </InputMargin>
                        <Input onChange={handleAddress2Change} value={address2} label="Business Address Line 2"/>
                    </motion.div>}
                </AnimatePresence>
                <InputMargin>
                    <Input parentRef={dateRef} type='date' max={todaysDate} onChange={handleDateChange} value={date} label="Date of Formation"/>
                </InputMargin>
                <Input onChange={handlePercentChange} value={percent} label="% of Your Ownership"/>
            </Content>
            <ButtonContainer>
                <BackButtonContainer>
                    <ArrowButton onClick={handleBack} direction={ArrowDirection.Left}/>
                </BackButtonContainer>
                <Button disabled={isButtonDisabled} type="submit">Next</Button>
            </ButtonContainer>
        </form>
    </StepContainer>
}

const InputMargin = styled.div`
    margin-bottom: 8px;
`

const AddressCheckboxContainer = styled.label`
    min-width: 0;
    max-width: 100%;
    width: 100%;
    display: flex;
    align-items: start;
    margin-bottom: 24px;
`

const BackButtonContainer = styled.div`
    margin-right: 16px;
`

const ButtonContainer = styled.div`
    margin-top: 32px;
    display: flex;
`

const Content = styled.div`
    width: 308px;
`

const CheckLabel = styled.div`
    min-width: 0;
    color: ${styles.Color.TaekusGrey3};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    text-wrap: wrap;
    white-space: wrap;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 12.4px */
    letter-spacing: 0.2px;
    margin: 0;
    display: flex;
    flex: 1 1 auto;
    overflow: hidden:
    pointer-events: none;
    margin-left: 10px;
    cursor: pointer;
`

const Title = styled.div`
    color: ${styles.Color.TaekusBlack};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 39.68px */
    letter-spacing: 0.32px;
    margin-bottom: 16px;
    ${styles.MediaQueries.Mobile} {
        font-size: 24px;
    }
`

export default CaptureBizInfo