import React, { useEffect, useRef, useState } from "react"

import { AnimatePresence, motion } from "framer-motion";
import { styled } from "styled-components"

import BackgroundImage from 'assets/global/img/adjustedBG.png'
import { expandVerticallyMotionProps, fadeInOutMotionProps } from "styles/motionConstants";
import styles from "styles/styles";
import Checkbox from "../components/Checkbox";
import CommonButton from "../components/Button";
import { useDispatch, useSelector } from "react-redux";
import { updateStep } from "../signupSlice";
import { SignupStep } from "../constants";
import { ProductType, StepDirection } from "../types";

const ElectronicFundsTransferTermsLink = 'https://taekus.com/legal/eft-terms'
const RewardsAgreementLink = 'https://taekus.com/legal/rewards-terms'
const CreditTermsLink = 'https://taekus.com/legal/credit-terms'

const messages = {
    [ProductType.Credit]: {
        title: ', you’re preapproved for the Taekus Credit Card!',
        subtitle: 'The only credit card with next-gen travel rewards',
    },
    [ProductType.Debit]: {
        title: ', you’ve been approved for a Taekus Debit Card!',
        subtitle: 'All Taekus debit cards are issued by Stearns Bank, NA.',
    },
}

type OfferProps = {
}

const Offer = (props: OfferProps) => {
    const dispatch = useDispatch()
    
    const imgRef = useRef(null)

    const offer = useSelector((state: any) => state.signup.offer)
    const application = useSelector((state: any) => state.signup.application)
    const products = useSelector((state: any) => state.signup.products)

    const [showError, setShowError] = useState(false)
    const [isImageLoaded, setIsImageLoaded] = useState(false)
    const [isCardholderAgreed, setIsCardholderAgreed] = useState(false)
    const [isECDAgreed, setIsECDAgreed] = useState(false)
    const [isCreditReportAgreed, setIsCreditReportAgreed] = useState(false)

    const selectedProduct = products?.find((product: any) => product.productCode === application.productCode)
    const isCreditProduct = selectedProduct?.productType === ProductType.Credit
    const isAgreementsInvalid = !(isCardholderAgreed && isECDAgreed && (!isCreditProduct || isCreditReportAgreed))

    const handleSubmit = () => {
        if (isAgreementsInvalid) {
            setShowError(true)
        } else {
            setShowError(false)
            dispatch(updateStep({
                step: isCreditProduct ? SignupStep.ReviewDisclosure : SignupStep.CreateUsername,
                direction: StepDirection.Right
            }))
        }
    }

    const loadBackgroundImage = () => {
        const bgImage = new Image();

        bgImage.onload = () => {
            if (imgRef.current) {
                (imgRef?.current as any).style.backgroundImage = `url(${bgImage.src})`;
            }
            setIsImageLoaded(true)
        }
        bgImage.onerror = () => {
            setIsImageLoaded(true)
        }

        bgImage.src = BackgroundImage;
    }

    // On component mount
    useEffect(() => {
        loadBackgroundImage()
    }, []) 

    return <Container
        {...fadeInOutMotionProps}
        key='Offer'
        ref={imgRef}
        isImageLoaded={isImageLoaded}
    >
        <Content>
            <Title>{application.firstName}{messages[selectedProduct?.productType as ProductType]?.title}</Title>
            <Subtitle>{messages[selectedProduct?.productType as ProductType]?.subtitle}</Subtitle>
            {selectedProduct.productType === ProductType.Debit ? <Grid>
                <GridItem>
                    <GridTitle>FDIC-insured Checking Accounts</GridTitle>
                    <GridSubtitle>Keep your money safe with FDIC insurance for your account account.</GridSubtitle>
                </GridItem>
                <GridItem>
                    <GridTitle>Rewards-Earning Debit Card</GridTitle>
                    <GridSubtitle>Compound your points-earning by adding your  Taekus debit card to your wallet</GridSubtitle>
                </GridItem>
                <GridItem>
                    <GridTitle>Complimentary Transfers/Checks</GridTitle>
                    <GridSubtitle>Send and receive money transfers without worrying about fees.</GridSubtitle>
                </GridItem>
                <GridItem>
                    <GridTitle>No Effect on Your Credit Score</GridTitle>
                    <GridSubtitle>Opening a Taekus checking account doesn’t require a credit check.</GridSubtitle>
                </GridItem>
            </Grid> : <>
                <DetailContainer>
                    <div style={{flex: 1}}>
                        <DetailTitle>Credit Limit</DetailTitle>
                        <CreditLimit>${Number(offer.credit?.creditLimit).toLocaleString()}</CreditLimit>
                    </div>
                    <div style={{ height: '41px', width: '1px', backgroundColor: styles.Color.TaekusGrey4, margin: '0 16px' }}/>
                    <div style={{flex: 1}}>
                        <DetailTitle>Interest Rate</DetailTitle>
                        <InterestRate>{offer.credit?.apr}% APR variable</InterestRate>
                    </div>
                </DetailContainer>
                <div style={{display: 'flex', width: '100%', marginTop: '40px'}}>
                    <MultiplierContainer>
                        <Multiplier>5x</Multiplier>
                        <MultiplierLabel>Travel</MultiplierLabel>
                    </MultiplierContainer>
                    <MultiplierContainer>
                        <Multiplier>3x</Multiplier>
                        <MultiplierLabel>on Restaurants</MultiplierLabel>
                    </MultiplierContainer>
                    <MultiplierContainer>
                        <Multiplier>3x</Multiplier>
                        <MultiplierLabel>Groceries</MultiplierLabel>
                    </MultiplierContainer>
                </div>
            </>}
            <Agreements>
                <CheckboxContainer htmlFor="cardholder">
                    <Checkbox id='cardholder' checked={isCardholderAgreed} onClick={(ev) => { ev?.preventDefault(); setIsCardholderAgreed(!isCardholderAgreed) }}/>
                    <CheckboxLabel>I agree and consent to the <Link target='_blank' href={selectedProduct?.fileBundleCardHolderAgreementUrl || CreditTermsLink}>Taekus Consumer Cardholder Agreement</Link> and <Link target='_blank' href={selectedProduct?.fileBundleRewardsTermsUrl || RewardsAgreementLink}>Rewards Agreement</Link>.</CheckboxLabel>
                </CheckboxContainer>
                <CheckboxContainer htmlFor="ecd">
                    <Checkbox id='ecd' checked={isECDAgreed} onClick={(ev) => { ev?.preventDefault(); setIsECDAgreed(!isECDAgreed) }}/>
                    <CheckboxLabel>I have reviewed and agree to the terms of the <Link target="_blank" href={selectedProduct?.fileBundleElectronicFundsTransferUrl || ElectronicFundsTransferTermsLink}>Electronic Funds Transfer Disclosure</Link>.</CheckboxLabel>
                </CheckboxContainer>
                {isCreditProduct && <CheckboxContainer htmlFor="report">
                    <Checkbox id='report' checked={isCreditReportAgreed} onClick={(ev) => { ev?.preventDefault(); setIsCreditReportAgreed(!isCreditReportAgreed) }}/>
                    <CheckboxLabel>By accepting this offer, I authorize Taekus to obtain information to evaluate my income and to request my consumer report from credit reporting agencies, which is a hard inquiry that may affect my credit.</CheckboxLabel>
                </CheckboxContainer>}
            </Agreements>
            <CommonButton onClick={handleSubmit}>Accept Offer</CommonButton>
            <AnimatePresence mode='wait'>
                {(showError && isAgreementsInvalid) && <motion.div style={{width: 'fit-content'}} {...expandVerticallyMotionProps}>
                    <ErrorMessage>You must accept all agreements to continue.</ErrorMessage>
                </motion.div>}
            </AnimatePresence>
            <Disclaimer>All Taekus cards are issued by Stearns Bank, NA. Member FDIC</Disclaimer>
        </Content>
    </Container>
}

const ErrorMessage = styled.div`
    color: ${styles.Color.TaekusRed};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 14.88px */
    letter-spacing: 0.24px;
    padding: 8px 16px 0;
`

const Link = styled.a`
    text-decoration: underline;
    color: #767ED8 !important;
`

const Disclaimer = styled.div`
    margin-top: 24px;
    color: ${styles.Color.TaekusGrey3};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
`

const MultiplierLabel = styled.div`
    color: ${styles.Color.TaekusGrey5};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 133.333% */
`

const Multiplier = styled.div`
    color: ${styles.Color.White};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 60px; /* 125% */
`

const MultiplierContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.20);
    flex: 1;
    &:not(&:first-child) {
        margin-left: 8px;
    }
    &:not(&:last-child) {
        margin-right: 8px;
    }
`

const InterestRate = styled.div`
    margin-top: 8px;
    color: ${styles.Color.TaekusCream};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 124%; /* 17.36px */
    letter-spacing: 0.14px;
`

const CreditLimit = styled.div`
    margin-top: 4px;
    color: ${styles.Color.White};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 124%; /* 24.8px */
    letter-spacing: 0.2px;
`

const DetailTitle = styled.div`
    color: ${styles.Color.White};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 12.4px */
    letter-spacing: 0.4px;
    text-transform: uppercase;
`

const DetailContainer = styled.div`
    padding: 16px 24px;
    display: flex;
    width: 100%;
`

const CheckboxContainer = styled.label`
    display: flex;
    padding: 12px 24px;
    margin: 0;
    width: 100%;
    border-radius: 4px;
    border: 1px solid transparent;
    cursor: pointer;
    user-select: none;
    &:hover {
        border: 1px solid ${styles.Color.TaekusPurple};
    }
    ${styles.Animation.transitionStyles}
`

const CheckboxLabel = styled.div`
    color: ${styles.Color.TaekusGrey4};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 14.88px */
    letter-spacing: 0.24px;
    margin: 0 0 0 16px;
`

type AgreementsProps = {
    centerContent?: boolean,
}

const Agreements = styled.div<AgreementsProps>`
    display: flex;
    flex-direction: column;
    ${props => !props.centerContent && 'align-items: start;'}
    margin: 40px 0;
`

const Button = styled.button<any>`
    min-width: 120px;
    display: flex;
    justify-content: center;
    text-align: center;
    background-color: transparent;
    padding: 10px 25px;
    border-radius: 2px;
    border: 1px solid rgba(255, 255, 255, 0.25);
    color: ${styles.Color.White};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%; /* 19.32px */
    letter-spacing: 0.28px;
    &:hover {
        ${props => props.disabled && 'box-shadow: 0px 0px 16px 0px rgba(124, 61, 118, 0.15);'}
        border: 1px solid ${styles.Color.TaekusPurple};
    }
    ${styles.Animation.transitionStyles}
`

const GridTitle = styled.div`
    color: ${styles.Color.White};
    text-align: center;
    /* Title - Small */
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 133.333% */
`

const GridSubtitle = styled.div`
    color: ${styles.Color.TaekusGrey3};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
`

const GridItem = styled.div`
    flex-basis: calc(50% - 8px);
    padding: 16px 0;
    &:nth-child(-n+2) {
        border-bottom: 1px solid rgba(255, 255, 255, 0.20);
    }
`

const Grid = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 24px;
`

const Title = styled.div`
    color: ${styles.Color.White};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 39.68px */
    letter-spacing: 0.32px;
`

const Subtitle = styled.div`
    margin-top: 16px;
    margin-bottom: 24px;
    color: ${styles.Color.TaekusGrey4};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%; /* 22.08px */
    letter-spacing: 0.32px;
`

const Content = styled(motion.div)`
    min-width: 100px;
    width: 50%;
    min-height: 100px;
    margin: 0 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const Container = styled(motion.div)<{ isImageLoaded: boolean }>`
    width: 100dvw;
    height: 100dvh;
    display: flex;
    justify-content: end;
    background-size: cover;
    background-position: center;
    background-color: #4E3259;
    -moz-transition: opacity 1.2s ease-in;
    -o-transition: opacity 1.2s ease-in;
    -webkit-transition: opacity 1.2s ease-in;
    transition: opacity 1.2s ease-in;
    opacity: ${props => props.isImageLoaded ? 1 : 0};
    color: white;
    font-family: ${styles.Font.Family.MonumentGrotesk};
`

export default Offer