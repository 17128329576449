import React from "react"
import { noop } from "lodash";

import { AnimatePresence, motion } from "framer-motion";
import { styled } from "styled-components"

import { fadeInOutMotionProps } from "styles/motionConstants";
import styles from "styles/styles";

import { ReactComponent as Checkmark } from "assets/svg/Checkmarks/RoundedCheckmark.svg";

type CheckboxProps = {
    checked: boolean,
    onClick: (ev?: React.MouseEvent) => void,
    id?: string,
}

const Checkbox = (props: CheckboxProps) => {
    
    const { checked, id, onClick } = props;

    return <Container onClick={onClick}>
        <HiddenInput
            id={id}
            type="checkbox"
            checked={checked}
            // Add noop onChange to silence React warning
            onChange={noop}
        />
        <StyledBackground checked={checked}>
            <AnimatePresence mode="wait">
                {checked && <CheckmarkContainer {...fadeInOutMotionProps}>
                    <StyledCheckmark/>
                </CheckmarkContainer>}
            </AnimatePresence>
        </StyledBackground>
    </Container>
}

const StyledCheckmark = styled(Checkmark)`
    width: 9px;
    height: auto;
    stroke: black;
`

const CheckmarkContainer = styled(motion.div)`
    width: min-content;
    height: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const HiddenInput = styled.input`
    display: none;
`

type StyledBackgroundProps = {
    checked: boolean;
}

const StyledBackground = styled.div<StyledBackgroundProps>`
    border-radius: 2px;
    border: 1px solid ${props => props.checked ? styles.Color.TaekusGreen : styles.Color.TaekusGrey4};
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.checked ? styles.Color.TaekusGreen : styles.Color.White};
    ${styles.Animation.transitionStyles}
`

const Container = styled.div`
    
`

export default Checkbox