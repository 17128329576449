import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router"
import { NavLink } from "react-router-dom"

import { noop } from "lodash"
import moment from "moment"

import { AnimatePresence, motion } from "framer-motion"
import styled from "styled-components"

import { Actions as BookingActions } from 'redux/features/flightBook'

import { AppPath } from "components/appRouter/constants"
import Navigation, { NavColor } from "components/navbar/Navigation"
import Modal from "components/common/Modal"

import { TripType, defaultSearchOptions } from "components/pages/Rewards/constants"
import { PassengerValidationErrorLabels, defaultTravelerInfo, defaultTravelerInfoErrors } from "components/pages/Rewards/Flights/constants"
import { getSearchOptionsFromLocalStorage } from "components/pages/Rewards/utils"
import { TravelerDetails, TravelerInfo, TravelerInfoErrors } from "components/pages/Rewards/Flights/types"

import BookingResult from "components/pages/Rewards/Flights/FlightBooking/BookingResult"
import ConfirmAndPay from "components/pages/Rewards/Flights/FlightBooking/ConfirmAndPay"
import FlightBookingSidebar from "components/pages/Rewards/Flights/FlightBooking/FlightBookingSidebar"
import ManageTravelerInfo from "components/pages/Rewards/Flights/FlightBooking/ManageTravelerInfo"
import TripSummary from "components/pages/Rewards/Flights/FlightBooking/TripSummary"

import { validateEmail, validateString } from "utils/utils"

import { fadeInOutMotionProps } from "styles/motionConstants"
import styles from "styles/styles"

import { ReactComponent as Checkmark } from "assets/svg/Checkmarks/Checkmark.svg";

import 'components/pages/Rewards/phoneInputStyles.css'
import FinalAvailabilityWs from "components/pages/Rewards/Flights/FinalAvailabilityWs";
import { buildPriceDetailPayload, isPassengerDetailsValid } from "components/pages/Rewards/Flights/FlightBooking/util"
import { useIsFirstRender } from "hooks/useIsFirstRender";


export enum ConfirmationStep {
    ReviewItinerary,
    PassengerInfo,
    PayAndConfirm,
    BookingResult,
}

const messages = {
    FlowStepTitle: {
        [ConfirmationStep.ReviewItinerary]: 'Review Trip Summary',
        [ConfirmationStep.PassengerInfo]: 'Traveler Info',
        [ConfirmationStep.PayAndConfirm]: 'Confirm and Pay',
    }
}

const FlightBooking = () => {
    const dispatch = useDispatch()
    const history = useHistory()

    // Redux state
    const banking = useSelector((state: any) => state.banking)
    const currentUser = useSelector((state: any) => state.currentUser.currentUser)
    const outboundItinerary = useSelector((state: any) => state.flightSearch.searchParams.outboundItinerary)
    const inboundItinerary = useSelector((state: any) => state.flightSearch.searchParams.inboundItinerary)
    const sessionId = useSelector((state: any) => state.flightSearch.searchParams.sessionId)
    const flightSearch = useSelector((state: any) => state.flightSearch)
    const flightBook = useSelector((state: any) => state.flightBook)
    const priceDetail = useSelector((state: any) => state.flightBook.priceDetail)
    const numberOfPassengers = useSelector((state: any) => state.flightSearch.searchParams.numPax.value)
    const isMobile = useSelector((state: any) => state.global.isMobile)

    // Component state
    const [selectedPaymentAccountUuid, setSelectedPaymentAccountUuid] = useState<string | undefined>(banking.account?.uuid)
    const [confirmationStep, setConfirmationStep] = useState(ConfirmationStep.ReviewItinerary)
    const [isFailedModalOpen, setIsFailedModalOpen] = useState(false)
    const [isAwaitingBooking, setIsAwaitingBooking] = useState(false)
    const [initialShownPriceDiff, setInitialShownPriceDiff] = useState<number | undefined>(undefined)
    const [selectedUpsellUuid, setSelectedUpsellUuid] = useState<string | undefined>(undefined)
    const [isShowMobileUpsell, setIsShowMobileUpsell] = useState(false);

    // Traveler Info
    const [isAddingPassenger, setIsAddingPassenger] = useState(false)
    const [showTravelerValidationErrors, setShowTravelerValidationErrors] = useState(false)
    const [travelerInfo, setTravelerInfo] = useState<TravelerInfo>({
        phone: currentUser?.phone || defaultTravelerInfo.phone,
        email: currentUser?.email ||defaultTravelerInfo.email,
        selectedTravelerUuids: []
    })
    const [travelerInfoErrors, setTravelerInfoErrors] = useState<TravelerInfoErrors>(defaultTravelerInfoErrors)

    const pageBody = useRef(null)

    const searchOptions = getSearchOptionsFromLocalStorage() || defaultSearchOptions
    const selectedUpsellFare = priceDetail.fares.find((fare: any) => fare.uuid === selectedUpsellUuid)
    const isPassportImageRequired = priceDetail.transferPartnerData?.partnerCode === 'VN'

    // Final availability websocket check
    const [isLoadingFinalAvailability, setIsLoadingFinalAvailability] = useState(false);
    const [completedFinalAvailability, setCompletedFinalAvailability] = useState<any>(null);
    const [websocketRequestViaCounterToChildComponent, setWebsocketRequestViaCounterToChildComponent] = useState<number>(0);
    const [finalAvailabilityParams, setFinalAvailabilityParams] = useState<any>({});
    const [awardTicketAvailable, setAwardTicketAvailable] = useState<any>(null);
    const requestIdToParams = useRef<any>({});
    const isFirstRender = useIsFirstRender()


    const incrementConfirmationStep = () => {
        // If not the final step, scroll to the top of the page
        if (confirmationStep < ConfirmationStep.PayAndConfirm) {
            (pageBody.current as any).scrollTo!({ top: 0, behavior: 'smooth' })
            if (confirmationStep === ConfirmationStep.PassengerInfo) {
                if (!isTravelerInfoValid(travelerInfo)) {
                    setShowTravelerValidationErrors(true)
                    return;
                } else {
                    setShowTravelerValidationErrors(false)
                }
            } 
            setConfirmationStep(confirmationStep + 1)
        } else {
            bookItinerary()
        }
    }

    const bookItinerary = () => {
        setIsAwaitingBooking(true)
        const formattedPassengerDetails = flightBook.bookingTravelers
            .filter((traveler: any, index: number) => travelerInfo.selectedTravelerUuids.includes(index.toString()))
            .map((traveler: any, index: number) => {
                return {
                    passport_image_uuid: traveler.passportImage, // only used for validation. Needed for backwards compatibility
                    uuid: traveler.uuid,
                    travellerNum: index + 1,
                }
            })

        const bookingParams = {
            priceDetail: {
                ...priceDetail,
                pricePointsBase: selectedUpsellFare?.pricePointsBase || priceDetail.pricePointsBase,
				pricePointsTaxes: selectedUpsellFare?.pricePointsTaxes || priceDetail.pricePointsTaxes,
                taxes: selectedUpsellFare?.taxes || priceDetail.taxes,
				upsellUuid: selectedUpsellFare?.uuid,
            },
            primary_email: travelerInfo?.email,
            primary_phone_number: travelerInfo?.phone,
            passengers: formattedPassengerDetails,
            outboundItineraryId: outboundItinerary.id,
            inboundItineraryId: inboundItinerary?.id,
            cardAccountUuid: banking.account.uuid,
            sessionId: priceDetail?.sessionId
          }
          dispatch(BookingActions.bookItinerary(bookingParams))
    }

    const updateAndValidateTravelerInfo = (updatedInfo: TravelerInfo) => {
        setTravelerInfo(updatedInfo)
        isTravelerInfoValid(updatedInfo)
    }

    const validateTravelers = (travelers: TravelerDetails[], selectedTravelerUuids: string[]) =>{
        if (selectedTravelerUuids.length !== numberOfPassengers) {
            return PassengerValidationErrorLabels.Passengers.TooFewPassengers
        }

        if (!travelers.some((traveler: TravelerDetails) => moment(traveler.dateOfBirth).isSameOrBefore(moment().subtract(18, 'years')))) {
            return PassengerValidationErrorLabels.Passengers.NoAdult
        }

        if (!travelers.some((traveler: TravelerDetails) => isPassengerDetailsValid(traveler, priceDetail.isDomesticItinerary, isPassportImageRequired))) {
            return PassengerValidationErrorLabels.Passengers.Incomplete
        }
        return undefined
    }

    const isTravelerInfoValid = (info: TravelerInfo) => {
        const updatedErrors = {
            email: !validateEmail(info.email) ? PassengerValidationErrorLabels.Email.Invalid : undefined,
            phone: !validateString(info.phone) ? PassengerValidationErrorLabels.Phone.Invalid : undefined,
            passengers: validateTravelers(flightBook.bookingTravelers, info.selectedTravelerUuids),
        }
        setTravelerInfoErrors(updatedErrors)

        return !Object.values(updatedErrors).some(error => error !== undefined)
    }

    const getStepContent = () => {
        switch (confirmationStep) {
            case ConfirmationStep.BookingResult:
                return <StepContainer key={ConfirmationStep.BookingResult} {...fadeInOutMotionProps}>
                    <div className='w-100 d-flex flex-column align-items-center'>
                        <SuccessContainer>
                            <CheckmarkContainer>
                                <Checkmark fill='#00bf0c' className='w-100 h-auto'/>
                            </CheckmarkContainer>
                            {outboundItinerary?.isAwardTicket ? <SubText>
                                Your ticket is currently being processed by our team. Look out for an email when your ticket has been confirmed.
                            </SubText> :
                            <SubText>
                                Your flight to {outboundItinerary?.slices.at(-1).segments.at(-1).destinationCityName} booked! <strong>{flightBook.confirmedBooking.pnr ? `(Booking code: ${flightBook.confirmedBooking.taekusId})` : ''}</strong>
                            </SubText>}
                        </SuccessContainer>
                        <div className="d-flex">
                            <StyledNavLink exact to={AppPath.Rewards}>Back to Rewards</StyledNavLink>
                            <StyledNavLink exact to={`${AppPath.MyTrips}/${flightBook.confirmedBooking.uuid}`}>View your Trip</StyledNavLink>
                        </div>
                    </div>
                </StepContainer>
            case ConfirmationStep.PayAndConfirm:
                return <ConfirmAndPay
                    selectedUpsellUuid={selectedUpsellUuid}
                    selectedPaymentAccountUuid={selectedPaymentAccountUuid}
                    updateSelectedPaymentAccountUuid={setSelectedPaymentAccountUuid}
                />
            case ConfirmationStep.PassengerInfo:
                return <ManageTravelerInfo
                    isAddingPassenger={isAddingPassenger}
                    updateIsAddingPassenger={setIsAddingPassenger}
                    travelerInfo={travelerInfo}
                    updateTravelerInfo={updateAndValidateTravelerInfo}
                    showValidationErrors={showTravelerValidationErrors}
                    validationErrors={travelerInfoErrors}
                />
            case ConfirmationStep.ReviewItinerary:
            default:
                return <TripSummary
                    updateSelectedUpsellUuid={setSelectedUpsellUuid}
                    selectedUpsellUuid={selectedUpsellUuid}
                    isShowMobileUpsell={isShowMobileUpsell}
                    updateIsShowMobileUpsell={setIsShowMobileUpsell}
                />
        }
    }

    // Used to recheck input validations after a passport is uploaded
    useEffect(() => {
        isTravelerInfoValid(travelerInfo)
    }, [flightBook.passportImage]) // eslint-disable-line

    // Redirect to flight search if outbound itin is undefined on page load, else fetch price detail
    useEffect(() => {
        if (!outboundItinerary) {
            history.push('/rewards/flights')
        } else {
            dispatch(BookingActions.fetchItineraryPrice(buildPriceDetailPayload(searchOptions, outboundItinerary, inboundItinerary, sessionId)))
            dispatch(BookingActions.getTravelers())
        }
    }, [dispatch]) // eslint-disable-line

     // Open modal if pricing failed or the award flight is unavailable
    useEffect(() => {
        if (!isFirstRender && ((!flightBook.pricingIsLoading && flightBook.pricingError) || awardTicketAvailable === false)) {
            setIsFailedModalOpen(true)
        }
    }, [flightBook.pricingIsLoading, awardTicketAvailable]) // eslint-disable-line

    // Update step to booking result when book API is completed
    useEffect(() => {
        if (!flightBook.isLoading && isAwaitingBooking) {
            setIsAwaitingBooking(false)
            setConfirmationStep(ConfirmationStep.BookingResult)
        }
    }, [flightBook.isLoading]) // eslint-disable-line

    useEffect(() => {
        if (currentUser.passportImageUuid) {
            dispatch(BookingActions.setPassportImage(0, currentUser.passportImageUuid))
        } else {
            dispatch(BookingActions.clearPassportImages())
        }
    }, [dispatch]) // eslint-disable-line

    // Start award availability confirmation if priceDetail confirms award ticket
    useEffect(() => {
        const containsAwardTicket = !!flightBook.priceDetail?.reservations?.filter((reservation: any) => !!reservation.isAwardTicket)?.length
        if (flightBook.successful && containsAwardTicket) {
            // Cache source id not set in enum. Look at backend code for mapping.
            if (flightBook.priceDetail?.reservations?.every((reservation: any) => reservation?.cacheSource === 5)) {
                // Pricing confirms availability
                setAwardTicketAvailable(true)
                setIsLoadingFinalAvailability(false)
            } else {
                setAwardTicketAvailable(null)
                const list_params = flightBook.priceDetail.reservations.map(
                   (reservation: any) => ({
                   "origin": reservation.itineraries[0].segments[0].departurePoint,
                   "destination": reservation.itineraries[0].segments[reservation.itineraries[0].segments.length - 1].arrivalPoint,
                   "itineraries": reservation.itineraries,
                   "departure_date": moment(reservation.itineraries[0].segments[0].localDepartureTimeDate).format('YYYY-MM-DD'),
                   "return_date": searchOptions.tripType === TripType.RoundTrip ? moment(searchOptions.returnDate).format('YYYY-MM-DD') : undefined,
                   "num_pax": searchOptions.numberOfPassengers ?? 1,
                   "trip_type": searchOptions.tripType,
                   "outbound_source": flightBook.priceDetail.reservations[0].itineraries[0].source,
                   "inbound_source":  searchOptions.tripType === TripType.RoundTrip ? flightBook.priceDetail.reservations[0].itineraries[1].source : undefined,
                   "outbound_routed_flights": reservation.outboundRoutedFlights?.map(
                       (routedFlight: any) => ({
                           "origin": routedFlight.segments[0].originCode,
                           "destination": routedFlight.segments[routedFlight.segments.length - 1].destinationCode,
                           "departure_date": moment(routedFlight.departureTime).format('YYYY-MM-DD'),
                           "source": routedFlight.cacheSource,
                           "cabin_class": routedFlight.cabinClass,
                           "segments": routedFlight.segments?.map(
                               (segment: any) => ({
                                   "origin": segment.originCode,
                                   "destination": segment.destinationCode,
                                   "cabin": segment.cabinName,
                                   "flt_number": segment.flightNumber,
                                   "operating_carrier_code": segment.operatingCarrier
                               })
                           ),
                       })
                   ),
                   "inbound_routed_flights": reservation.inboundRoutedFlights?.map(
                       (routedFlight: any) => ({
                           "origin": routedFlight.segments[0].originCode,
                           "destination": routedFlight.segments[routedFlight.segments.length - 1].destinationCode,
                           "departure_date": moment(routedFlight.departureTime).format('YYYY-MM-DD'),
                           "source": routedFlight.cacheSource,
                           "cabin_class": routedFlight.cabinClass,
                           "segments": routedFlight.segments?.map(
                               (segment: any) => ({
                                   "origin": segment.originCode,
                                   "destination": segment.destinationCode,
                                   "cabin": segment.cabinName,
                                   "flt_number": segment.flightNumber,
                                   "operating_carrier_code": segment.operatingCarrier
                               })
                           )
                       })
                   )
                }))

                setFinalAvailabilityParams(list_params)
                setWebsocketRequestViaCounterToChildComponent(current => current + 1)
            }
        }
    }, [flightBook.priceDetail]);  // eslint-disable-line

    useEffect(() => {
        if (completedFinalAvailability && Object.keys(completedFinalAvailability).length === 0) {
            // If no live award is returned, we cannot determine no inventory exists.
            // Block the user from proceeding.
            setAwardTicketAvailable(false)
            setIsLoadingFinalAvailability(false)
        } else if (flightBook.priceDetail?.reservations && completedFinalAvailability && Object.keys(completedFinalAvailability).length > 0) {
            setIsLoadingFinalAvailability(false)
            setAwardTicketAvailable(completedFinalAvailability.exists);
        }
    }, [flightBook.priceDetail?.itineraries?.[0]?.segments, completedFinalAvailability]) // eslint-disable-line

    // Set upsell selection if upsell is available, else get price delta for display
    useEffect(() => {
        if (!isFirstRender && flightBook.pricingIsLoading === false && priceDetail.sessionId !== '') {
            if (priceDetail.fares.length > 0) {
                const outboundPrice = flightSearch.searchParams?.outboundItinerary?.priceTotal || 0
                const inboundPrice = flightSearch.searchParams?.inboundItinerary?.priceTotal || 0
                const initialPrice = Math.max(inboundPrice, outboundPrice)

                let closestUpsellDelta: number | undefined = undefined
                let closestUpsellUuid: string | undefined = undefined

                const getPriceDelta = (fare: any) => {
                    return (fare.priceTotal - initialPrice) / initialPrice
                }

                priceDetail.fares.forEach((fare: any, index: number) => {
                    if (closestUpsellDelta === undefined || Math.abs(getPriceDelta(fare)) < Math.abs(closestUpsellDelta)) {
                        closestUpsellDelta = getPriceDelta(fare)
                        closestUpsellUuid = fare.uuid
                    }
                })
                setInitialShownPriceDiff((closestUpsellDelta || 0) * 100)
                setSelectedUpsellUuid(closestUpsellUuid)
            } else {
                const outboundPrice = flightSearch.searchParams?.outboundItinerary?.priceTotal || 0
                const inboundPrice = flightSearch.searchParams?.inboundItinerary?.priceTotal || 0
                const initialPrice = Math.max(inboundPrice, outboundPrice)
                setInitialShownPriceDiff(((priceDetail.priceTotal - initialPrice) / initialPrice) * 100)
            }
        }
    }, [flightBook.pricingIsLoading]) // eslint-disable-line

    return <Container ref={pageBody}>
        <AnimatePresence mode='wait'>
            {confirmationStep === ConfirmationStep.BookingResult ? <BookingResult/> : <AnimatedContainer
                key='confirmation'
                initial={{ opacity: 0 }}
                exit={{ opacity: 0 }}
                animate={{ opacity: 1 }}
            >
                <Navigation color={NavColor.Black} />
                <Content>
                    <PageContentContainer>
                        {isMobile ? <MobileProgressBarContainer>
                            <MobileProgressBarLabel>{messages.FlowStepTitle[confirmationStep]}</MobileProgressBarLabel>
                            <MobileProgressBar>
                                <MobileProgressBarInner confirmationStep={confirmationStep}/>
                            </MobileProgressBar>
                        </MobileProgressBarContainer> : <ProgressBarContainer>
                            <StepContainer
                                onClick={confirmationStep >= ConfirmationStep.ReviewItinerary ? () => {
                                    setConfirmationStep(ConfirmationStep.ReviewItinerary)
                                } : noop}
                                completed={confirmationStep >= ConfirmationStep.ReviewItinerary}
                            >
                                <StepLabel 
                                    completed={confirmationStep >= ConfirmationStep.ReviewItinerary}
                                    active={confirmationStep === ConfirmationStep.ReviewItinerary}
                                >
                                    Trip Summary
                                </StepLabel>
                                <StepBar completed={confirmationStep >= ConfirmationStep.ReviewItinerary} active={confirmationStep === ConfirmationStep.ReviewItinerary}/>
                            </StepContainer>
                            <StepContainer
                                onClick={confirmationStep >= ConfirmationStep.PassengerInfo ? () => {
                                    setConfirmationStep(ConfirmationStep.PassengerInfo)
                                } : noop}
                                completed={confirmationStep >= ConfirmationStep.PassengerInfo}
                            >
                                <StepLabel
                                    completed={confirmationStep >= ConfirmationStep.PassengerInfo}
                                    active={confirmationStep === ConfirmationStep.PassengerInfo}
                                >
                                    Traveler Information
                                </StepLabel>
                                <StepBar completed={confirmationStep >= ConfirmationStep.PassengerInfo} active={confirmationStep === ConfirmationStep.PassengerInfo}/>
                            </StepContainer>
                            <StepContainer
                                onClick={confirmationStep >= ConfirmationStep.PayAndConfirm ? () => {
                                    setConfirmationStep(ConfirmationStep.PayAndConfirm)
                                } : noop}
                                completed={confirmationStep >= ConfirmationStep.PayAndConfirm}
                            >
                                <StepLabel
                                    completed={confirmationStep >= ConfirmationStep.PayAndConfirm}
                                    active={confirmationStep === ConfirmationStep.PayAndConfirm}
                                >
                                    Confirm & Pay
                                </StepLabel>
                                <StepBar completed={confirmationStep >= ConfirmationStep.PayAndConfirm} active={confirmationStep === ConfirmationStep.PayAndConfirm}/>
                            </StepContainer>
                        </ProgressBarContainer>}
                        <StepContentContainer key={confirmationStep} {...fadeInOutMotionProps}>
                            {getStepContent()}
                        </StepContentContainer>
                    </PageContentContainer>
                    <FlightBookingSidebar
                        priceDiff={initialShownPriceDiff}
                        isShowMobileUpsell={isShowMobileUpsell}
                        isAddingPassenger={isAddingPassenger}
                        incrementFlowStep={incrementConfirmationStep}
                        currentFlowStep={confirmationStep}
                        searchOptions={searchOptions}
                        selectedUpsellUuid={selectedUpsellUuid}
                        selectedPaymentAccountUuid={selectedPaymentAccountUuid}
                        isLoadingFinalAvailability={isLoadingFinalAvailability}
                    />
                </Content>
            </AnimatedContainer>}
        </AnimatePresence>
        {!flightBook.pricingIsLoading && isFailedModalOpen && <Modal title={'No Ticket Availability'}>
            <FailedModalContentContainer>
                <FailedModalBody>
                    <FailedModalText>Unfortunately, the itinerary you've selected is no longer available.</FailedModalText>
                    <FailedModalText>Please select another itinerary.</FailedModalText>
                </FailedModalBody>
                <div className="w-100 d-flex justify-content-end">
                    <Button as={NavLink} to={AppPath.FlightSearch}>Return to Redemptions</Button>
                </div>
            </FailedModalContentContainer>
        </Modal>}
        <FinalAvailabilityWs
            setIsLoading={setIsLoadingFinalAvailability}
            setResponse={setCompletedFinalAvailability}
            websocketRequestViaCounter={websocketRequestViaCounterToChildComponent}
            params={finalAvailabilityParams}
            requestIdToParams={requestIdToParams}
        />
    </Container>
}

const AnimatedContainer = styled(motion.div)`
    display: flex;
    flex-direction: column;
    flex: 1;
`

const FailedModalText = styled.div`
    &:first-child {
        margin-bottom: 10px;
    }
`

type MobileProgressBarInnerProps = {
    confirmationStep: ConfirmationStep,
}

const MobileProgressBarInner = styled.div<MobileProgressBarInnerProps>`
    width: ${props => `${((props.confirmationStep + 1) / 3) * 100}`}%;
    height: 100%;
    background-color: black;
    transition: all 0.5s ease-in;
`

const MobileProgressBar = styled.div`
    height: 12px;
    background-color: transparent;
    border: 1px solid black;
`

const Content = styled.div`
    display: flex;
    ${styles.MediaQueries.Mobile} {
        flex-direction: column;
        flex: 1;
    }
`

const FailedModalContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`

const FailedModalBody = styled.div`
    flex: 1;
    font-family: ${styles.Font.Family.MonumentGrotesk};
`

const Button = styled(motion.button)`
    background-color: ${styles.Color.TaekusPurple};
    color: ${styles.Color.White};
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px;
    padding: 0 40px;
    border: 1px solid ${styles.Color.TaekusPurple};
    user-select: none;
    &:not(&:last-child) {
        margin-right: 10px;
    }
    ${styles.Animation.transitionStyles}
    ${styles.MediaQueries.Desktop} {
        min-width: 200px;
        &:hover {
            text-decoration: none;
            background-color: ${styles.Color.White};
            color: ${styles.Color.TaekusPurple};
        }
    }
    ${styles.MediaQueries.Mobile} {
        flex: 1;
        margin: 10px;
    }
`

const SuccessContainer = styled.div`
    display: flex;
    align-items: center;
    height: min-content;
`

const CheckmarkContainer = styled.div`
    width: ${styles.Spacing.S};
    height: ${styles.Spacing.S};
    margin-right: ${styles.Spacing.S};
    display: flex;
    align-items: center;
`

const StyledNavLink = styled(NavLink)`
    color: white;
    width: 200px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: ${styles.Spacing.M} ${styles.Spacing.XS} 0;
    background-color: ${styles.Color.TaekusPurple};
    :hover {
        color: white;
        text-decoration: none;
    }
`

const SubText = styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    margin-top: 10px;
    line-height: 138%;
    letter-spacing: 0.02em;
    color: #0E0E0E;
    margin: 0;
`

const MobileProgressBarLabel = styled.div`
    color: #0E0E0E;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 124%; /* 17.36px */
    letter-spacing: 0.14px;
    margin-bottom: 5px;
`

const MobileProgressBarContainer = styled.div`
    padding: 20px 15px;
    width: 100%;
    // background-color: #FCFBF6;
`

const ProgressBarContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: ${styles.Spacing.M} 0;
    ${styles.MediaQueries.Mobile} {
        width: 100%;
    }
    ${styles.MediaQueries.Desktop} {
        width: 90%;
    }
`

type StepProps = {
    active?: boolean;
    completed?: boolean;
}

const StepLabel = styled.div<StepProps>`
    color: ${props => props.active ? '#0E0E0E' : (props.completed ? styles.Color.TaekusPurple : '#BBB')};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 14px;
    font-style: normal;
    font-weight: ${props => props.active ? '600' : '400'};
    line-height: 124%; /* 17.36px */
    letter-spacing: 0.16px;
    background-color: transparent !important;
    white-space: nowrap;
    ${styles.Animation.transitionStyles}
`

const StepBar = styled.div<StepProps>`
    width: 100%;
    background-color: ${props => props.active ? '#0E0E0E' : (props.completed ? styles.Color.TaekusPurple : '#BBB')};
    height: 12px;
    ${styles.Animation.transitionStyles}
`

const StepContainer = styled.div<StepProps>`
    display: flex;
    flex-direction: column;
    align-items: start;
    flex: 1;
    width: 100%;
    position: relative;
    ${styles.Animation.transitionStyles}
    ${props => props.completed && `
        cursor: pointer;
        &:hover {
            * {
                color: #0E0E0E !important;
                background-color: #0E0E0E;
            }
        }
    `}
    &:not(&:first-child) {
        padding-left: 2px;
    }
    &:not(&:last-child) {
        padding-right: 2px;
    }
`

const StepContentContainer = styled(motion.div)`
    overflow: hidden;
    ${styles.MediaQueries.Mobile} {
        width: 100%;
        flex: 1;
        display: flex;
    }
    ${styles.MediaQueries.Desktop} {
        width: 90%;
        margin-bottom: 160px;
    }
`

const PageContentContainer = styled.div`
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    ${styles.Animation.transitionStyles}
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    ${styles.Scrollbar.defaultScrollbarStyles}
`

export default FlightBooking
