import React, { useEffect, useRef, useState } from "react"

import moment from "moment";

import { styled } from "styled-components"

import { formatPhoneNumber } from "utils/utils";

import ArrowButton, { ArrowDirection } from "components/signup/components/ArrowButton";
import Button from "components/signup/components/Button";
import Input from "components/signup/components/Input";
import StepContainer, { getStepContainerMotionProps } from "components/signup/StepContainer";
import { ProductType, Application, StepDirection, ProductCategory } from "components/signup/types";

import styles from "styles/styles";
import { useDispatch, useSelector } from "react-redux";
import { createLead, fetchLead, updateStep } from "components/signup/signupSlice";
import { useIsFirstRender } from "hooks/useIsFirstRender";
import { AnimatePresence, motion } from "framer-motion";
import { fadeInOutMotionProps } from "styles/motionConstants";
import { SignupStep } from "../constants";
import { FetchLeadError } from "../errors";

type VerifyPhoneProps = {
}

const VerifyPhone = (props: VerifyPhoneProps) => {
    const dispatch = useDispatch()
    const inputRef = useRef(null)
    const isFirstRender = useIsFirstRender()

    const user = useSelector((state: any) => state.currentUser)
    const leadExists = useSelector((state: any) => state.signup.leadExists)
    const userExists = useSelector((state: any) => state.signup.userExists)
    const leadUuid = useSelector((state: any) => state.signup.leadUuid)
    const application = useSelector((state: any) => state.signup.application)
    const isLoading = useSelector((state: any) => state.signup.isLoading)
    const products = useSelector((state: any) => state.signup.products)
    const isMobile = useSelector((state: any) => state.global.isMobile)
    const fetchLeadError = useSelector((state: any) => state.signup.errors.fetchLead)
    const stepDirection = useSelector((state: any) => state.signup.direction)

    const [inviteCode, setInviteCode] = useState('')
    const [codeExpirationTime, setCodeExpirationtime] = useState(moment().add(30, 'seconds'))
    const [timeLeft, setTimeLeft] = useState(codeExpirationTime.diff(moment(), 'seconds'))

    const selectedProduct = products?.find((product: any) => product.productCode === application.productCode)

    const getErrorMessage = () => {
        switch (fetchLeadError) {
            case FetchLeadError.InvalidToken:
                return 'Your code is invalid.'
            case FetchLeadError.Throttled:
                return 'Too many attempts, try again later.'
            default: 
                return 'Oops, something went wrong.'
        }
    }

    const errorMessage = (application.isPhoneVerified === false && fetchLeadError) ? getErrorMessage() : undefined

    const isInviteCodeValid = inviteCode !== ''
    const isButtonDisabled = !isInviteCodeValid

    const handleBack = () => {
        dispatch(updateStep({
            step: SignupStep.FindInfo,
            direction: StepDirection.Left,
        }))
    }

    const handleSubmit = (ev: React.FormEvent) => {
        ev.preventDefault();
        dispatch(fetchLead({
            leadUuid,
            phone: application.phoneNumber,
            token: inviteCode,
            user,
        }))
    }

    const formatSecondsToString = () => {
        const minutes = Math.floor(timeLeft / 60)
        const minuteLabel =  minutes > 0 ? `minute${minutes > 1 ? 's' : ''}` : ''
        const seconds = Math.floor(timeLeft % 60)
        const secondsLabel = seconds > 0 ? (seconds > 1 ? 'seconds' : 'second') : ''
        return `${minutes || ''} ${minuteLabel} ${seconds || ''} ${secondsLabel}`
    }

    const handleInputChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        if (!isNaN(Number(ev.target.value)) && ev.target.value.length <= 6) {
            setInviteCode(ev.target.value)
        }
    }

    const resendCode = () => { 
        setCodeExpirationtime(moment().add(30, 'seconds'))
        dispatch(createLead({
            inviteCode: application.inviteCode,
            productCode: application.productCode,
            dateOfBirth: application.birthDate,
            phoneNumber: application.phoneNumber,
            isMobile
        }))
    }

    const validateBasicInfo = () => {
        const isFirstNameValid = application.firstName
        const isLastNameValid = application.lastName
        const isSSNValid = application.ssn || application.ssnLastFour
        const isAddressValid = application.addressStreet && application.addressCity && application.addressState && application.addressZip
        return isFirstNameValid && isLastNameValid && isSSNValid && isAddressValid
    }

    useEffect(() => {
        (inputRef.current as any)?.focus()
    }, [])

    useEffect(() => {
        const interval = setInterval(() => setTimeLeft(codeExpirationTime.diff(moment(), 'seconds')), 1000);

        return () => {
            clearInterval(interval)
            setTimeLeft(30)
        };
    }, [codeExpirationTime])

    useEffect(() => {
        if (!isFirstRender && !isLoading && application.isPhoneVerified) {
            const isPersonalDebitApp = selectedProduct.productType === ProductType.Debit && selectedProduct.productCategory === ProductCategory.Personal
            const hasBasicInfo = validateBasicInfo()
            const nextStep = (leadExists && hasBasicInfo) ? SignupStep.InitialReview : SignupStep.UserDetails
            const nextUserStep = isPersonalDebitApp ? SignupStep.FinalReview : SignupStep.InitialReview

            dispatch(updateStep({
                step: userExists ? nextUserStep : nextStep,
                direction: StepDirection.Right
            }))
        }
    }, [isLoading]) // eslint-disable-line

    return <StepContainer
        {...getStepContainerMotionProps(stepDirection)}
        key='VerifyPhone'
    >
        <form className="d-flex flex-column align-items-center" onSubmit={handleSubmit}>
            <Title>Verify your phone number</Title>
            <Text>Enter the code we sent to {formatPhoneNumber(application.phoneNumber?.slice(2))} via SMS so we can make sure everything’s working smoothly.</Text>
            <Input errorMessage={errorMessage} value={inviteCode} onChange={handleInputChange} parentRef={inputRef} label="One-Time Authentication Code"/>
            <AnimatePresence mode='wait'>
                {timeLeft > 0 ? <CodeTimer
                    {...fadeInOutMotionProps}
                    key={codeExpirationTime.toString()}
                >
                    Your code expires in: <strong style={{fontWeight: 700}}>{formatSecondsToString()}</strong>.
                </CodeTimer> : <CodeTimer
                    {...fadeInOutMotionProps}
                    key='expired'
                >
                    Your code has expired.
                </CodeTimer>}
            </AnimatePresence>
            <ResendCodeLink onClick={resendCode}>{timeLeft > 0 ? "Didn’t receive a code?" : 'Request another?'}</ResendCodeLink>
            <div style={{marginTop: '32px', display: 'flex'}}>
                <div style={{marginRight: '16px'}}>
                    <ArrowButton onClick={handleBack} direction={ArrowDirection.Left}/>
                </div>
                <Button isLoading={isLoading} disabled={isButtonDisabled} type="submit">Next</Button>
            </div>
        </form>
    </StepContainer>
}

const ResendCodeLink = styled.div`
    color: ${styles.Color.TaekusPurple};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 138%; /* 22.08px */
    letter-spacing: 0.32px;
    cursor: pointer;
    margin-top: 8px;
    ${styles.MediaQueries.Mobile} {
        font-size: 14px;
    }
`

const CodeTimer = styled(motion.div)`
    color: ${styles.Color.TaekusGrey2};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%; /* 22.08px */
    letter-spacing: 0.32px;
    margin-top: 32px;
    ${styles.MediaQueries.Mobile} {
        font-size: 14px;
        max-width: 180px;
    }
`

const Text = styled.div`
    color: ${styles.Color.TaekusGrey2};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%; /* 22.08px */
    letter-spacing: 0.32px;
    margin-bottom: 16px;
    ${styles.MediaQueries.Mobile} {
        font-size: 14px;
    }
`

const Title = styled.div`
    color: ${styles.Color.TaekusGrey1};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 49.6px */
    letter-spacing: 0.4px;
    margin-bottom: 32px;
    ${styles.MediaQueries.Mobile} {
        font-size: 24px;
    }
`

export default VerifyPhone