import React, { useEffect, useRef, useState } from "react"
import { styled } from "styled-components"
import styles from "styles/styles";
import { AnimatePresence, motion } from "framer-motion";
import Button from "../components/Button";
import StepContainer, { getStepContainerMotionProps } from "../StepContainer";
import { ProductCategory, ProductType, StepDirection } from "../types";
import { ApiResponse, IpInfo, IpregistryClient } from "@ipregistry/client";
import { useDispatch, useSelector } from "react-redux";
import { updateApplication, updateIsVPNorIntl, updateStep } from "../signupSlice";
import { SignupStep } from "../constants";
import { fadeInOutMotionProps } from "styles/motionConstants";
import BackgroundImage from 'assets/global/img/adjustedBG.png'
import messages from "../messages";

enum FilterType {
    All='ALL',
    Credit='CREDIT',
    Debit='PREPAID'
}

type ChooseProductProps = {
}

const ChooseProduct = (props: ChooseProductProps) => {
    const imgRef = useRef(null)
    
    const dispatch = useDispatch()

    const user = useSelector((state: any) => state.currentUser)
    const isVPNOrIntl = useSelector((state: any) => state.signup.isVPNOrIntl)
    const application = useSelector((state: any) => state.signup.application)
    const products = useSelector((state: any) => state.signup.products)
    const stepDirection = useSelector((state: any) => state.signup.direction)

    const [isImageLoaded, setIsImageLoaded] = useState(false)
    const [filter, setFilter] = useState(FilterType.All)

    const selectedProduct = products?.find((product: any) => product.productCode === application.productCode)
    const isBusinessDebit = selectedProduct?.productType === ProductType.Debit && selectedProduct?.productType === ProductType.Debit
    const preselectTitle = selectedProduct?.productType === ProductType.Credit ? `See if you’re pre-approved for\nthe ${selectedProduct?.displayName} Card` : `Apply for a ${selectedProduct?.displayName} Account`
    const disclaimerMessage = selectedProduct?.productType === ProductType.Credit ? `Your credit score won’t be affected by the pre-approval process. \nAll Taekus debit cards are issued by Stearns Bank, NA.` : `All Taekus debit cards are issued by Stearns Bank, NA.`

    const filterProducts = (product: any) => filter === FilterType.All || product.productType === filter
    const sortCreditToDebit = (a: any,b: any) => a.productType === ProductType.Credit && a.productType !== b.productType ? -1 : 1
    const sortConsumerToBiz = (a: any,b: any) => a.productCategory === ProductCategory.Personal && a.productCategory !== b.productCategory ? -1 : 1

    const getIPInfo = async (ip: string) => {
        const client: IpregistryClient = new IpregistryClient((window as any).env.REACT_APP_IPREGISTRY_API_KEY)

        try {
            // Get location, threat data and more
            const response: ApiResponse<IpInfo> = await client.lookupIp(ip)
            dispatch(updateIsVPNorIntl(response.data.security.is_vpn || response.data.currency.code !== 'US'))
        } catch (error) {
            // If endpoint fails for any reason (timeout, no more IP check tokens, etc) push to VPN detection
            dispatch(updateIsVPNorIntl(false))
        }
    }

    const handlePreselectClick = () => {
        if (!user.isFetching) {
            dispatch(updateApplication({
                productCode: selectedProduct?.productCode,
                birthDate: user.userLoaded ? user.currentUser.dateOfBirth : application.birthDate,
                phoneNumber: user.userLoaded ? user.currentUser.phone : application.phoneNumber,
            }))
            dispatch(updateStep({
                step: isVPNOrIntl ? SignupStep.VPNDetected : (user.userLoaded ? SignupStep.LoadingProve : SignupStep.FindInfo),
                direction: StepDirection.Right
            }))
        }
    }

    const loadBackgroundImage = () => {
        const bgImage = new Image();

        bgImage.onload = () => {
            if (imgRef.current) {
                (imgRef?.current as any).style.backgroundImage = `url(${bgImage.src})`;
            }
            setIsImageLoaded(true)
        }
        bgImage.onerror = () => {
            setIsImageLoaded(true)
        }

        bgImage.src = BackgroundImage;
    }

    // On component mount
    useEffect(() => {
        loadBackgroundImage()
    }, []) 

    useEffect(() => {
        // Uncomment to enable VPN/location check
        if (false && isVPNOrIntl === undefined) {
            fetch("https://api.ipify.org?format=json")
                .then(response => response.json())
                .then(data => {
                    // Display the IP address on the screen
                    getIPInfo(data.ip)
                })
                .catch(error => {
                    // If endpoint fails for any reason push to VPN detection
                    dispatch(updateIsVPNorIntl(false))
                });
        }
    }, [dispatch]) // eslint-disable-line

    return (selectedProduct ? <FullscreenContainer
        {...fadeInOutMotionProps}
        key='preselected'
        ref={imgRef}
        isImageLoaded={isImageLoaded}
    >
        <PreselectContent>
            <PreselectTitle>{preselectTitle}</PreselectTitle>
            {isBusinessDebit && <PreselectSubtitle>Make managing business expenses easier when you open a FDIC-insured Taekus Business Checking Account.</PreselectSubtitle>}
            {selectedProduct.productType === ProductType.Debit ? <Grid>
                <GridItem>
                    <GridTitle>FDIC-insured Checking Accounts</GridTitle>
                    <GridSubtitle>Keep your money safe with FDIC insurance for your account account.</GridSubtitle>
                </GridItem>
                <GridItem>
                    <GridTitle>Rewards-Earning Debit Card</GridTitle>
                    <GridSubtitle>Compound your points-earning by adding your  Taekus debit card to your wallet</GridSubtitle>
                </GridItem>
                <GridItem>
                    <GridTitle>Complimentary Transfers/Checks</GridTitle>
                    <GridSubtitle>Send and receive money transfers without worrying about fees.</GridSubtitle>
                </GridItem>
                <GridItem>
                    <GridTitle>No Effect on Your Credit Score</GridTitle>
                    <GridSubtitle>Opening a Taekus checking account doesn’t require a credit check.</GridSubtitle>
                </GridItem>
            </Grid> : <div style={{display: 'flex', width: '100%', marginTop: '40px'}}>
                <MultiplierContainer>
                    <Mult>5x</Mult>
                    <MultLabel>Travel</MultLabel>
                </MultiplierContainer>
                <MultiplierContainer>
                    <Mult>3x</Mult>
                    <MultLabel>on Restaurants</MultLabel>
                </MultiplierContainer>
                <MultiplierContainer>
                    <Mult>3x</Mult>
                    <MultLabel>Groceries</MultLabel>
                </MultiplierContainer>
            </div>}
            <div style={{margin: '40px 0 24px'}}>
                <Button onClick={handlePreselectClick}>Continue to Application</Button>
            </div>
            <Disclaimer>{disclaimerMessage}</Disclaimer>
        </PreselectContent>
    </FullscreenContainer> : <StepContainer
        {...getStepContainerMotionProps(stepDirection)}
        key='ChooseProduct'
    >
        <Scrollable>
            <Title>Choose a Taekus product</Title>
            <Text>Your credit score won’t be affected the pre-approval process.</Text>
            <FilterContainer>
                <Filter 
                    id={FilterType.All}
                    selected={filter === FilterType.All}
                    onClick={(ev) => { setFilter((ev.target as HTMLDivElement).id as FilterType) }}
                >
                    All
                    <div style={{fontWeight: 500, opacity: 0, pointerEvents: 'none'}}>All</div>
                </Filter>
                <Filter/>
                <Filter
                    id={FilterType.Credit}
                    selected={filter === FilterType.Credit}
                    onClick={(ev) => { setFilter((ev.target as HTMLDivElement).id as FilterType) }}
                >
                    Credit Cards
                    <div style={{fontWeight: 500, opacity: 0, pointerEvents: 'none'}}>Credit Cards</div>
                </Filter>
                <Filter/>
                <Filter 
                    id={FilterType.Debit}
                    selected={filter === FilterType.Debit}
                    onClick={(ev) => { setFilter((ev.target as HTMLDivElement).id as FilterType) }}
                >
                    Debit Cards
                    <div style={{fontWeight: 500, opacity: 0, pointerEvents: 'none'}}>Debit Cards</div>
                </Filter>
            </FilterContainer>
            <ProductContainer>
                <AnimatePresence mode='wait'>
                    {[...products]?.filter(filterProducts)
                        ?.sort(sortCreditToDebit)
                        ?.sort(sortConsumerToBiz)
                        .map((product: any) => <Product
                            showMultipliers
                            key={product.displayName}
                            product={product}
                            limited
                        />)
                    }
                </AnimatePresence>
            </ProductContainer>
        </Scrollable>
    </StepContainer>)
}

type ProductProps = {
    limited?: boolean,
    showMultipliers?: boolean,
    product: any,
}

const Product = (props: ProductProps) => {
    const dispatch = useDispatch();

    const user = useSelector((state: any) => state.currentUser)
    const isVPNOrIntl = useSelector((state: any) => state.signup.isVPNOrIntl)
    const application = useSelector((state: any) => state.signup.application)

    const handleSelect = (ev: React.FormEvent) => {
        ev.preventDefault();

        const { product } = props;
        if (!user.isFetching) {
            dispatch(updateApplication({
                productCode: product.productCode,
                birthDate: user.userLoaded ? user.currentUser.dateOfBirth : application.birthDate,
                phoneNumber: user.userLoaded ? user.currentUser.phone : application.phoneNumber,
            }))
            dispatch(updateStep({
                step: isVPNOrIntl ? SignupStep.VPNDetected : (user.userLoaded ? SignupStep.LoadingProve : SignupStep.FindInfo),
                direction: StepDirection.Right
            }))
        }
    }

    const { limited, showMultipliers, product } = props;
    const productType = product.productType

    return <Container key={product.displayName} onClick={handleSelect}>
        <Card productType={productType}/>
        <LimitedOffer limited={limited}>Limited Offer</LimitedOffer>
        <ProductTitle>{product.displayName}</ProductTitle>
        <div style={{flex: 1, width: '100%'}}>
            {showMultipliers ? <div style={{display: 'flex', flexDirection: 'column', marginTop: '8px', padding: '0 24px'}}>
                <ProductText>$250 Annual Fee</ProductText>
                <div style={{marginTop: '16px', display: 'flex', width: '100%', height: '88px', padding: '16px 0'}}>
                    <div style={{flex: 1}}>
                        <Multiplier $productType={productType}>5x</Multiplier>
                        <MultiplierLabel>Travels</MultiplierLabel>
                    </div>
                    <div style={{flex: 1}}>
                        <Multiplier $productType={productType}>4x</Multiplier>
                        <MultiplierLabel>Restaraunts</MultiplierLabel>
                    </div>
                    <div style={{flex: 1}}>
                        <Multiplier $productType={productType}>3x</Multiplier>
                        <MultiplierLabel>Groceries</MultiplierLabel>
                    </div>
                </div>
            </div> : <div style={{display: 'flex', flexDirection: 'column', marginTop: '12px'}}>
                <ProductText>
                    <div style={{marginBottom: '4px'}}>Bank Accounts</div>
                    <div style={{marginBottom: '4px'}}>Free wires</div>
                    <div style={{marginBottom: '4px'}}>Transparent pricing</div>
                    <div style={{marginBottom: '4px'}}>No Monthly Fee</div>
                    <div style={{marginBottom: '4px'}}>No Insufficient Funds Fee</div>
                    <div style={{marginBottom: '4px'}}>Fee-Free Overdraft Protection</div>
                    <div style={{marginBottom: '4px'}}>Access 60,000+ Fee-Free ATMs</div>
                    <div>Send Money with Zelle®</div>
                </ProductText>
            </div>}
        </div>
        <Button secondary onClick={(ev) => { ev.stopPropagation() }}>Learn More</Button>
    </Container>   
}

const Disclaimer = styled.div`
    white-space: pre-line;
    color: ${styles.Color.TaekusGrey3};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
    letter-spacing: 0.5px;
`

const MultLabel = styled.div`
    color: ${styles.Color.TaekusGrey5};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 133.333% */
`

const Mult = styled.div`
    color: ${styles.Color.White};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 60px; /* 125% */
`

const MultiplierContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.20);
    flex: 1;
    &:not(&:first-child) {
        margin-left: 8px;
    }
    &:not(&:last-child) {
        margin-right: 8px;
    }
`

const GridTitle = styled.div`
    color: ${styles.Color.White};
    text-align: center;
    /* Title - Small */
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 133.333% */
`

const GridSubtitle = styled.div`
    color: ${styles.Color.TaekusGrey3};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
`

const GridItem = styled.div`
    flex-basis: calc(50% - 8px);
    padding: 16px 0;
    &:nth-child(-n+2) {
        border-bottom: 1px solid rgba(255, 255, 255, 0.20);
    }
`

const Grid = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 24px;
`

const PreselectTitle = styled.div`
    white-space: pre-line;
    color: ${styles.Color.White};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 39.68px */
    letter-spacing: 0.32px;
`

const PreselectSubtitle = styled.div`
    margin-top: 16px;
    margin-bottom: 24px;
    color: ${styles.Color.TaekusGrey4};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%; /* 22.08px */
    letter-spacing: 0.32px;
`

const PreselectContent = styled(motion.div)`
    min-width: 100px;
    width: 50%;
    min-height: 100px;
    margin: 0 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const FullscreenContainer = styled(motion.div)<{ isImageLoaded: boolean }>`
    width: 100dvw;
    height: 100dvh;
    display: flex;
    justify-content: end;
    background-size: cover;
    background-position: center;
    background-color: #4E3259;
    -moz-transition: opacity 1.2s ease-in;
    -o-transition: opacity 1.2s ease-in;
    -webkit-transition: opacity 1.2s ease-in;
    transition: opacity 1.2s ease-in;
    opacity: ${props => props.isImageLoaded ? 1 : 0};
    color: white;
    font-family: ${styles.Font.Family.MonumentGrotesk};
`

const FilterContainer = styled.div`
    display: flex;
    margin-bottom: 64px;
`

const ProductContainer = styled.div`
    display: flex;
    max-width: calc(100dvw - 40px);
    margin: 0 20px;
    padding-bottom: 10px;
    overflow-x: auto;
    overflow-y: hidden;
    ${styles.Scrollbar.defaultScrollbarStyles}
    ${styles.MediaQueries.Mobile} {
        flex-wrap: wrap;
    }
`

const Scrollable = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    ${styles.MediaQueries.Mobile} {
        overflow-x: hidden;
        overflow-y: scroll;
    }
    ${styles.Scrollbar.TaekusGrey}
`

const MultiplierLabel = styled.div`
    color: ${styles.Color.TaekusGrey3};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 12.4px */
    letter-spacing: 0.1px;
`

type MultiplierProps = {
    $productType: ProductType
}

const Multiplier = styled.div<MultiplierProps>`
    color: ${props => props.$productType === ProductType.Credit ? styles.Color.TaekusPurple : styles.Color.TaekusBlue};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 124%; /* 39.68px */
    letter-spacing: 0.32px;
`

const ProductText = styled.div`
    color: ${styles.Color.TaekusGrey3};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 14.88px */
    letter-spacing: 0.12px;
`

const ProductTitle = styled.div`
    color: ${styles.Color.TaekusBlack};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 138%; /* 22.08px */
    letter-spacing: 0.32px;
`

type LimitedOfferProps = {
    limited?: boolean,
}

const LimitedOffer = styled.div<LimitedOfferProps>`
    ${props => props.limited ? '' : 'visibility: hidden;'}
    background-color: ${styles.Color.TaekusBlack};
    color: ${styles.Color.TaekusGrey5};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 10px;
    padding: 4px 8px;
    font-style: normal;
    font-weight: 400;
    line-height: 10px; /* 100% */
    margin-bottom: 8px;
`

type CardProps = {
    productType: ProductType,
}

const Card = styled.div<CardProps>`
    width: 132.286px;
    height: 80px;
    background-color: ${props => props.productType === ProductType.Credit ? styles.Color.TaekusPurple : styles.Color.TaekusBlue};
    border-radius: 4px;
    margin-bottom: 24px;
`

const Container = styled(motion.div)`
    height: 464px;
    min-width: 308px;
    margin: 0 8px;
    ${styles.MediaQueries.Mobile} {
        width: calc(50dvw - 2px);
        height: 464px;
        margin: 0;
    }
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    border: 1px solid transparent;
    padding: 40px 0;
    
    &:hover {
        border: 1px solid ${styles.Color.TaekusPurple};
        background-color: ${styles.Color.White};
    }
    ${styles.Animation.transitionStyles}
`

type FilterProps = {
    selected?: boolean,
}

const Filter = styled.div<FilterProps>`
    cursor: pointer;
    color: ${props => props.selected ? `${styles.Color.TaekusPurple} !important` : styles.Color.TaekusGrey3};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: ${props => props.selected ? 500 : 400};
    line-height: 138%; /* 22.08px */
    letter-spacing: 0.32px;
    height: 30px;
    min-width: 24px;
    padding: 0 4px;
    border-bottom: 2px solid ${props => props.selected ? `${styles.Color.TaekusPurple} !important` : styles.Color.TaekusGrey4};
    ${props => props.selected !== undefined && `&:hover {
        color: rgba(124, 61, 118, 0.5);
        border-bottom: 2px solid rgba(124, 61, 118, 0.5);
    }`}
    ${styles.MediaQueries.Mobile} {
        font-size: 14px;   
    }
    ${styles.Animation.transitionStyles}
`

const Title = styled.div`
    color: ${styles.Color.TaekusBlack};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 39.68px */
    letter-spacing: 0.32px;
    margin-bottom: 16px;
    ${styles.MediaQueries.Mobile} {
        font-size: 24px;   
    }
`

const Text = styled.div`
    color: ${styles.Color.TaekusGrey2};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%; /* 22.08px */
    letter-spacing: 0.32px;
    margin-bottom: 40px;
    ${styles.MediaQueries.Mobile} {
        font-size: 14px;   
    }
`

export default ChooseProduct