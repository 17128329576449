import React from "react"

import { styled } from "styled-components"

import StepContainer, { getStepContainerMotionProps } from "components/signup/StepContainer";
import { StepDirection } from "components/signup/types";

import styles from "styles/styles";
import { useSelector } from "react-redux";

type VPNProps = {
}

const VPN = (props: VPNProps) => {
    const stepDirection = useSelector((state: any) => state.signup.direction)

    return <StepContainer
        {...getStepContainerMotionProps(stepDirection)}
        key='VPN'
    >
        <div className="d-flex flex-column align-items-center">
            <Title>Are you on a VPN?</Title>
            <Text>We don’t support application submissions on a VPN, so please disable your VPN before continuing. Thank you!</Text>
        </div>
    </StepContainer>
}

const Text = styled.div`
    color: ${styles.Color.TaekusGrey2};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%; /* 22.08px */
    letter-spacing: 0.32px;
    margin-bottom: 16px;
`

const Title = styled.div`
    color: ${styles.Color.TaekusGrey1};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 49.6px */
    letter-spacing: 0.4px;
    margin-bottom: 32px;
`

export default VPN