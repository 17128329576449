import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux";

import { styled } from "styled-components"

import ArrowButton, { ArrowDirection } from "components/signup/components/ArrowButton";
import Button from "components/signup/components/Button";
import Select from "components/signup/components/Select";
import StepContainer, { getStepContainerMotionProps } from "components/signup/StepContainer";
import { Application, StepDirection } from "components/signup/types";

import styles from "styles/styles";
import { updateApplication, updateLead, updateStep } from "../signupSlice";
import { SignupStep, taxCategories } from "../constants";

type CaptureBizCategoryProps = {
}

const CaptureBizCategory = (props: CaptureBizCategoryProps) => {
    const dispatch = useDispatch()
    
    const application = useSelector((state: any) => state.signup.application)
    const leadUuid = useSelector((state: any) => state.signup.leadUuid)
    const stepDirection = useSelector((state: any) => state.signup.direction)

    const industryOptions = taxCategories.map(category => ({ value: category, label: category }))

    const [isInputDisabled, setIsInputDisabled] = useState(false)
    const [selectedCode, setSelectedCode] = useState(application.naicsCategory ? industryOptions.find(option => option.value === application.naicsCategory) : undefined)

    const isButtonDisabled = !selectedCode || isInputDisabled

    const handleBack = (ev: any) => {
        ev.stopPropagation();
        dispatch(updateStep({
            step: SignupStep.BizEIN,
            direction: StepDirection.Left
        }))
    }

    const handleSubmit = (ev: React.FormEvent) => {
        ev.preventDefault();
        setIsInputDisabled(true)
        const applicationChanges = { naicsCategory: selectedCode?.value }

        dispatch(updateApplication(applicationChanges))
        dispatch(updateLead({ leadUuid, application: applicationChanges }))
        dispatch(updateStep({ step: SignupStep.BizDetails, direction: StepDirection.Right }))
    }

    const handleSelect = (option: any) => {
        setSelectedCode(option)
    }

    return <StepContainer
        {...getStepContainerMotionProps(stepDirection)}
        key='BusinessIndustry'
    >
        <form className="d-flex flex-column align-items-center" onSubmit={handleSubmit}>
            <Title>How would you categorize your business?</Title>
            <Text>Please choose the most accurate industry.</Text>
            <Select options={industryOptions} selected={selectedCode} onSelect={handleSelect} label='Industry type'/>
            <ButtonContainer>
                <BackButtonContainer>
                    <ArrowButton onClick={handleBack} direction={ArrowDirection.Left}/>
                </BackButtonContainer>
                <Button disabled={isButtonDisabled} type="submit">Next</Button>
            </ButtonContainer>
        </form>
    </StepContainer>
}

const BackButtonContainer = styled.div`
    margin-right: 16px;
`

const ButtonContainer = styled.div`
    margin-top: 32px;
    display: flex;
`

const Text = styled.div`
    color: ${styles.Color.TaekusGrey2};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%; /* 22.08px */
    letter-spacing: 0.32px;
    margin-bottom: 32px;
    ${styles.MediaQueries.Mobile} {
        font-size: 14px;
    }
`

const Title = styled.div`
    color: ${styles.Color.TaekusBlack};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 49.6px */
    letter-spacing: 0.4px;
    margin-bottom: 16px;
    ${styles.MediaQueries.Mobile} {
        font-size: 24px;
    }
`

export default CaptureBizCategory