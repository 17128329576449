import React from "react"

import { styled } from "styled-components"

import styles from "styles/styles"

import { ReactComponent as Checkmark } from "assets/svg/Checkmarks/RoundedCheckmark.svg";

type SelectableProps = {
    label: string,
    onClick: (ev: React.MouseEvent<HTMLButtonElement>) => void,
    selected: boolean,
    id?: string,
}

const Selectable = (props: SelectableProps) => {
    return <Container 
        id={props.id} 
        onClick={props.onClick} 
        selected={props.selected} 
        type='button'
    >
        <Icon selected={props.selected}>
            <StyledCheckmark/>
        </Icon>
        {props.label}
    </Container>
}

const StyledCheckmark = styled(Checkmark)`
    stroke: ${styles.Color.White};
    width: 10px;
    height: auto;
`

type SelectedProps = {
    selected: boolean,
}

const Icon = styled.div<SelectedProps>`
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid ${props => props.selected ? styles.Color.TaekusPurple : styles.Color.TaekusGrey4};
    margin-right: 12px;
    background-color: ${props => props.selected ? styles.Color.TaekusPurple : 'transparent'};
    pointer-events: none;
    ${styles.Animation.transitionStyles}
`

const Container = styled.button<SelectedProps>`
    // min-width: 308px;
    width: 308px;
    height: 52px;
    padding: 0 16px;
    display: flex;
    justify-content: start;
    align-items: center;
    color: ${props => props.selected ? styles.Color.TaekusPurple : styles.Color.TaekusGrey2};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: ${props => props.selected ? 500 : 400};
    line-height: 138%; /* 22.08px */
    letter-spacing: 0.32px;
    border-radius: 2px;
    border: 1px solid ${props => props.selected ? styles.Color.TaekusPurple : styles.Color.TaekusGrey4};
    background: ${props => props.selected ? 'linear-gradient(0deg, rgba(124, 61, 118, 0.05) 0%, rgba(124, 61, 118, 0.05) 100%), var(--Color-Taekus-White, #FFF)' : styles.Color.White};
    outline-offset: 1px;
    &:hover {
        box-shadow: 0px 0px 16px 0px rgba(124, 61, 118, 0.15);
    }
    &:focus {
        outline: 1px solid ${styles.Color.TaekusPurple};
    }
    -moz-transition: border .2s ease-in, color .2s ease-in, background .2s ease-in, box-shadow .2s ease-in;
    -o-transition: border .2s ease-in, color .2s ease-in, background .2s ease-in, box-shadow .2s ease-in;
    -webkit-transition: border .2s ease-in, color .2s ease-in, background .2s ease-in, box-shadow .2s ease-in;
    transition: border .2s ease-in, color .2s ease-in, background .2s ease-in, box-shadow .2s ease-in;
`

export default Selectable