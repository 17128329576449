import React, { useEffect, useRef } from "react"
import { AuthenticatorBuilder, DeviceRole, MobileAuthImplementation } from "@prove-identity/prove-auth"

import { styled } from "styled-components"

import StepContainer, { getStepContainerMotionProps } from "components/signup/StepContainer";
import TaekusLoadingIcon from "components/signup/components/TaekusLoadingIcon";
import { Application, StepDirection } from "components/signup/types";

import styles from "styles/styles";
import { useDispatch, useSelector } from "react-redux";
import { createLead, fetchLead, updateStep } from "../signupSlice";
import PhoneNumberInput from "@prove-identity/prove-auth/build/lib/proveauth/internal/phone-number-input";
import { SignupStep } from "../constants";


type LoadingInfoProps = {
}

const LoadingInfo = (props: LoadingInfoProps) => {
    const dispatch = useDispatch()

    const user = useSelector((state: any) => state.currentUser)
    const isMobile = useSelector((state: any) => state.global.isMobile)
    const leadUuid = useSelector((state: any) => state.signup.leadUuid)
    const application = useSelector((state: any) => state.signup.application)
    const prove = useSelector((state: any) => state.signup.prove)
    const proveAuthCode = useSelector((state: any) => state.signup.prove.authToken)
    const userExists = useSelector((state: any) => state.signup.userExists)
    const stepDirection = useSelector((state: any) => state.signup.direction)

    const inputRef = useRef(null)

    async function authenticate(isMobileWeb: boolean, authToken: string) {
        // Set up the authenticator for either mobile or desktop flow.
        let builder = new AuthenticatorBuilder();
      
        // Success callback
        const verify = (authId: string) => {
            dispatch(fetchLead({
                leadUuid,
                phone: application.phoneNumber,
                proveToken: proveAuthCode,
                user,
            }))
            return new Promise<void>((resolve, reject) => {
                setTimeout(() => {
                    // dispatch(updateProve({ rejected: true }))
                    resolve();
                }, 1000);
            });          
        }

        const instantLink = (phoneNumberNeeded: boolean, phoneValidationError: any) => {
            return new Promise<PhoneNumberInput | null>((resolve, reject) => {
              if (phoneNumberNeeded) {
                var val = prompt("Enter phone number:");
                let input = {
                  phoneNumber: val,
                };
                resolve(input as PhoneNumberInput);
              } else {
                resolve(null);
              }
            });
        }

        if (isMobileWeb) {
          // Set up Mobile Auth and OTP.
          builder = builder
            .withAuthFinishStep((input) => verify(input.authId))
            .withMobileAuthImplementation(MobileAuthImplementation.Fetch)
            // .withOtpFallback(otpStart, otpFinish);
        } else {
          // Set up Instant Link.
          builder = builder
            .withAuthFinishStep((input) => verify(input.authId))
            .withInstantLinkFallback(instantLink)
            .withRole(DeviceRole.Secondary);
        }
      
        const authenticator = builder.build();
      
        // Authenticate with the authToken.
        return authenticator.authenticate(authToken);
    } 

    useEffect(() => {
        (inputRef.current as any)?.focus()
    }, [])

    useEffect(() => {
        // Create lead with DOB, phone #, invite & product codes
        // This step should fail if the phone # matches an existing Taekus user
        dispatch(createLead({
            inviteCode: application.inviteCode,
            productCode: application.productCode,
            dateOfBirth: application.birthDate,
            phoneNumber: application.phoneNumber,
            isMobile
        }))
    }, []) // eslint-disable-line

    useEffect(() => {
        if (proveAuthCode) {
            // Start Prove authorization
            // todo: use Prove SDK and provide callback to get auth data from BE
            
            // Check if the end user is on a mobile or desktop browser.
            const authCheck = new AuthenticatorBuilder().build();
            let isMobile = authCheck.isMobileWeb()
            authenticate(isMobile, proveAuthCode)

        }
    }, [proveAuthCode]) // eslint-disable-line

    // This effect should fire when the proveData from the above BE callback is updated
    useEffect(() => {
        if (prove.invalidPhone) {
            // Invalid Phone #, push back to findInfo
            // props.onError()
            dispatch(updateStep({
                step: SignupStep.FindInfo,
                direction: StepDirection.Left
            }))
        } else if (prove.rejected) {
            // Prove rejected this user as a potential fraud source
            // Begin internal 2FA, and then push them to manual data entry
            dispatch(updateStep({
                step: (userExists && !user.userLoaded) ? SignupStep.UserDetected : SignupStep.VerifyPhone,
                direction: StepDirection.Right
            }))
        } else if (prove.prefillComplete) {
            // Prove pre-fills all necessary user data, push user to initial review
            dispatch(updateStep({
                step: SignupStep.InitialReview,
                direction: StepDirection.Right
            }))
        } else {
            // Prove didn't reject user, but does not have any or all data 
            // Push user to manual data entry
        }
    }, [prove]) // eslint-disable-line

    return <StepContainer
        {...getStepContainerMotionProps(stepDirection)}
        key='LoadingInfo'
    >
        <Form>
            <TaekusLoadingIcon/>
            <Title>Finding your information...</Title>
        </Form>
    </StepContainer>
}

const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    ${styles.MediaQueries.Mobile} {
        width: 100dvw;
        height: calc(100dvh - 156px);
    }
`

const Title = styled.div`
    color: ${styles.Color.TaekusGrey1};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 49.6px */
    letter-spacing: 0.4px;
    margin-top: 32px;
    ${styles.MediaQueries.Mobile} {
        font-size: 14px;   
    }
`

export default LoadingInfo