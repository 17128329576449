import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux";

import { styled } from "styled-components"

import { useIsFirstRender } from "hooks/useIsFirstRender";

import { StepDirection } from "components/signup/types";

import Button from "components/signup/components/Button";
import Input from "components/signup/components/Input";
import StepContainer, { getStepContainerMotionProps } from "components/signup/StepContainer";

import styles from "styles/styles";
import {  validateUsername } from "../signupSlice";

type UsernameProps = {
}

const Username = (props: UsernameProps) => {
    const dispatch = useDispatch()

    const isFirstRender = useIsFirstRender()
    const isLoading = useSelector((state: any) => state.signup.isLoading)
    const isUsernameUnique = useSelector((state: any) => state.signup.isUsernameValid)
    const stepDirection = useSelector((state: any) => state.signup.direction)

    const inputRef = useRef(null)

    const [username, setUsername] = useState('')
    const [isInputDisabled, setIsInputDisabled] = useState(false)

    const isUsernameValid = username.length >= 8
    const isButtonDisabled = !isUsernameValid || isInputDisabled
    const errorMessage = isUsernameUnique === false ? 'This username is unavailable.' : undefined

    const handleSubmit = (ev: React.FormEvent) => {
        ev.preventDefault();
        setIsInputDisabled(true)
        dispatch(validateUsername(username))
    }

    const handleInputChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        if (ev.target.value.length <= 28) {
            setUsername(ev.target.value)
        }
    }

    // Focus username input on mount
    useEffect(() => {
        (inputRef.current as any)?.focus()
    }, [])

    // When loading completes, save username to Redux and move to next step
    useEffect(() => {
        if (!isFirstRender && isUsernameUnique === false) {
            setIsInputDisabled(false)
        }
    }, [isUsernameUnique]) // eslint-disable-line

    return <StepContainer
        {...getStepContainerMotionProps(stepDirection)}
        key='Username'
    >
        <form className="d-flex flex-column align-items-center" onSubmit={handleSubmit}>
            <Title>Let’s set up your Taekus login.</Title>
            <Text>Choose a username for your Taekus portal login that isn’t your email.</Text>
            <Input 
                errorMessage={errorMessage} 
                onChange={handleInputChange} 
                value={username} 
                setValue={setUsername} 
                parentRef={inputRef} 
                label="Username"
            />
            <ButtonRow>
                <Button isLoading={isLoading} disabled={isButtonDisabled} type="submit">Next</Button>
            </ButtonRow>
        </form>
    </StepContainer>
}

const ButtonRow = styled.div`
    margin-top: 32px;
`

const Text = styled.div`
    color: ${styles.Color.TaekusGrey2};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%; /* 22.08px */
    letter-spacing: 0.32px;
    margin-bottom: 32px;
`

const Title = styled.div`
    color: ${styles.Color.TaekusGrey1};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 49.6px */
    letter-spacing: 0.4px;
    margin-bottom: 24px;
`

export default Username