import { createSlice } from '@reduxjs/toolkit'
import { defaultApplication, SignupStep } from './constants'
import { Application, StepDirection } from './types'
import API from 'services/api'
import { FetchLeadError } from './errors'

type SignupState = {
    application: Application,
    leadUuid: string | undefined,
    leadExists: boolean | undefined,
    userExists: boolean | undefined,
    offerUuid: string | undefined,
    isInviteCodeValid: boolean | undefined,
    isUsernameValid: boolean | undefined,
    isVPNOrIntl: boolean | undefined,
    patchFailed: boolean,
    leadFailed: boolean | undefined,
    isLoading: boolean,
    step: SignupStep,
    prevStep?: SignupStep,
    direction: StepDirection,
    errors: {
        fetchLead: FetchLeadError | undefined,
    }
    prove: {
        authToken: string | undefined,
        rejected: boolean | undefined,
        prefillComplete: boolean | undefined,
        invalidPhone: boolean,
    },
    offer: any | undefined,
    test: boolean | undefined,
    products: any[] | undefined,
}

const defaultSignupState: SignupState = {
    application: defaultApplication,
    leadUuid: undefined,
    leadExists: undefined,
    userExists: undefined,
    offerUuid: undefined,
    isInviteCodeValid: undefined,
    isUsernameValid: undefined,
    isVPNOrIntl: undefined,
    patchFailed: false,
    leadFailed: undefined,
    isLoading: false,
    step: SignupStep.InviteCode,
    direction: StepDirection.Right,
    errors: {
        fetchLead: undefined
    },
    prove: {
        authToken: undefined,
        rejected: undefined,
        prefillComplete: undefined,
        invalidPhone: false,
    },
    offer: undefined,
    test: undefined,
    products: undefined,
}

export const signupSlice = createSlice({
    name: 'signup',
    initialState: defaultSignupState,
    reducers: {
        updateStep: (state, action) => {
            state.step = action.payload.step !== undefined ? action.payload.step : state.step
            state.prevStep = action.payload.prevStep
            state.direction = action.payload.direction !== undefined ? action.payload.direction : state.direction
        },
        updateTest: (state) => {
            state.test = !state.test
        },
        updateIsLoading: (state, action) => {
            state.isLoading = action.payload
        },
        updateApplication: (state, action) => {
            let copy = {
                ...state.application,
                ...action.payload
            }
            state.application = copy
        },
        updateIsInviteCodeValid: (state, action) => {
            state.isInviteCodeValid = action.payload
        },
        updateIsVPNorIntl: (state, action) => {
            state.isVPNOrIntl = action.payload
        },
        updateProve: (state, action) => {
            let copy = {
                ...state.prove,
                ...action.payload
            }
            state.prove = copy
        },
        updateLeadUuid: (state, action) => {
            state.leadUuid = action.payload
        },


        // Username
        validateUsernameStart: (state, action) => {
            state.isLoading = true
            state.isUsernameValid = undefined
        },
        validateUsernameError: (state, action) => {
            state.isLoading = false
            state.isUsernameValid = false
        },
        validateUsernameComplete: (state, action) => {
            state.isLoading = false
            state.isUsernameValid = true
            state.step = SignupStep.CreatePassword
        },
        
        // Invite code
        validateInviteStart: (state, action) => {
            state.isLoading = true
            state.application.inviteCode = action.payload
            state.isInviteCodeValid = undefined
        },
        validateInviteComplete: (state, action) => {
            state.isInviteCodeValid = action.payload.valid
            state.products = action.payload.offerings
            state.application = { 
                ...state.application,
                productCode: action.payload.offerings.find((offering: any) => offering.uuid === action.payload.uuid).productCode
            }
            state.step = SignupStep.ChooseProduct
        },

        // Create Lead
        createLeadStart: (state) => {
            state.isLoading = true
            state.leadFailed = undefined
            state.application.isPhoneVerified = undefined
        },
        createLeadError: (state, action) => {
            state.leadFailed = true
            state.step = SignupStep.FindInfo
        },
        createLeadComplete: (state, action) => {
            state.leadFailed = false
            let proveCopy = {
                ...state.prove,
                authToken: action.payload.authToken,
                rejected: action.payload.authToken === null,
            }
            state.prove = proveCopy
            state.leadUuid = action.payload.uuid
            state.leadExists = action.payload.leadExists
            state.userExists = action.payload.userExists
        },

        // FetchProveInfo
        fetchLeadStart: (state) => {
            state.isLoading = true
            state.errors.fetchLead = undefined
        },
        fetchLeadError: (state, action) => {
            if(action.payload.errorCode === 1001) {
                state.prove.invalidPhone = true
                state.errors.fetchLead = FetchLeadError.InvalidPhone
            } else if (action.payload.nonFieldErrors.at(0)?.includes('Token Invalid')) {
                state.errors.fetchLead = FetchLeadError.InvalidToken
            } else if (action.payload.nonFieldErrors.at(0)?.includes('Too many attempts')) {
                state.errors.fetchLead = FetchLeadError.Throttled
            }
            state.application.isPhoneVerified = false
            state.isLoading = false
        },
        fetchLeadComplete: (state, action) => {
            state.application = {
                ...state.application,
                ...action.payload.data.lead,
                firstName: action.payload.data.lead?.firstName || (action.payload.user.userLoaded && action.payload.user.currentUser.firstName),
                lastName: action.payload.data.lead?.lastName || (action.payload.user.userLoaded && action.payload.user.currentUser.lastName),
                addressStreet: action.payload.data.lead?.addressStreet || (action.payload.user.userLoaded && action.payload.user.currentUser.primaryAddress.street),
                addressCity: action.payload.data.lead?.addressCity || (action.payload.user.userLoaded && action.payload.user.currentUser.primaryAddress.city),
                addressState: action.payload.data.lead?.addressState || (action.payload.user.userLoaded && action.payload.user.currentUser.primaryAddress.state),
                addressZip: action.payload.data.lead?.addressZip || (action.payload.user.userLoaded && action.payload.user.currentUser.primaryAddress.zip),
                isPhoneVerified: true,
            }
            state.prove.prefillComplete = true;
            state.isLoading = false
        },

        // Update lead
        updateLeadComplete: (state) => {
            state.patchFailed = false;
        },
        updateLeadError: (state) => {
            state.patchFailed = true;
        },

        // Submit prequal
        submitPrequalComplete: (state, action) => {
            state.offer = action.payload
        },
        submitPrequalError: (state) =>{
            state.offer = false;
        },

        // Onboard user
        onboardUserComplete: (state, action) => {
            
        }
    }
})

export const {
    updateStep,
    updateTest,
    updateIsLoading,
    updateApplication,
    updateIsInviteCodeValid,
    updateIsVPNorIntl,
    updateProve,
    updateLeadUuid,
    updateLeadComplete,
    updateLeadError,
    validateUsernameStart,
    validateUsernameError,
    validateUsernameComplete,
    validateInviteStart,
    validateInviteComplete,
    createLeadStart,
    createLeadError,
    createLeadComplete,
    fetchLeadStart,
    fetchLeadError,
    fetchLeadComplete,
    submitPrequalComplete,
    submitPrequalError,
    onboardUserComplete
} = signupSlice.actions
  
export const validateInviteCode = (inviteCode: string) => async (dispatch: any) => {
    dispatch(validateInviteStart(inviteCode))

    return API.auth.verifyInviteCode(inviteCode)
        .then((response: any) => {
            dispatch(validateInviteComplete(response.data))
        })
        .catch((e) => {
            dispatch(updateIsInviteCodeValid(false))
        })
        .finally(() => {
            dispatch(updateIsLoading(false))
        })
}

export const validateUsername = (username: string) => async (dispatch: any) => {
    dispatch(validateUsernameStart(username))

    return API.auth.verifyUsername(username)
        .then((response: any) => {
            dispatch(validateUsernameComplete(response.data))
        })
        .catch((e) => {
            dispatch(validateUsernameError(e))
        })
}

export const testEndpoint = () => async (dispatch: any) => {
    dispatch(updateIsLoading(true))

    await setTimeout(() => {
        dispatch(updateTest())
        dispatch(updateIsLoading(false))
    }, 2000)
    
    return;
}

type CreateLeadParams = {
    inviteCode: string,
    productCode: string,
    dateOfBirth: string,
    phoneNumber: string,
    isMobile: boolean
}

export const createLead = (params: CreateLeadParams) => async (dispatch: any) => {
    dispatch(createLeadStart())

    return API.auth.createLead({
        flowType: params.isMobile ? "mobile" : "desktop",
        phone: params.phoneNumber,
        dateOfBirth: params.dateOfBirth,
        productCode: params.productCode,
        inviteCode: params.inviteCode
    })
        .then((response: any) => {
            dispatch(createLeadComplete(response.data))
        })
        .catch((e) => {
            // Error handling
            dispatch(createLeadError(e))
        })
        .finally(() => {
            dispatch(updateIsLoading(false))
        })
}

type UpdateLeadParams = {
    leadUuid: string,
    application: Application,
}

export const updateLead = (params: UpdateLeadParams) => async (dispatch: any) => {
    dispatch(updateIsLoading(true))

    return API.auth.patchLead({
        ...params.application,
        uuid: params.leadUuid,
    })
        .then((response: any) => {
            dispatch(updateLeadComplete())
        })
        .catch((e) => {
            // Error handling
            dispatch(updateLeadError())
        })
        .finally(() => {
            dispatch(updateIsLoading(false))
        })
}

type VerifyPhoneParams = {
    leadUuid: string,
    token: string,
}

export const verifyPhone = (params: VerifyPhoneParams) => async (dispatch: any) => {
    dispatch(updateIsLoading(true))

    API.auth.verifyPhoneNumber({
        leadUuid: params.leadUuid,
        token: params.token,
        verificationType: 'sms',
    })
        .then((response: any) => {
            
            dispatch(updateApplication({ isPhoneVerified: response.data.success }))
            if (false) {
                // If Taekus user already exists for this phone #

            } else {
                // New lead is created
                // dispatch(updateProveAuthToken('test'))
            }
        })
        .catch((e) => {
            // Error handling
            dispatch(updateApplication({ isPhoneVerified: false }))
        })
        .finally(() => {
            dispatch(updateIsLoading(false))
        })
}

type FetchLeadParams = {
    leadUuid: string,
    phone: string,
    token?: string,
    proveToken?: string,
    user: any,
}

export const fetchLead = (params: FetchLeadParams) => async (dispatch: any) => {
    dispatch(fetchLeadStart())

    API.auth.getLead({
        uuid: params.leadUuid,
        phone: params.phone,
        'mfa_token': params.token,
        'authentication_token': params.proveToken
    })
        .then((response: any) => {
            dispatch(fetchLeadComplete({
                data: response.data,
                user: params.user,
            }))
        })
        .catch((e) => {
            // Error handling
            dispatch(fetchLeadError(e.response.data))
        })
}

export const submitPrequal = (leadUuid: string) => async (dispatch: any) => {
    dispatch(updateIsLoading(true))

    API.auth.postLead({ uuid: leadUuid })
        .then((response: any) => {
            dispatch(submitPrequalComplete(response.data))
        })
        .catch((e) => {
            dispatch(submitPrequalError())
        })
        .finally(() => {
            dispatch(updateIsLoading(false))
        })
}

type OnboardParams = {
    uuid: string,
    username: string,
    password: string,
    cardHolderAgreementAcknowledgedUrl: string,
    creditTermsAcknowledgedUrl: string,
    debitTermsAcknowledgedUrl: string,
    electronicFundsTransferAcknowledgedUrl: string,
    electronicDisclosureAcknowledgedUrl: string,
    rewardTermsAcknowledgedUrl: string,
    privacyPolicyAcknowledgedUrl: string,
}

export const onboardUser = (params: OnboardParams) => async (dispatch: any) => {
    dispatch(updateIsLoading(true))

    API.auth.onboardUser({
            ...params,
        })
        .then((response: any) => {
            dispatch(onboardUserComplete(response.data))
        })
        .catch((e) => {
            // Error handling
        })
        .finally(() => {
            dispatch(updateIsLoading(false))
        })
}

export default signupSlice.reducer