import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { get } from "lodash";
import GooglePlacesAutocomplete, { geocodeByPlaceId } from "react-google-places-autocomplete";

import { Modal } from "react-bootstrap";

import styled from "styled-components";

import { ReactComponent as SearchIcon } from "assets/svg/Search.svg";

import { Actions } from "redux/currentUser";

import TextInput from "components/common/TextInput";
import { extractFromAddress } from "components/common/util";

import styles from "styles/styles";

const Addresses = () => {
    // Redux state
    const dispatch = useDispatch()
    const currentUser = useSelector((state: any) => state.currentUser.currentUser)
    const parentUser = currentUser.parentUser
    const mainUser = parentUser ? parentUser : currentUser

    // Component state
    const [searchValue, setSearchValue] = useState('')
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [address, setAddress] = useState<any>({
        googleAddress: undefined,
        unitType: undefined,
        unitNum: undefined
    })


    let allAddresses = [mainUser.primaryAddress, ...mainUser.otherAddresses]
    const displayAddresses = searchValue.length ? allAddresses.filter((address: any) => `${address?.street} ${address.unitNum} ${address.city} ${address.state}, ${address.zip}`.toLowerCase().includes(searchValue.toLowerCase())) : allAddresses

    const toggleIsModalOpen = () => {
        setIsModalOpen(!isModalOpen)
        setAddress({
            googleAddress: undefined,
            unitType: undefined,
            unitNum: undefined
        })
    }

    const handleAddressChange = (value: any) => {
        setAddress({
            ...address,
            googleAddress: value,
        })
    }

    const handleUnitTypeChange = (ev: React.ChangeEvent<HTMLSelectElement>) => {
        setAddress({
            ...address,
            unitType: ev.target.value,
        })
    }

    const handleUnitNumChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        setAddress({
            ...address,
            unitNum: ev.target.value,
        })
    }

    const parseGoogleAddressComponents = (gAddress: google.maps.GeocoderResult[]) => {
        let address_components = get(gAddress, '[0].address_components', [])
    
        // sometimes cities are sublocalities, and not localities.
        // Occasionally, no city exists at all, so we set it to a blank string.
        let city = ''
    
        if (extractFromAddress(address_components, 'locality') !== null) {
          city = extractFromAddress(address_components, 'locality')
        } else if (extractFromAddress(address_components, 'sublocality') !== null) {
          city = extractFromAddress(address_components, 'sublocality')
        } else {
          city = ''
        }
    
        const updatedAddress = {
          street:
            extractFromAddress(address_components, 'street_number') +
            ' ' +
            extractFromAddress(address_components, 'route'),
          city: city,
          state: extractFromAddress(address_components, 'administrative_area_level_1', true),
          zip: extractFromAddress(address_components, 'postal_code'),
        }
    
        return updatedAddress;
      }

    const submitNewAddress = () => {
        geocodeByPlaceId((address.googleAddress as any)?.value?.place_id)
            .then((gAddress: google.maps.GeocoderResult[]) => {
                return parseGoogleAddressComponents(gAddress)
            })
            .then((parsedAddress) => {
                dispatch(Actions.addAddress({
                    ...parsedAddress,
                    unitType: address.unitType,
                    unitNum: address.unitNum,
                }))
                toggleIsModalOpen()
            })
            .catch((error) => console.log(error))
    }

    const handleSearchChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(ev.target.value)
    }

    const mapAddressToRow = (address: any, index: number) => {
        const addressKey = `address-${index}`
        const fullAddress = `${address.street}${address.unitNum && ` ${address.unitNum}`}, ${address.city} ${address.state}, ${address.zip}`
        
        return <Address key={addressKey}>
            <div>{fullAddress}</div>
            {address.isPrimary && <div style={{opacity: 0.5}}>Primary</div>}
        </Address>
    }

    return <div>
        <Modal centered show={isModalOpen}>
            <ModalContent>
                <ModalTitle>Add new address</ModalTitle>
                <ModalSubtitle>Address</ModalSubtitle>
                <GooglePlacesAutocomplete
                    apiKey={(window as any).env.REACT_APP_GOOGLE_PLACES_API_KEY}
                    autocompletionRequest={{
                      componentRestrictions: { country: ['us'] },
                    }}
                    selectProps={{
                        value: address.googleAddress,
                        onChange: handleAddressChange,
                        placeholder: 'Search addresses',
                    }}
                  />
                <ModalSubtitle>Address Line 2 (Optional)</ModalSubtitle>
                <div className='d-flex'>
                    <UnitTypeSelect defaultValue='' value={address.unitType} onChange={handleUnitTypeChange}>
                        <option value="" disabled>Prefix</option>
                        <option>#</option>
                        <option>Apt</option>
                        <option>Bldg</option>
                        <option>Dept</option>
                        <option>Fl</option>
                        <option>Rm</option>
                        <option>Ste</option>
                        <option>Unit</option>
                    </UnitTypeSelect>
                    <UnitNumberInput value={address.unitNum} onChange={handleUnitNumChange} placeholder="Line 2" />
                </div>
                <div className='d-flex'>
                    <CancelButton onClick={toggleIsModalOpen}>Cancel</CancelButton>
                    <ConfirmButton onClick={submitNewAddress}>Confirm</ConfirmButton>
                </div>
            </ModalContent>
        </Modal>
        <Title>Addresses</Title>
        {parentUser ? <HelpText><b>Acting as an authorized user for {parentUser.firstName} {parentUser.lastName}</b></HelpText>:<></>}
        <div>
            <ContentHeader>
                <SearchContainer>
                    <TextInput 
                        onChange={handleSearchChange}
                        icon={<SearchIcon/>} 
                        placeholder={'Search'} 
                    />
                </SearchContainer>
                {currentUser.hasMultipleAddressAccess ? <AddAddressButton onClick={toggleIsModalOpen}>
                    + Add Address
                </AddAddressButton> : <AddAddressHelpText>
                    For assistance adding additional addresses to your account, please contact <span style={{color: styles.Color.TaekusPurple}}>support@taekus.com</span>.
                </AddAddressHelpText>}
            </ContentHeader>
            <div>
                {displayAddresses.map(mapAddressToRow)}
            </div>
        </div>
    </div>
}

const HelpText = styled.div`
     ${styles.Text.BodySmall}
    font-size: ${styles.Font.Size.Small};
    padding-top: ${styles.Spacing.S};
    text-align: center;
    color: ${styles.Color.NearBlack};
    opacity: 0.75;
`

const AddAddressButton = styled.button`
    background-color: ${styles.Color.Black};
    color: ${styles.Color.White};
    border: 0;
    padding: ${styles.Spacing.XS};
    height: fit-content;
    ${styles.MediaQueries.Mobile} {
        width: 100%;
    }
`

const AddAddressHelpText = styled.div`
    ${styles.Text.BodySmall}
    font-size: 14px;
    text-align: center;
    color: ${styles.Color.NearBlack};
    opacity: 0.75;
    ${styles.MediaQueries.Mobile} {
        width: 100%;
        padding: 0 ${styles.Spacing.S};
    }
    ${styles.MediaQueries.Desktop} {
        width: 50%;
        padding: 0 ${styles.Spacing.M};
    }
`

const SearchContainer = styled.div`
    margin: ${styles.Spacing.S} 0;
    border: 0;
    outline: 0;
    background-color: ${styles.Color.Transparent};
    ${styles.MediaQueries.Desktop} {
        width: 50%;
    }
    ${styles.MediaQueries.Mobile} {
        width: 100%;
    }
`

const ContentHeader = styled.div`
    ${styles.MediaQueries.Desktop} {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`

const UnitTypeSelect = styled.select`
    border: 1px solid hsl(0, 0%, 80%);
    margin-right: ${styles.Spacing.XS};
    padding: 6px 8px;
    border-radius: ${styles.BorderRadius.M};
`

const UnitNumberInput = styled.input`
    border: 1px solid hsl(0, 0%, 80%);
    flex: 1;
    padding: 6px 8px;
    border-radius: ${styles.BorderRadius.M};
`

const ModalTitle = styled.div`
    font-size: 24px;
`

const ModalSubtitle = styled.div`
    margin-top: ${styles.Spacing.XS};
`

const ModalContent = styled.div`
    padding: ${styles.Spacing.S};
`

const ConfirmButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${styles.Color.TaekusPurple};
    color ${styles.Color.White};
    border: 0;
    padding: 8px;
    flex: 1;
    margin-left: 4px;
    margin-top: ${styles.Spacing.XS};
    height: ${styles.Spacing.M};
`

const CancelButton = styled.button`
    background-color: ${styles.Color.Grey};
    color ${styles.Color.Black};
    border: 0;
    padding: 8px;
    flex: 1;
    margin-right: 4px;
    margin-top: ${styles.Spacing.XS};
    height: ${styles.Spacing.M};
`

const Title = styled.div`
    ${styles.Text.DisplayMedium}
    width: 100%;
    padding-bottom: ${styles.Spacing.XS};
    height: 50px;
    border-bottom: 1px solid grey;
`

const Address = styled.div`
    font-family: ${styles.Font.Family.MonumentGrotesk};
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    &:not(&:last-child) {
        border-bottom: 1px solid #D7D7D7;
    }
    ${styles.MediaQueries.Mobile} {
        flex-direction: column;
        padding: ${styles.Spacing.S} 0;
    }
    ${styles.MediaQueries.Desktop} {
        padding: 0 ${styles.Spacing.S};
        align-items: center;
    }
`

export default Addresses;