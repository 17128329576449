import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux";

import { styled } from "styled-components"

import messages from "components/signup/messages";
import { CompanyStructure, StepDirection } from "components/signup/types";

import ArrowButton, { ArrowDirection } from "components/signup/components/ArrowButton";
import Button from "components/signup/components/Button";
import Input from "components/signup/components/Input";
import StepContainer, { getStepContainerMotionProps } from "components/signup/StepContainer";

import styles from "styles/styles";
import { updateApplication, updateLead, updateStep } from "../signupSlice";
import { SignupStep } from "../constants";

type CaptureBizNameProps = {
}

const CaptureBizName = (props: CaptureBizNameProps) => {
    const dispatch = useDispatch()
    
    const inputRef = useRef(null)

    const application = useSelector((state: any) => state.signup.application)
    const leadUuid = useSelector((state: any) => state.signup.leadUuid)
    const stepDirection = useSelector((state: any) => state.signup.direction)

    const [bizName, setBizName] = useState(application.companyName || '')
    const [isInputDisabled, setIsInputDisabled] = useState(false)

    const companyLabel = messages.BizTypes[application.companyStructure as CompanyStructure]
    const isButtonDisabled = (application.companyStructure === CompanyStructure.SoleProp ? bizName.length === 0 : bizName.length < 3) || isInputDisabled

    const handleBack = (ev: any) => { 
        ev.stopPropagation();
        dispatch(updateStep({ step: SignupStep.BizType, direction: StepDirection.Left }))
    }

    const handleSubmit = (ev: React.FormEvent) => {
        ev.preventDefault();
        setIsInputDisabled(true)
        const applicationChanges = { companyName: bizName }

        dispatch(updateApplication(applicationChanges))
        dispatch(updateLead({ leadUuid, application: applicationChanges }))
        dispatch(updateStep({ step: SignupStep.BizDBA, direction: StepDirection.Right }))
    }

    const handleInputChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        setBizName(ev.target.value)
    }

    // Focus name input on mount
    useEffect(() => {
        (inputRef.current as any)?.focus()
    }, [])

    return <StepContainer
        {...getStepContainerMotionProps(stepDirection)}
        key='BusinessName'
    >
        <form className="d-flex flex-column align-items-center" onSubmit={handleSubmit}>
            <Title>What’s the legal name of your {companyLabel}?</Title>
            <Input showsError onChange={handleInputChange} value={bizName} parentRef={inputRef} label="Registered Business Name"/>
            <ButtonContainer>
                <BackButtonContainer>
                    <ArrowButton onClick={handleBack} direction={ArrowDirection.Left}/>
                </BackButtonContainer>
                <Button disabled={isButtonDisabled} type="submit">Next</Button>
            </ButtonContainer>
        </form>
    </StepContainer>
}

const BackButtonContainer = styled.div`
    margin-right: 16px;
`

const ButtonContainer = styled.div`
    margin-top: 32px;
    display: flex;
`

const Title = styled.div`
    color: ${styles.Color.TaekusBlack};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 49.6px */
    letter-spacing: 0.4px;
    margin-bottom: 32px;
    ${styles.MediaQueries.Mobile} {
        font-size: 24px;
    }
`

export default CaptureBizName