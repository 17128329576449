import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";

import { styled } from "styled-components"

import { ProductType, CompanyStructure, HousingType, StepDirection, ProductCategory } from "components/signup/types";

import ArrowButton, { ArrowDirection } from "components/signup/components/ArrowButton";
import Button from "components/signup/components/Button";
import CurrencyInput from "components/signup/components/CurrencyInput";
import Input from "components/signup/components/Input";
import StepContainer, { getStepContainerMotionProps } from "components/signup/StepContainer";
import SSNInput from "components/signup/components/SSNInput";

import styles from "styles/styles";
import AddressInput from "../components/AddressInput";
import { updateApplication, updateLead, updateStep } from "../signupSlice";
import { geocodeByPlaceId } from "react-google-places-autocomplete";
import { parseGoogleAddress } from "components/common/util";
import Selectable from "../components/Selectable";
import { SignupStep, taxCategories } from "../constants";
import { AnimatePresence, motion } from "framer-motion";
import moment from "moment";
import Select from "../components/Select";
import messages from "../messages";
import { validateEmail, validateString, validateSSN, validateIncome, validateHousing, validateBusinessStructure, validateBusinessIndustry, validateEIN, validateFormationDate, validatePercentOwnership, validateAddress } from "../validators";
import { useIsFirstRender } from "hooks/useIsFirstRender";
import { formatAsUSD } from "../utils";
import { HousingError } from "../errors";
import { expandVerticallyMotionProps } from "styles/motionConstants";

const companyStructureList = [
    CompanyStructure.SoleProp,
    CompanyStructure.LLC,
    CompanyStructure.cCorp,
    CompanyStructure.sCorp,
]

const inputFadeInOutMotionProps = {
    initial:{ height: 0, opacity: 0, margin: 0 },
    exit:{ height: 0, opacity: 0, margin: 0 },
    animate:{ height: 'auto', opacity: 1, marginTop: '12px' },
}

type EditInfoProps = {
}

const EditInfo = (props: EditInfoProps) => {
    const dispatch = useDispatch()
    const isFirstRender = useIsFirstRender()

    const application = useSelector((state: any) => state.signup.application)
    const stepDirection = useSelector((state: any) => state.signup.direction)
    const prevStep = useSelector((state: any) => state.signup.prevStep)
    const leadUuid = useSelector((state: any) => state.signup.leadUuid)
    const isLoading = useSelector((state: any) => state.signup.isLoading)
    const products = useSelector((state: any) => state.signup.products)

    const industryOptions = taxCategories.map(category => ({ value: category, label: category }))
    const structureOptions = companyStructureList.map(category => ({ value: category, label: messages.BizTypes[category] }))

    const [showErrors, setShowErrors] = useState(false)
    const [errors, setErrors] = useState<any>({})
    const [firstName, setFirstName] = useState(application.firstName || '')
    const [lastName, setLastName] = useState(application.lastName || '')
    const [ssn, setSSN] = useState(application.ssn || application.ssnLastFour || '')
    const [showLastFour, setShowLastFour] = useState(!application.ssn && application.ssnLastFour)
    const [address, setAddress] = useState<any>(application.addressStreet ? {
        label: `${application.addressStreet}, ${application.addressCity}, ${application.addressState}`,
        value: undefined,
    } : undefined)
    const [addressLine2, setAddressLine2] = useState(application.addressLineTwo || '')
    const [email, setEmail] = useState(application.email || '')
    const [income, setIncome] = useState(Number(application.totalAnnualIncome) ? Number(application.totalAnnualIncome)?.toLocaleString() : '')
    const [housingType, setHousingType] = useState<HousingType | undefined>(application.residenceType || undefined)
    const [housingPayment, setHousingPayment] = useState(Number(application.monthlyHousingPayment) ? Number(application.monthlyHousingPayment)?.toLocaleString() : '')

    const [bizName, setBizName] = useState(application.companyName || '')
    const [dba, setDBA] = useState(application.dbaName || '')
    const [businessStructure, setBusinessStructure] = useState(application.companyStructure ? structureOptions.find(option => option.value === application.companyStructure) : undefined) 
    const [ein, setEin] = useState(application.taxIdentificationNumber || '')
    const [formationDate, setFormationDate] = useState(application.formationDate ? moment(application.formationDate).format('YYYY-MM-DD') : '')
    const [percentOwnership, setPercentOwnership] = useState(Number(application.businessPercentOwnership) ? Number(application.businessPercentOwnership).toLocaleString() : '')
    const [naicsCategory, setNaicsCategory] = useState(application.naicsCategory ? industryOptions.find(option => option.value === application.naicsCategory) : undefined)
    const [bizAddress, setBizAddress] = useState<any>(application.businessAddressStreet ? {
        label: `${application.businessAddressStreet}, ${application.businessAddressCity}, ${application.businessAddressState}`,
        value: undefined,
    } : undefined)
    const [bizAddressLine2, setBizAddressLine2] = useState(application.businessAddressLineTwo || '')

    const selectedProduct = products?.find((product: any) => product.productCode === application.productCode)
    const showEmail = prevStep === SignupStep.FinalReview
    const showCreditFields = prevStep === SignupStep.FinalReview && selectedProduct?.productType === ProductType.Credit
    const showBusinessFields = prevStep === SignupStep.FinalReview && selectedProduct?.productCategory === ProductCategory.Business

    const handleBack = (ev: React.MouseEvent) => {
        dispatch(updateStep({ step: prevStep, direction: StepDirection.Up }))
    }

    const validateApplication = () => {
        // Basic fields
        const firstNameError = validateString(firstName)
        const lastNameError = validateString(lastName)
        const ssnError = !showLastFour && validateSSN(ssn)
        const addressError = validateAddress(address)
        const addressLine2Error = addressLine2 !== '' && validateString(addressLine2)
        const emailError = validateEmail(email)

        // Credit fields
        const incomeError = validateIncome(income)
        const housingError = validateHousing(housingType, housingPayment)

        // Business fields
        const businessStructureError = validateBusinessStructure(businessStructure?.value)
        const businessNameError = businessStructure?.value !== CompanyStructure.SoleProp && validateString(bizName)
        const einError = validateEIN(ein, businessStructure?.value)
        const businessAddressError = validateAddress(bizAddress)
        const businessAddressLine2Error = bizAddressLine2 !== '' && validateString(bizAddressLine2)
        const businessIndustryError = validateBusinessIndustry(naicsCategory?.value)
        const businessDBAError = dba !== '' && validateString(dba)
        const formationDateError = validateFormationDate(formationDate)
        const percentOwnershipError = validatePercentOwnership(percentOwnership)

        const isBasicInfoInvalid = !![firstNameError, lastNameError, ssnError, addressError, addressLine2Error].some(error => error !== false && error !== undefined)
        const isEmailInvalid = showEmail && emailError !== undefined
        const isCreditInfoInvalid = showCreditFields && !![incomeError, housingError].some(error => error !== undefined)
        const isBusinessInfoInvalid = showBusinessFields && !![
            businessStructureError,
            businessNameError, 
            einError, 
            businessAddressError, 
            businessAddressLine2Error, 
            businessIndustryError, 
            businessDBAError,
            formationDateError
        ].some(error => error !== false && error !== undefined)

        setErrors({
            // basic fields
            firstName: firstNameError,
            lastName: lastNameError,
            ssn: ssnError,
            address: addressError,
            email: emailError,

            // credit fields
            income: incomeError,
            housing: housingError,

            // business fields
            companyStructure: businessStructureError,
            companyName: businessNameError,
            ein: einError,
            bizAddress: businessAddressError,
            naicsCategory: businessIndustryError,
            dbaName: businessDBAError,
            formationDate: formationDateError,
            percentOwnership: percentOwnershipError
        })
        return !(isBasicInfoInvalid || isEmailInvalid || isCreditInfoInvalid || isBusinessInfoInvalid)
    }

    const handleSubmit = async (ev: React.FormEvent<HTMLFormElement>) => {
        ev.preventDefault();

        const isInputValid = validateApplication()

        if (isInputValid) {
            // Geocode personal address
            const parsedAddress = await geocodeByPlaceId(address?.value?.place_id)
            .then((gAddress) => parseGoogleAddress(gAddress))
            .catch(() => undefined)
            // Geocode biz address
            const parsedBizAddress = await geocodeByPlaceId(bizAddress?.value?.place_id)
                .then((gAddress) => parseGoogleAddress(gAddress))
                .catch(() => undefined)

            const applicationChanges = {
                firstName,
                lastName,
                ssn: showLastFour ? undefined : ssn,
                addressStreet: parsedAddress?.street || application.addressStreet,
                addressCity: parsedAddress?.city || application.addressCity,
                addressState: parsedAddress?.state || application.addressState,
                addressZip: parsedAddress?.zip || application.addressZip,
                addressLineTwo: addressLine2,
                email: email,

                totalAnnualIncome: income.replaceAll(',',''),
                residenceType: housingType,
                monthlyHousingPayment: housingType === HousingType.Rent ? housingPayment.replaceAll(',','') : undefined,
                
                companyStructure: businessStructure?.value,
                companyName: bizName,
                taxIdentificationNumber: ein,
                businessAddressStreet: parsedBizAddress?.street || application.businessAddressStreet,
                businessAddressCity: parsedBizAddress?.city || application.businessAddressCity,
                businessAddressState: parsedBizAddress?.state || application.businessAddressState,
                businessAddressZip: parsedBizAddress?.zip || application.businessAddressZip,
                businessAddressLineTwo: bizAddressLine2,
                naicsCategory: naicsCategory?.value,
                dbaName: dba,
                formationDate: formationDate !== '' ? formationDate: undefined,
                businessPercentOwnership: Number(percentOwnership)
            }

            dispatch(updateApplication(applicationChanges))
            dispatch(updateLead({ leadUuid, application: applicationChanges }))
        } else {
            setShowErrors(true)
        }
    }

    // onChange handlers

    const handleFirstNameChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        if (ev.target.value.length > 40) return;

        setFirstName(ev.target.value)
        setErrors({ ...errors, firstName: validateString(ev.target.value) })
    }

    const handleLastNameChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        if (ev.target.value.length > 40) return;
        
        setLastName(ev.target.value)
        setErrors({ ...errors, lastName: validateString(ev.target.value) })
    }

    const handleSsnChange = (value: string) => {
        if (showLastFour) {
            setShowLastFour(false)
            setSSN('')
            setErrors({ ...errors, ssn: validateSSN('') })
        } else {
            setSSN(value)
            setErrors({ ...errors, ssn: validateSSN(value) })
        }
    }

    const handleAddressChange = (value: any) => {
        setAddress(value)
    }

    const handleAddress2Change = (ev: React.ChangeEvent<HTMLInputElement>) => {
        if (ev.target.value.length > 40) return;
        
        setAddressLine2(ev.target.value)
    }

    const handleEmailChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        if (ev.target.value.length > 60) return;

        setEmail(ev.target.value)
        setErrors({ ...errors, email: validateEmail(ev.target.value) })
    }

    const handleIncomeChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        if (String(Number(ev.target.value.replaceAll(',',''))).length <= 9) {
            const inputValue = ev.target.value;
            const formattedValue = formatAsUSD(inputValue);
            setIncome(formattedValue);
            setErrors({ ...errors, income: validateIncome(formattedValue) })
        }
    }

    const updateHousingType = (ev: React.MouseEvent<HTMLButtonElement>) => {
        setHousingType((ev.target as any).id)
        setErrors({ ...errors, housing: validateHousing((ev.target as any).id, housingPayment) })
    }

    const handleHousingPaymentChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        if (String(Number(ev.target.value.replaceAll(',',''))).length <= 9) {
            const inputValue = ev.target.value;
            const formattedValue = formatAsUSD(inputValue);
            setHousingPayment(formattedValue);
        }
    }

    const handleStructureSelect = (option: any) => {
        setBusinessStructure(option)
        setErrors({ ...errors, companyStructure: validateBusinessStructure(option?.value) })
    }

    const handleBusinessNameChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        setBizName(ev.target.value)
        setErrors({ ...errors, companyName: validateString(ev.target.value) })
    }
    
    const handleIndustrySelect = (option: any) => {
        setNaicsCategory(option)
        setErrors({ ...errors, naicsCategory: validateBusinessIndustry(option?.value) })
    }

    const handleEINChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        if (/[^\d-]/.test(ev.target.value)) return;

        if (ein.includes('-') && !ev.target.value.includes('-')) {
            const cleanedString = ev.target.value.slice(0,1)
            setEin(cleanedString)
            setErrors({ ...errors, ein: validateEIN(cleanedString, businessStructure?.value) })
            return
        }

        const cleaned = ev.target.value.replaceAll('-', '')
        const formatted = cleaned.length >= 2 ? cleaned.slice(0, 2) + '-' + cleaned.slice(2) : cleaned

        if (cleaned.length <= 9) {
            setEin(formatted)
            setErrors({ ...errors, ein: validateEIN(formatted, businessStructure?.value) })
        }
    }

    const handleBizAddressChange = (value: any) => {
        setBizAddress(value)
        setErrors({ ...errors, bizAddress: validateAddress(value) })
    }

    const handleBizAddress2Change = (ev: React.ChangeEvent<HTMLInputElement>) => {
        if (ev.target.value.length > 40) return;

        setBizAddressLine2(ev.target.value)
    }

    const handleDBAChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        if (ev.target.value.length > 40) return;
        
        setDBA(ev.target.value)
        setErrors({ ...errors, dbaName: dba !== '' && validateString(ev.target.value) })
    }

    const handleFormationDateChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        setFormationDate(ev.target.value)
        setErrors({ ...errors, formationDate: validateFormationDate(ev.target.value) })
    }

    const handlePercentChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        const asNumber = Number(ev.target.value)
        if (isNaN(asNumber) || asNumber > 100 || asNumber < 0 || ev.target.value.length > 5) return;

        setPercentOwnership(ev.target.value)
        setErrors({ ...errors, percentOwnership: validatePercentOwnership(ev.target.value) })
    }

    // Go back to review page when API is completed
    useEffect(() => {
        const isErrors = Object.values(errors).filter(e => !!e).length > 0
        if (!isFirstRender && isLoading === false && !isErrors) {
            dispatch(updateStep({ step: prevStep, direction: StepDirection.Up, prevStep: SignupStep.EditInfo }))
        }
    }, [isLoading])

    return <StepContainer
        {...getStepContainerMotionProps(stepDirection)}
        key='EditInfo'
    >
        <Form onSubmit={handleSubmit}>
            <Title>Edit your info.</Title>
            <div style={{display: 'flex'}}>
                <InputList>
                    <InputContainer>
                        <Input 
                            errorMessage={showErrors && messages.Errors.FirstName[errors.firstName]} 
                            onChange={handleFirstNameChange} 
                            value={firstName} 
                            label="First Name"
                        />
                    </InputContainer>
                    <InputContainer>
                        <Input 
                            errorMessage={showErrors && messages.Errors.LastName[errors.lastName]} 
                            onChange={handleLastNameChange}  
                            value={lastName} 
                            label="Last Name"
                        />
                    </InputContainer>
                    <InputContainer>
                        <SSNInput 
                            errorMessage={showErrors && messages.Errors.SSN[errors.ssn]}
                            lastFour={showLastFour}
                            value={ssn}
                            onChange={handleSsnChange}
                        />
                    </InputContainer>
                    <InputContainer>
                        <AddressInput
                            errorMessage={showErrors && messages.Errors.Address[errors.address]}
                            onChange={handleAddressChange}
                            value={address}
                        />
                    </InputContainer>
                    <InputContainer>
                        <Input
                            autocomplete='nope'
                            onChange={handleAddress2Change}
                            value={addressLine2}
                            label="Address Line 2*"
                        />
                    </InputContainer>
                    {showEmail && <InputContainer>
                        <Input
                            errorMessage={showErrors && messages.Errors.Email[errors.email]}
                            onChange={handleEmailChange} 
                            value={email}
                            label="Email"
                        />
                    </InputContainer>}
                    {showCreditFields && <InputContainer>
                        <CurrencyInput 
                            errorMessage={showErrors && messages.Errors.Income[errors.income]} 
                            value={income} 
                            onChange={handleIncomeChange} 
                            label="Annual Income"
                        />
                    </InputContainer>}
                    {showCreditFields && <InputContainer>
                        <div style={{display: 'flex'}}>
                            <Selectable
                                id={HousingType.Own}
                                onClick={updateHousingType}
                                selected={housingType === HousingType.Own}
                                label='Own'
                            />
                            <Selectable
                                id={HousingType.Rent} 
                                onClick={updateHousingType} 
                                selected={housingType === HousingType.Rent} 
                                label='Rent'
                            />
                        </div>
                        <AnimatePresence mode='wait'>
                            {(showErrors && errors.housing === HousingError.Required) && <motion.div style={{minWidth: '100%', width: 'min-content'}} {...expandVerticallyMotionProps}>
                                <ErrorMessage>{messages.Errors.Housing[errors.housing]}</ErrorMessage>
                            </motion.div>}
                        </AnimatePresence>
                    </InputContainer>}
                    <AnimatePresence mode='wait'>
                        {(showCreditFields && housingType === HousingType.Rent) && <InputContainer {...inputFadeInOutMotionProps}>
                            <CurrencyInput errorMessage={showErrors && messages.Errors.Housing[errors.housing]} value={housingPayment} onChange={handleHousingPaymentChange} label="Monthly housing payment"/>
                        </InputContainer>}
                    </AnimatePresence>
                </InputList>
                {showBusinessFields && <InputList>
                    <InputContainer>
                        <Select 
                            errorMessage={showErrors && messages.Errors.BusinessStructure[errors.companyStructure]} 
                            options={structureOptions} 
                            selected={businessStructure} 
                            onSelect={handleStructureSelect} 
                            label='Company Structure'
                        />
                    </InputContainer>
                    <AnimatePresence mode='wait'>
                        {businessStructure?.value !== CompanyStructure.SoleProp && <InputContainer {...inputFadeInOutMotionProps}>
                            <Input 
                                errorMessage={showErrors && messages.Errors.CompanyName[errors.companyName]} 
                                onChange={handleBusinessNameChange}
                                value={bizName} 
                                label="Business Name"
                            />
                        </InputContainer>}
                    </AnimatePresence>
                    <InputContainer>
                        <Input
                            errorMessage={showErrors && messages.Errors.EIN[errors.ein]}
                            onChange={handleEINChange}
                            value={ein}
                            label="EIN"
                        />
                    </InputContainer>
                    <InputContainer>
                        <AddressInput 
                            errorMessage={showErrors && messages.Errors.Address[errors.bizAddress]} 
                            placeholder="Business Address" 
                            onChange={handleBizAddressChange}
                            value={bizAddress}
                        />
                    </InputContainer>
                    <InputContainer>
                        <Input 
                            onChange={handleBizAddress2Change}
                            value={bizAddressLine2}
                            label="Business Address Line 2*"
                        />
                    </InputContainer>
                    <InputContainer>
                        <Select 
                            errorMessage={showErrors && messages.Errors.BusinessIndustry[errors.naicsCategory]} 
                            options={industryOptions} 
                            selected={naicsCategory} 
                            onSelect={handleIndustrySelect} 
                            label='Industry type'
                        />
                    </InputContainer>
                    <InputContainer>
                        <Input 
                            errorMessage={showErrors && messages.Errors.DBA[errors.dbaName]}
                            onChange={handleDBAChange}
                            value={dba} 
                            label="DBA"
                        />
                    </InputContainer>
                    <InputContainer>
                        <Input 
                            errorMessage={showErrors && messages.Errors.FormationDate[errors.formationDate]}
                            type='date' 
                            max={moment().format('YYYY-MM-DD')}
                            onChange={handleFormationDateChange}
                            value={formationDate}
                            label="Established Date"
                        />
                    </InputContainer>
                    <InputContainer>
                        <Input
                            errorMessage={showErrors && messages.Errors.PercentOwnership[errors.percentOwnership]} 
                            onChange={handlePercentChange} 
                            value={percentOwnership} 
                            label="% Ownership"
                        />
                    </InputContainer>
                </InputList>}
            </div>
            <ButtonContainer>
                <BackButtonContainer>
                    <ArrowButton onClick={handleBack} direction={ArrowDirection.Left}/>
                </BackButtonContainer>
                <Button isLoading={isLoading} type="submit">Next</Button>
            </ButtonContainer>
        </Form>
    </StepContainer>
}

const ErrorMessage = styled.div`
    color: ${styles.Color.TaekusRed};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 14.88px */
    letter-spacing: 0.24px;
    padding: 8px 16px 0;
`

const BackButtonContainer = styled.div`
    margin-right: 16px;
`

const ButtonContainer = styled.div`
    margin-top: 32px;
    display: flex;
`

const InputList = styled.div`
    width: 416px;
    &:not(&:last-child) {
        margin-right: 16px;
    }
`

const InputContainer = styled(motion.div)`
    &:not(&:first-child) {
        margin-top: 12px;
    }
`

const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: calc(100dvh - 120px);
    padding: 10px;
    overflow-y: auto;
    ${styles.Scrollbar.defaultScrollbarStyles}
`

const Title = styled.div`
    color: ${styles.Color.TaekusGrey1};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 49.6px */
    letter-spacing: 0.4px;
    margin-bottom: 16px;
`

export default EditInfo