import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router"
import { Link, NavLink } from "react-router-dom"

import moment from "moment"

import PhoneInput from 'react-phone-number-input'

import { FaRegFileImage, FaRegTimesCircle, FaUpload } from 'react-icons/fa'

import { AnimatePresence, motion } from "framer-motion"
import styled from "styled-components"

import { Actions as BookingActions } from 'redux/features/flightBook'
import { updateToast } from "redux/features/global/globalSlice"

import Button, { ButtonSize, ButtonType } from "components/common/Button"
import Spinner from "components/common/Spinner"

import { AppPath } from "components/appRouter/constants"
import Navigation, { NavColor } from "components/navbar/Navigation"

import PaymentDetailsCard from "components/flights/flightBook/PaymentDetailsCard"

import { AwardTravelClass, BookingError, TravelClass, TripType, defaultSearchOptions, messages } from "components/pages/Rewards/constants"
import { PassengerValidationErrorLabels, StepUpText, VALID_IMAGE_TYPES, ValidationRequirements } from "components/pages/Rewards/Flights/constants"
import FlightTaxesModal from "components/pages/Rewards/Flights/FlightBooking/FlightTaxesModal"
import ItineraryReview from "components/pages/Rewards/Flights/ItineraryReview"
import PriceModal from "components/pages/Rewards/PriceModal"
import Upsell from "components/pages/Rewards/Upsell"
import { getSearchOptionsFromLocalStorage } from "components/pages/Rewards/utils"

import { Locale, USDCurrencyOptions } from 'utils/constants';
import { validateEmail, validateString } from "utils/utils"

import { ReactComponent as ArrowLeftIcon } from "assets/svg/ArrowLeft.svg";
import { ReactComponent as Checkmark } from "assets/svg/Checkmarks/Checkmark.svg";
import { ReactComponent as Close } from "assets/svg/Close.svg";

import { fadeInOutMotionProps } from "styles/motionConstants"
import styles from "styles/styles"

import 'components/pages/Rewards/phoneInputStyles.css'
import FinalAvailabilityWs from "components/pages/Rewards/Flights/FinalAvailabilityWs"

const SIDEBAR_WIDTH = 240

enum ConfirmationSteps {
    ReviewItinerary,
    PassengerInfo,
    PayAndConfirm,
    Success
}

const defaultPassengerDetails = {
    firstName: undefined,
    middleName: undefined,
    lastName: undefined,
    dateOfBirth: '',
    gender: undefined,
    phone: undefined,
    email: undefined,
    knownTravellerNumber: undefined,
    redressNumber: undefined,
    VNAFrequentFlyerNumber: undefined,
    passportNumber: undefined,
    passportCountry: undefined,
    passportExpDate: undefined,
    passportImage: undefined
}

const defaultPassengerDetailsErrors = {
    firstName: null,
    middleName: null,
    lastName: null,
    dateOfBirth: null,
    gender: null,
    phone: null,
    email: null,
    knownTravellerNumber: null,
    redressNumber: null,
    VNAFrequentFlyerNumber: null,
    passportNumber: null,
    passportCountry: null,
    passportExpDate: null,
    passportImage: null
}

interface PassportErrors {
    [Key: number]: String;
}

const FlightConfirmation = () => {
    // Redux state
    const dispatch = useDispatch()
    const history = useHistory()
    const banking = useSelector((state: any) => state.banking)
    const currentUser = useSelector((state: any) => state.currentUser.currentUser)
    const outboundItinerary = useSelector((state: any) => state.flightSearch.searchParams.outboundItinerary)
    const inboundItinerary = useSelector((state: any) => state.flightSearch.searchParams.inboundItinerary)
    const flightBook = useSelector((state: any) => state.flightBook)
    const priceDetail = useSelector((state: any) => state.flightBook.priceDetail)
    const numberOfPassengers = useSelector((state: any) => state.flightSearch.searchParams.numPax.value)
    const isMobile = useSelector((state: any) => state.global.isMobile)

    // Component state
    const [confirmationStep, setConfirmationStep] = useState(ConfirmationSteps.ReviewItinerary)
    const [isTaxDetailOpen, setIsTaxDetailOpen] = useState(false)
    const [passengerDetails, setPassengerDetails] = useState([{
        firstName: currentUser.firstName,
        middleName: currentUser.middleName,
        lastName: currentUser.lastName,
        dateOfBirth: moment(currentUser.dateOfBirth).format('YYYY-MM-DD'),
        gender: currentUser.gender,
        phone: currentUser.phone,
        email: currentUser.email,
        VNAFrequentFlyerNumber: currentUser.VNAFrequentFlyerNumber,
        passportImage: currentUser.passportImageUuid
    },
    ...Array(numberOfPassengers - 1).fill(null).map(() => (JSON.parse(JSON.stringify(defaultPassengerDetails))))
    ])

    const [showValidationErrors, setShowValidationErrors] = useState(false)
    const [passportImageValidation, setPassportImageValidation] = useState<PassportErrors>({})
    const [passengerDetailsErrors, setPassengerDetailsErrors] = useState(Array(numberOfPassengers).fill(defaultPassengerDetailsErrors))
    const [isAwaitingBooking, setIsAwaitingBooking] = useState(false)
    const pageBody = useRef(null)
    const hiddenFileInput = useRef<HTMLInputElement[]>([]); // used to hide passport image input and show button instead
    const [isLoadingLiveScrape, setIsLoadingLiveScrape] = useState(false);
    const [completedLiveScrape, setCompletedLiveScrape] = useState<any>(null);
    const [awardTicketAvailable, setAwardTicketAvailable] = useState<any>(null);
    const [livescrapeParams, setLivescrapeParams] = useState<any>({});
    const [websocketRequestViaCounterToChildComponent, setWebsocketRequestViaCounterToChildComponent] = useState<number>(0);
    const requestIdToParams = useRef<any>({});

    const searchOptions = getSearchOptionsFromLocalStorage() || defaultSearchOptions
    const totalCash = priceDetail.priceCashBase + priceDetail.priceCashTaxes
    const totalPoints = priceDetail.pricePointsBase + priceDetail.pricePointsTaxes
    const userHasInsufficientPoints = currentUser.numPointsPending < totalPoints
    const userHasInsufficientFunds = banking.account.balance.availableBalance < totalCash

    const decrementConfirmationStep = () => {
        if (confirmationStep > ConfirmationSteps.ReviewItinerary) {
            setConfirmationStep(confirmationStep - 1)
            dispatch(BookingActions.clearFlightError())
        }
    }

    const incrementConfirmationStep = () => {
        if (confirmationStep < ConfirmationSteps.PayAndConfirm) {
            (pageBody.current as any).scrollTo!({ top: 0, behavior: 'smooth' })
            if (confirmationStep === ConfirmationSteps.PassengerInfo && !isPassengerDetailsValid()) {
                setShowValidationErrors(true)
                return;
            }
            setConfirmationStep(confirmationStep + 1)
        } else {
            bookItinerary()
        }
    }

    const generatePassengerDetailOnChangeHandler = (passengerIndex: number) => {
        return (ev: React.ChangeEvent) => {
            const detailsCopy = [...passengerDetails];
            detailsCopy[passengerIndex][ev.target.id] = (ev.target as HTMLInputElement).value;
            setPassengerDetails(detailsCopy)
            isPassengerDetailsValid()
        }
    }

    const bookItinerary = () => {
        setIsAwaitingBooking(true)
        const formattedPassengerDetails = passengerDetails.map(
            (passenger: any, index: number) => {
                const passengerWithTravellerNumber = { ...passenger, travellerNum: index + 1 }
                delete passengerWithTravellerNumber.passportImage
                const passportUuid = flightBook.passportImage[index]?.uuid
                if (priceDetail.stepUpRequired && !!passportUuid) {
                    return {
                        ...passengerWithTravellerNumber,
                        passportImageUuid: flightBook.passportImage[index].uuid,
                    }
                } else {
                    return passengerWithTravellerNumber
                }
            })

        const bookingParams = {
            priceDetail,
            passengers: formattedPassengerDetails,
            outboundItineraryId: outboundItinerary.id,
            inboundItineraryId: inboundItinerary?.id,
            cardAccountUuid: banking.account.uuid,
        }
        dispatch(BookingActions.bookItinerary(bookingParams))
    }

    const getTravelerLabel = () => {
        return `${numberOfPassengers} Traveler${numberOfPassengers > 1 ? 's' : ''}`
    }

    const getTravelClassLabel = () => {
        switch (searchOptions.travelClass) {
            case TravelClass.PremiumEconomy:
                return messages.SearchOptions.TravelClass[TravelClass.PremiumEconomy]
            case TravelClass.Business:
                return messages.SearchOptions.TravelClass[TravelClass.Business]
            case TravelClass.FirstClass:
                return messages.SearchOptions.TravelClass[TravelClass.FirstClass]
            case TravelClass.Economy:
            default:
                return messages.SearchOptions.TravelClass[TravelClass.Economy]
        }
    }

    const isPassengerDetailsValid = () => {
        const copyOfPassengerDetailsErrors = JSON.parse(JSON.stringify(passengerDetailsErrors))

        const updatedErrors = passengerDetails.map((details, passengerIndex) => {
            const getDateOfBirthError = (dateOfBirth: string) => {
                if (!validateString(dateOfBirth)) {
                    return PassengerValidationErrorLabels.DateOfBirth.MissingValue
                }
                if (moment(dateOfBirth).isBefore(moment().subtract(110, 'years'))) {
                    return PassengerValidationErrorLabels.DateOfBirth.Exceeds110
                }
                if (passengerIndex === 0 && moment(dateOfBirth).isAfter(moment().subtract(18, 'years'))) {
                    return PassengerValidationErrorLabels.DateOfBirth.Under18
                }
                return null;
            }

            const firstName = validateString(details.firstName) ? null : PassengerValidationErrorLabels.FirstName.MissingValue
            const lastName = validateString(details.lastName) ? null : PassengerValidationErrorLabels.LastName.MissingValue
            const dateOfBirth = getDateOfBirthError(details.dateOfBirth)
            const email = ((passengerIndex === 0 || priceDetail.stepUpRequired) && !validateEmail(details.email)) ? PassengerValidationErrorLabels.Email.Invalid : null
            const phone = ((passengerIndex === 0 || priceDetail.stepUpRequired) && !validateString(details.phone)) ? PassengerValidationErrorLabels.Phone.Invalid : null
            const gender = validateString(details.gender) ? null : PassengerValidationErrorLabels.Gender.MissingValue
            // if step up is required, passport image uuid must be set with no errors
            const passportImage = (priceDetail.stepUpRequired && (!flightBook.passportImage[passengerIndex]?.uuid || !!flightBook.passportImage[passengerIndex]?.error)) ? PassengerValidationErrorLabels.PassportImage.MissingValue : null

            return {
                ...copyOfPassengerDetailsErrors[passengerIndex],
                firstName,
                lastName,
                dateOfBirth,
                email,
                phone,
                gender,
                passportImage
            }
        })

        setPassengerDetailsErrors(updatedErrors)
        return !updatedErrors.some(errors => Object.values(errors).some(error => error !== null))
    }

    const userCannotBook = () => {
        if (userHasInsufficientFunds) {
            return getBookingErrorLabel(BookingError.InsufficientFunds)
        } else if (userHasInsufficientPoints) {
            return getBookingErrorLabel(BookingError.InsufficientPoints)
        } else {
            return ""
        }
    }

    const getBookingErrorLabel = (error: BookingError) => {
        switch (error) {
            case BookingError.InsufficientFunds:
                return "You don't have enough funds for this redemption. Please deposit into this account or switch to an account with enough funds."
            case BookingError.InsufficientPoints:
                return "You don't have enough points for this redemption."
            default:
                return "An error occured while attempting to book your ticket."
        }
    }

    const toggleIsTaxDetailOpen = () => {
        setIsTaxDetailOpen(!isTaxDetailOpen)
    }

    const handleImageUploadClick = (index: any) => {
        if (hiddenFileInput.current) {
            hiddenFileInput.current[index].click()
        }
    }

    const updatePassportImageValidation = (index: number, value: string) => {
        const copiedPassportImageValidation = JSON.parse(JSON.stringify(passportImageValidation))
        copiedPassportImageValidation[index] = value
        setPassportImageValidation({ ...copiedPassportImageValidation })
    }

    const submitImageHandler = (passengerIndex: number) => {
        return (event: any) => {
            const imageFile = event.target.files[0]
            const detailsCopy = [...passengerDetails];
            detailsCopy[passengerIndex][event.target.id] = imageFile;
            setPassengerDetails(detailsCopy)

            const errors: String[] = []
            if (imageFile.size > 5000000) {
                errors.push(PassengerValidationErrorLabels.PassportImage.ExceedsSize)
            }
            if (!VALID_IMAGE_TYPES.includes(imageFile.type)) {
                errors.push(PassengerValidationErrorLabels.PassportImage.InvalidType)
            }
            if (!!errors.length) {
                updatePassportImageValidation(passengerIndex, errors.join(" "))
            } else {
                updatePassportImageValidation(passengerIndex, "")
                const formData = new FormData();
                formData.append("image", imageFile);
                dispatch(BookingActions.uploadPassport(passengerIndex, formData))
            }
            return
        }
    }

    const currentAccountName = () => {
        try {
            const foundAccount = currentUser.cardAccounts.find((account: any) => account.uuid === banking.account.uuid)
            return foundAccount.name + " account ending in " + foundAccount.accountLast4
        } catch (error) {
            return "account"
        }
    }

    useEffect(() => {
        // Used to recheck input validations after a passport is uploaded
        isPassengerDetailsValid()
    }, [flightBook.passportImage]) // eslint-disable-line

    const removeImage = (passengerIndex: number) => {
        if (flightBook.passportImage[passengerIndex]?.uuid && flightBook.passportImage[passengerIndex]?.uuid !== currentUser.passportImageUuid) {
            // Existing passport is not set at account level and can be deleted
            dispatch(BookingActions.deletePassport(passengerIndex, { imageUuid: flightBook.passportImage[passengerIndex].uuid }))
        } else if (flightBook.passportImage[passengerIndex]?.uuid === currentUser.passportImageUuid) {
            // Existing passport is set at account level and should be cleared
            dispatch(BookingActions.clearPassengerPassportImage(passengerIndex))
        }

        const detailsCopy = [...passengerDetails];
        detailsCopy[passengerIndex].passportImage = null
        setPassengerDetails(detailsCopy)

        updatePassportImageValidation(passengerIndex, "")
        if (hiddenFileInput.current && passengerIndex in hiddenFileInput.current) {
            hiddenFileInput.current[passengerIndex].value = ""
        }
    }

    const renderUploadedFile = (passengerIndex: any) => {
        if (flightBook.passportImage[passengerIndex]?.isLoading) {
            return <SpinnerContainer><Spinner /></SpinnerContainer>
        } else {
            return <UploadedPassportImage>
                <div>
                    <FileImageIcon />
                    {passengerDetails[passengerIndex].passportImage.name || "Image set in Profile"}
                </div>
                <ClearButtonContainer onClick={() => removeImage(passengerIndex)}><StyledClose /></ClearButtonContainer>
            </UploadedPassportImage>
        }
    }

    const displayPassportStatus = (passengerIndex: any) => {
        if (!!passportImageValidation[passengerIndex]) {
            return <PassportImageErrorIcon fill='red' />
        }
        else if (passengerDetails[passengerIndex].passportImage && !flightBook.passportImage[passengerIndex]?.isLoading) {
            if (flightBook.passportImage[passengerIndex]?.error || !flightBook.passportImage[passengerIndex]?.uuid) {
                return <PassportImageErrorIcon fill='red' />
            } else {
                return <PassportImageCheckmarContainer><Checkmark fill='#00bf0c' className='w-100 h-auto' /></PassportImageCheckmarContainer>
            }
        }
    }

    useEffect(() => {
        dispatch(BookingActions.fetchItineraryPrice({
            activeFlight: searchOptions.tripType === TripType.RoundTrip && outboundItinerary?.length ? 'inbound' : 'outbound',
            airlines: searchOptions.airlineOption ? {
                value: searchOptions.airlineOption
            } : [],
            connectingAirport: null,
            currentTripType: {
                value: searchOptions.tripType
            },
            departureDate: searchOptions.departureDate?.format('YYYY-MM-DD'),
            destination: {
                value: searchOptions.arrivalAirport?.value,
            },
            excludeNoCarryOn: {
                value: false
            },
            originIsCity: false,
            destinationIsCity: false,
            inboundItinerary: inboundItinerary,
            isRefundable: {
                value: searchOptions.isRefundable === 'true'
            },
            maxStops: {
                value: searchOptions.maxNumberOfStops,
            },
            numPax: {
                value: searchOptions.numberOfPassengers,
            },
            origin: {
                value: searchOptions.departureAirport?.value,
            },
            outboundItinerary: outboundItinerary,
            resetSearch: false,
            returnDate: searchOptions.tripType === TripType.RoundTrip ? searchOptions.returnDate?.format('YYYY-MM-DD') : searchOptions.departureDate?.format('YYYY-MM-DD'),
            ticketType: {
                value: searchOptions.ticketType
            },
            travelClass: {
                value: searchOptions.travelClass
            },
            weeksToFetch: []
        }))
    }, [dispatch]) // eslint-disable-line

    useEffect(() => {
        if (!flightBook.isLoading && isAwaitingBooking) {
            setIsAwaitingBooking(false)

            if (!flightBook.error) {
                setConfirmationStep(ConfirmationSteps.Success)
            } else {
                dispatch(updateToast({
                    message: 'There was an error booking your flight. Please review your passenger details and try again.',
                    isOpen: true,
                    isError: true,
                }))
            }
        }
    }, [flightBook.isLoading, dispatch]) // eslint-disable-line

    useEffect(() => {
        if (currentUser.passportImageUuid) {
            dispatch(BookingActions.setPassportImage(0, currentUser.passportImageUuid))
        } else {
            dispatch(BookingActions.clearPassportImages())
        }
    }, [dispatch]) // eslint-disable-line

    useEffect(() => {
        if (flightBook.successful && flightBook.priceDetail?.isAwardTicket) {
            // Cache source id not set in enum. Look at backend code for mapping.
            if (flightBook.priceDetail?.cacheSource === 5) {
                // Pricing confirms availability
                setAwardTicketAvailable(true)
                setIsLoadingLiveScrape(false)
            } else {
                setAwardTicketAvailable(false)
                setLivescrapeParams({
                    "origin": flightBook.priceDetail.itineraries[0].segments[0].departurePoint,
                    "destination": flightBook.priceDetail.itineraries[0].segments[flightBook.priceDetail.itineraries[0].segments.length - 1].arrivalPoint,
                    "departure_date": moment(flightBook.priceDetail.itineraries[0].segments[0].localDepartureTimeDate).format('YYYY-MM-DD'),
                    "return_date": searchOptions.tripType === TripType.RoundTrip ? moment(searchOptions.returnDate).format('YYYY-MM-DD') : undefined,
                    "num_pax": searchOptions.numberOfPassengers ?? 1,
                    "trip_type": searchOptions.tripType
                })
                setWebsocketRequestViaCounterToChildComponent(current => current + 1)
            }
        }
    }, [flightBook.priceDetail?.isAwardTicket]); // eslint-disable-line

    const getAwardCabinClass = (travelClass: string) => {
        switch (travelClass) {
            case TravelClass.Economy:
                return AwardTravelClass.Economy
            case TravelClass.PremiumEconomy:
                return AwardTravelClass.PremiumEconomy
            case TravelClass.Business:
                return AwardTravelClass.Business
            case TravelClass.FirstClass:
                return AwardTravelClass.FirstClass
            default:
                return false
        }
    }

    const seatExistsFilter = (seatsLeft: number) => {
        const numPax = Number(searchOptions.numberOfPassengers || 1)
        return seatsLeft >= numPax
    }

    const allSegmentsMatchFilter = (pricedFlight: any, livescrapeFlight: any) => {
        if (Object.keys(pricedFlight).length !== Object.keys(livescrapeFlight?.segments).length) {
            return false
        }
        for (let i = 0; i < Object.keys(pricedFlight).length; i++) {
            const knownSegment = pricedFlight[i]
            const livescrapeSegment = livescrapeFlight?.segments[i]
            const cabinMismatch = getAwardCabinClass(knownSegment?.cabin) !== livescrapeSegment?.cabinName
            const originMismatch = knownSegment?.origin !== livescrapeSegment?.originCode
            const destinationMismatch = knownSegment?.destination !== livescrapeSegment?.destinationCode
            const flightNumberMismatch = knownSegment?.fltNumber !== livescrapeSegment?.flightNumber
            const operatingCarrierMismatch = knownSegment?.operatingCarrierCode !== livescrapeSegment?.operatingCarrier
            if (cabinMismatch || originMismatch || destinationMismatch || flightNumberMismatch || operatingCarrierMismatch) {
                return false
            }
        }
        return true
    }

    const liveAwardExists = (segments: any[], liveAwards: any) => {
        let isSegmentInLiveAwards = false;
        if (Object.keys(liveAwards).length > 0) {
            const filteredLiveAward = Object.entries(liveAwards)
                .filter(([key, value]) => {
                    // Checks if each entry in the dictionary passes filter conditions, then returns array of [key, value] tuples
                    return allSegmentsMatchFilter(segments, (value as any)) && seatExistsFilter((value as any).seatsLeft)
                })
            isSegmentInLiveAwards = Object.keys(filteredLiveAward).length > 0
        }

        return isSegmentInLiveAwards
    }

    useEffect(() => {
        if (completedLiveScrape && Object.keys(completedLiveScrape).length === 0) {
            // If no live award is returned, we cannot determine no inventory exists.
            // Block the user from proceeding.
            setAwardTicketAvailable(false)
            setIsLoadingLiveScrape(false)
        } else if (flightBook.priceDetail?.itineraries && completedLiveScrape && Object.keys(completedLiveScrape).length > 0) {
            let isSegmentInLiveAwards = false;
            if (flightBook.priceDetail?.itineraries?.length === completedLiveScrape.length) {
                isSegmentInLiveAwards = flightBook.priceDetail?.itineraries.every(
                    (itineraries: any, index: number) => {
                        const segments = itineraries.segments;
                        const liveAwards = completedLiveScrape[index]
                        return liveAwardExists(segments, liveAwards)
                    }
                )
            }
            setIsLoadingLiveScrape(false)
            setAwardTicketAvailable(isSegmentInLiveAwards);
        }
    }, [flightBook.priceDetail?.itineraries?.[0]?.segments, completedLiveScrape]); // eslint-disable-line

    const getPassengerErrors = (passengerIndex: number) => {
        const isErrorsForPassenger = Object.keys(passengerDetailsErrors[passengerIndex]).filter(key => passengerDetailsErrors[passengerIndex][key]).length

        return isErrorsForPassenger ? <PassengerErrorsContainer>
            {Object.keys(passengerDetailsErrors[passengerIndex]).map(key => passengerDetailsErrors[passengerIndex][key] && <div key={key} className='d-flex align-items-center'>
                <ErrorIcon fill='red' />
                <div>{passengerDetailsErrors[passengerIndex][key]}</div>
            </div>)}
        </PassengerErrorsContainer> : undefined
    }

    const getStepContent = (passengerDetails: any[]) => {
        switch (confirmationStep) {
            case ConfirmationSteps.Success:
                return <StepContainer key={ConfirmationSteps.Success} {...fadeInOutMotionProps}>
                    <div className='w-100 d-flex flex-column align-items-center'>
                        <SuccessContainer>
                            <CheckmarkContainer>
                                <Checkmark fill='#00bf0c' className='w-100 h-auto' />
                            </CheckmarkContainer>
                            {outboundItinerary?.isAwardTicket ?
                                <SubText style={{ margin: 0 }}>
                                    Your ticket is currently being processed by our team. Look out for an email when your ticket has been confirmed.
                                </SubText> :
                                <SubText style={{ margin: 0 }}>
                                    Your flight to {outboundItinerary.slices.at(-1).segments.at(-1).destinationCityName} booked! <strong>{flightBook.confirmedBooking.pnr ? `(Booking code: ${flightBook.confirmedBooking.taekusId})` : ''}</strong>
                                </SubText>
                            }
                        </SuccessContainer>
                        <div className="d-flex">
                            <StyledNavLink exact to={AppPath.Rewards}>Back to Rewards</StyledNavLink>
                            <StyledNavLink exact to={`${AppPath.MyTrips}/${flightBook.confirmedBooking.uuid}`}>View your Trip</StyledNavLink>
                        </div>
                    </div>
                </StepContainer>
            case ConfirmationSteps.PayAndConfirm:
                return <StepContentContainer key={ConfirmationSteps.PayAndConfirm} {...fadeInOutMotionProps}>
                    <AnimatePresence>
                        {(flightBook.error || !!userCannotBook()) && <BookingErrorContainer
                            initial={{ opacity: 0, height: 0 }}
                            exit={{ opacity: 0, height: 0 }}
                            animate={{ opacity: 1, height: 'auto' }}
                        >
                            <ErrorIcon fill='red' />
                            <div>{flightBook.errors.length ? getBookingErrorLabel(flightBook.errors[0]) : userCannotBook()}</div>
                        </BookingErrorContainer>}
                    </AnimatePresence>
                    <PaymentDetailsCard
                        transferPartner={priceDetail.transferPartnerData}
                        priceCash={totalCash}
                        pricePoints={totalPoints}
                        bookTicket={() => { }}
                    />
                    <ConfirmationTextContainer>
                        <CostText>You are redeeming {(totalPoints).toLocaleString()} points.</CostText>
                        {!!totalCash && <SubText>{(totalCash).toLocaleString(Locale.English, USDCurrencyOptions)} will also be deducted from your {currentAccountName()}.</SubText>}
                        <SubText>To book your flight with {outboundItinerary.airlineName}, no further action is needed once you click “Book”.</SubText>
                        {priceDetail.transferPartnerData.partnerCode !== 'XX' && <SubText>Your miles will be transferred to {priceDetail?.transferPartnerData.partnerName} and used to book this ticket.</SubText>}
                    </ConfirmationTextContainer>
                </StepContentContainer>
            case ConfirmationSteps.PassengerInfo:
                return <StepContentContainer key={ConfirmationSteps.PassengerInfo} {...fadeInOutMotionProps}>
                    {priceDetail.stepUpRequired && <InformationText>{StepUpText.header}</InformationText>}
                    {passengerDetails.map((passenger, passengerIndex) => {
                        const updatePassengerDetails = generatePassengerDetailOnChangeHandler(passengerIndex)
                        const submitImage = submitImageHandler(passengerIndex)
                        const updatePhoneNumber = (value: any) => {
                            const detailsCopy = [...passengerDetails];
                            detailsCopy[passengerIndex].phone = value;
                            setPassengerDetails(detailsCopy)
                            isPassengerDetailsValid()
                        }

                        const clearAccountHolderDetails = () => {
                            const clearedDetails = [defaultPassengerDetails, ...passengerDetails.slice(1)]
                            removeImage(0)
                            setPassengerDetails(clearedDetails)
                        }

                        return <PassengerContainer key={`passenger-${passengerIndex}`}>
                            <div className='w-100 d-flex justify-content-between'>
                                <PassengerTitle>Traveler {passengerIndex + 1}</PassengerTitle>
                                {passengerIndex === 0 && <ClearButtonContainer onClick={clearAccountHolderDetails}>
                                    <StyledClose />
                                    <div>Clear account holder details</div>
                                </ClearButtonContainer>}
                            </div>
                            {showValidationErrors && getPassengerErrors(passengerIndex)}
                            <InputRow>
                                <PassengerInputContainer error={showValidationErrors && passengerDetailsErrors[passengerIndex].firstName}>
                                    <PassengerInput error={showValidationErrors && passengerDetailsErrors[passengerIndex].firstName} value={passenger.firstName || ''} onChange={updatePassengerDetails} id='firstName' />
                                    <PassengerLabel>First Name*</PassengerLabel>
                                </PassengerInputContainer>
                                <PassengerInputContainer>
                                    <PassengerInput value={passenger.middleName || ''} onChange={updatePassengerDetails} id='middleName' />
                                    <PassengerLabel>Middle Name</PassengerLabel>
                                </PassengerInputContainer>
                                <PassengerInputContainer error={showValidationErrors && passengerDetailsErrors[passengerIndex].lastName}>
                                    <PassengerInput error={showValidationErrors && passengerDetailsErrors[passengerIndex].lastName} value={passenger.lastName || ''} onChange={updatePassengerDetails} id='lastName' />
                                    <PassengerLabel>Last Name*</PassengerLabel>
                                </PassengerInputContainer>
                            </InputRow>
                            <DropdownRow>
                                <PassengerInputContainer error={showValidationErrors && passengerDetailsErrors[passengerIndex].dateOfBirth} marginRight="5%">
                                    <PassengerInput
                                        type='date'
                                        value={passenger.dateOfBirth}
                                        onChange={updatePassengerDetails}
                                        error={showValidationErrors && passengerDetailsErrors[passengerIndex].dateOfBirth}
                                        id='dateOfBirth'
                                    />
                                    <PassengerLabel>Date of Birth*</PassengerLabel>
                                </PassengerInputContainer>
                                <PassengerInputContainer error={showValidationErrors && passengerDetailsErrors[passengerIndex].gender}>
                                    <GenderSelect error={showValidationErrors && passengerDetailsErrors[passengerIndex].gender} value={passenger.gender || ''} onChange={updatePassengerDetails} id='gender'>
                                        <option value=''>Select Gender</option>
                                        <option value='M'>Male</option>
                                        <option value='F'>Female</option>
                                    </GenderSelect>
                                    <PassengerLabel>Gender*</PassengerLabel>
                                </PassengerInputContainer>
                            </DropdownRow>
                            {(passengerIndex === 0 || priceDetail.stepUpRequired) && <DropdownRow>
                                <PassengerInputContainer error={showValidationErrors && passengerDetailsErrors[passengerIndex].phone} marginRight="5%">
                                    <PhoneInputContainer error={showValidationErrors && passengerDetailsErrors[passengerIndex].phone}>
                                        <PhoneInput value={passenger.phone} onChange={updatePhoneNumber} />
                                    </PhoneInputContainer>
                                    <PassengerLabel>Phone Number*</PassengerLabel>
                                </PassengerInputContainer>
                                <PassengerInputContainer error={showValidationErrors && passengerDetailsErrors[passengerIndex].email}>
                                    <PassengerInput error={showValidationErrors && passengerDetailsErrors[passengerIndex].email} value={passenger.email || ''} onChange={updatePassengerDetails} id='email' />
                                    <PassengerLabel>Email*</PassengerLabel>
                                </PassengerInputContainer>
                            </DropdownRow>}
                            {priceDetail?.isDomesticItinerary ? <DropdownRow>
                                <PassengerInputContainer marginRight="5%">
                                    <PassengerInput value={passenger.knownTravellerNumber || ''} onChange={updatePassengerDetails} id='knownTravellerNumber' />
                                    <PassengerLabel>Known Traveler Number (KTN)</PassengerLabel>
                                </PassengerInputContainer>
                                <PassengerInputContainer>
                                    <PassengerInput value={passenger.redressNumber || ''} onChange={updatePassengerDetails} id='redressNumber' />
                                    <PassengerLabel>Redress Number</PassengerLabel>
                                </PassengerInputContainer>
                            </DropdownRow> :
                                <>
                                    <InputRow>
                                        <PassengerInputContainer>
                                            <PassengerInput value={passenger.passportNumber || ''} onChange={updatePassengerDetails} id='passportNumber' />
                                            <PassengerLabel>Passport Number</PassengerLabel>
                                        </PassengerInputContainer>
                                        <PassengerInputContainer>
                                            <PassengerInput
                                                type='date'
                                                value={passenger.passportExpDate || ''}
                                                onChange={updatePassengerDetails}
                                                min={moment().startOf('day').format('YYYY-MM-DD')}
                                                id='passportExpDate'
                                            />
                                            <PassengerLabel>Passport Expiration Date</PassengerLabel>
                                        </PassengerInputContainer>
                                        <PassengerInputContainer>
                                            <PassengerInput value={passenger.passportCountry || ''} onChange={updatePassengerDetails} id='passportCountry' />
                                            <PassengerLabel>Passport Country</PassengerLabel>
                                        </PassengerInputContainer>
                                    </InputRow>
                                    {priceDetail.stepUpRequired &&
                                        <DropdownRow>
                                            <PassportUploadContainer>
                                                <HeaderText error={showValidationErrors && passengerDetailsErrors[passengerIndex].passportImage}>Upload government-issued photo ID {displayPassportStatus(passengerIndex)}</HeaderText>
                                                {passportImageValidation[passengerIndex] && <ErrorText>{passportImageValidation[passengerIndex]}</ErrorText>}
                                                {!passengerDetails[passengerIndex].passportImage ?
                                                    <PassportButtonContainer>
                                                        <PassportUploadButton onClick={() => handleImageUploadClick(passengerIndex)}><UploadIcon />Upload ID</PassportUploadButton>
                                                        <DescriptionTextWrapper>
                                                            {ValidationRequirements.PassportImage.map((validationText: string, index: number) => {
                                                                return <div key={`passport-uploadvalidation-text-${index}`}>{validationText}</div>
                                                            })}
                                                        </DescriptionTextWrapper>
                                                    </PassportButtonContainer>
                                                    : renderUploadedFile(passengerIndex)}
                                            </PassportUploadContainer>
                                            <PassengerInput ref={el => hiddenFileInput.current[passengerIndex] = el as HTMLInputElement} style={{ display: 'none' }} type="file" onChange={submitImage} id='passportImage' />
                                        </DropdownRow>
                                    }
                                </>
                            }
                        </PassengerContainer>
                    }
                    )}
                </StepContentContainer>
            case ConfirmationSteps.ReviewItinerary:
            default:
                return <StepContentContainer key={ConfirmationSteps.ReviewItinerary} {...fadeInOutMotionProps}>
                    {outboundItinerary && <div>
                        <ItineraryTitle>Outbound Itinerary</ItineraryTitle>
                        <ItineraryReview option={outboundItinerary} />
                    </div>}
                    {inboundItinerary && <div>
                        <ItineraryTitle>Inbound Itinerary</ItineraryTitle>
                        <ItineraryReview option={inboundItinerary} />
                    </div>}
                    {priceDetail.fares.length > 0 && <UpsellContainer>
                        {priceDetail.fares.map((fare: any) =>
                            <Upsell
                                uuid={fare.uuid}
                                priceDisagreement={fare.priceDisagreement}
                                pricePointsBase={fare.pricePointsBase}
                                pricePointsTaxes={fare.pricePointsTaxes}
                                priceTotal={fare.priceTotal}
                                segments={fare.segments}
                                taxes={fare.taxes}
                                key={fare.uuid}
                            />
                        )}
                    </UpsellContainer>}
                </StepContentContainer>
        }
    }

    const isButtonDisabled = (): boolean => {
        if (flightBook.error) {
            return true;
        }

        if (flightBook.pricingError) {
            return true;
        }

        // Disable button if the user does not have sufficient cash or points
        // in his/her account.
        if (confirmationStep === ConfirmationSteps.PayAndConfirm && !!userCannotBook()) {
            return true;
        }

        // Disable button if the total cash or total points components 
        // have not been calculated yet.
        if (
            confirmationStep === ConfirmationSteps.ReviewItinerary &&
            !(totalCash || totalPoints)
        ) {
            return true;
        }

        // Disable button if award ticket final availability check is still pending,
        // or award ticket returns as not available (i.e. missing in the live scrape response).
        if (
            confirmationStep === ConfirmationSteps.PayAndConfirm &&
            flightBook.priceDetail?.isAwardTicket && (isLoadingLiveScrape || !awardTicketAvailable)
        ) {
            return true;
        }

        return false;
    };

    useEffect(() => {
        // If flight is domestic, set KTN / redressNumber for primary passenger
        if (priceDetail?.isDomesticItinerary) {
            const detailsCopy = [...passengerDetails];
            if (!!currentUser.knownTravellerNumber) {
                detailsCopy[0].knownTravellerNumber = currentUser.knownTravellerNumber;
            }
            if (!!currentUser.redressNumber) {
                detailsCopy[0].redressNumber = currentUser.redressNumber;
            }
            setPassengerDetails(detailsCopy)
        }

    }, [priceDetail]) // eslint-disable-line

    useEffect(() => {
        if (!outboundItinerary) {
            history.push('/rewards/flights')
        }
    }, []) // eslint-disable-line

    return <Container ref={pageBody}>
        <Navigation color={NavColor.Black} />
        <div className="d-flex">
            {!isMobile && <Sidebar>
                <BackToFlightsButtonContainer as={Link} to={AppPath.FlightSearch}>
                    <StyledArrowLeft fill={styles.Color.TaekusPurple} />
                    <BackButtonLabel>Back to flight search</BackButtonLabel>
                </BackToFlightsButtonContainer>
                <SidebarTitle>Your Trip Details</SidebarTitle>
                <SidebarItem>{getTravelerLabel()} ∙ {getTravelClassLabel()}</SidebarItem>
                <SidebarItem>{`${outboundItinerary?.slices[0].segments[0].departurePoint} to ${outboundItinerary?.slices[0].segments[outboundItinerary?.slices[0].segments.length - 1].arrivalPoint}`} ∙ {messages.SearchOptions.TripType[searchOptions.tripType as TripType]}</SidebarItem>
                <SidebarItem>{moment(outboundItinerary?.slices[0].segments[0].localDepartureTimeDate).format('MMM D, YYYY')} {searchOptions.tripType === TripType.RoundTrip && `- ${moment(inboundItinerary?.slices[0].segments[0].localDepartureTimeDate).format('MMM D, YYYY')}`}</SidebarItem>
                <SidebarItem />
                {flightBook.pricingIsLoading ? <div className='w-100 d-flex justify-content-center'><Spinner size={40} /></div> : !flightBook.pricingError && (outboundItinerary?.isAwardTicket ? (
                    <>
                        <SidebarItem><FlightTaxesButton onClick={toggleIsTaxDetailOpen}>Expand tax details</FlightTaxesButton></SidebarItem>
                        <SidebarItem>Flight: {totalPoints.toLocaleString()} points</SidebarItem>
                        <SidebarItem>Fees & Taxes: {totalCash.toLocaleString(Locale.English, USDCurrencyOptions)}</SidebarItem>
                        <SidebarItem />
                        <SidebarItem>
                            <strong>
                                <div>Total cost:</div>
                                <div>
                                    {(totalPoints).toLocaleString()} pts. + {(totalCash || 0).toLocaleString(Locale.English, USDCurrencyOptions)}
                                </div>
                            </strong>
                        </SidebarItem>
                    </>
                ) : (
                    <>
                        {(totalPoints || totalCash) &&
                            <>
                                <SidebarItem><FlightTaxesButton onClick={toggleIsTaxDetailOpen}>Expand tax details</FlightTaxesButton></SidebarItem>
                                <SidebarItem>
                                    <div>Flight:</div>
                                    <MiniText>
                                        {(totalPoints).toLocaleString()} points {!!totalCash && " + $" + (totalCash).toLocaleString()}
                                    </MiniText>
                                </SidebarItem>
                                <SidebarItem />
                                <SidebarItem>
                                    <strong>
                                        <div>Total cost:</div>
                                        <MiniText>{((totalPoints)).toLocaleString()} pts {!!totalCash && " + $" + (totalCash).toLocaleString()}</MiniText>
                                    </strong>
                                </SidebarItem>
                            </>
                        }
                    </>
                ))}
            </Sidebar>}
            <PageContentContainer>
                <ProgressBarContainer>
                    <StepContainer>
                        <ProgressBubbleContainer>
                            <StepBubble isCompleted />
                            <ProgressBarLabel>Review Itinerary</ProgressBarLabel>
                        </ProgressBubbleContainer>
                    </StepContainer>
                    <StepContainer>
                        <ProgressBubbleContainer>
                            <StepBubble isCompleted={confirmationStep > 0} />
                            <ProgressBarLabel>Passenger Info</ProgressBarLabel>
                        </ProgressBubbleContainer>
                    </StepContainer>
                    <StepContainer>
                        <ProgressBubbleContainer>
                            <StepBubble isCompleted={confirmationStep > 1} />
                            <ProgressBarLabel>Pay & Confirm</ProgressBarLabel>
                        </ProgressBubbleContainer>
                    </StepContainer>
                </ProgressBarContainer>
                {confirmationStep !== ConfirmationSteps.Success && <PriceModalContainer>
                    <PriceModal
                        liveScrapeIsLoading={isLoadingLiveScrape}
                        awardTicketAvailable={awardTicketAvailable}
                    />
                </PriceModalContainer>}
                <AnimatePresence mode='wait'>
                    {getStepContent(passengerDetails)}
                </AnimatePresence>
                {confirmationStep !== ConfirmationSteps.Success && <ButtonRowContainer>
                    <AnimatePresence mode='wait'>
                        {flightBook.isLoading ? <SpinnerContainer><Spinner /></SpinnerContainer> : <>
                            <AnimatePresence mode='wait'>
                                {confirmationStep > 0 && <BackButtonContainer {...fadeInOutMotionProps}>
                                    <Button
                                        onClick={decrementConfirmationStep}
                                        buttonType={ButtonType.SecondaryPurple}
                                        size={ButtonSize.Wide}
                                        label="Back"
                                    />
                                </BackButtonContainer>}
                            </AnimatePresence>
                            <Button
                                size={ButtonSize.Wide}
                                onClick={incrementConfirmationStep}
                                disabled={isButtonDisabled()}
                                buttonType={ButtonType.Purple}
                                label={confirmationStep === ConfirmationSteps.PayAndConfirm ? 'Book' : 'Next'}
                            />
                        </>}
                    </AnimatePresence>
                </ButtonRowContainer>}
            </PageContentContainer>
        </div>
        {isTaxDetailOpen && <FlightTaxesModal taxes={priceDetail?.taxes} toggleIsOpen={toggleIsTaxDetailOpen}></FlightTaxesModal>}
        <FinalAvailabilityWs
            setIsLoading={setIsLoadingLiveScrape}
            setResponse={setCompletedLiveScrape}
            websocketRequestViaCounter={websocketRequestViaCounterToChildComponent}
            params={livescrapeParams}
            requestIdToParams={requestIdToParams}
        />
    </Container>
}



const HeaderText = styled.div<PassengerInputProps>`
    color: ${props => props.error ? 'red' : styles.Color.Black};
    padding-top: ${styles.Spacing.XS};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 20px;
    margin-bottom: 6px;
    display: flex;
`
const DescriptionTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: ${styles.Spacing.M};
    margin-right: ${styles.Spacing.M};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: ${styles.Font.Size.Small};
`
const ErrorText = styled.div`
    padding: ${styles.Spacing.XS};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: ${styles.Font.Size.Small};
    margin-bottom: 6px;
    display: flex;
    color: red;
`

const InformationText = styled.div`
    font-size: ${styles.Font.Size.Small};
    padding: 20px;
    padding-bottom: 50px;
`

const UpsellContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
`

const StyledClose = styled(Close)`
    width: 24px;
    height: 24px;
`

const FileImageIcon = styled(FaRegFileImage)`
    margin-bottom: 4px;
    margin-right: 6px;
    height: ${styles.Spacing.S};
    width: auto;
`

const ErrorIcon = styled(FaRegTimesCircle)`
    margin-right: ${styles.Spacing.XS};
`

const PassportImageErrorIcon = styled(FaRegTimesCircle)`
    margin-left: 10px;
    margin-top: 3px;
`

const UploadIcon = styled(FaUpload)`
    margin-right: 10px;
    margin-bottom: 4px;
`

const BookingErrorContainer = styled(motion.div)`
    display: flex;
    align-items: center;
    border-left: 2px solid ${styles.Color.TaekusPurple};
    padding-left: ${styles.Spacing.S};
    height: ${styles.Spacing.M};
    margin-bottom: ${styles.Spacing.S};
    font-family: ${styles.Font.Family.MonumentGrotesk};
`

const StyledArrowLeft = styled(ArrowLeftIcon)`
    width: ${styles.Spacing.S};
`

const PassengerErrorsContainer = styled.div`
    width: 100%;
    border-left: 2px solid red;
    min-height: ${styles.Spacing.M};
    transition: all 0.2s ease-in;
    margin-bottom: ${styles.Spacing.S};
    padding: ${styles.Spacing.XS} ${styles.Spacing.S};
    overflow: hidden;
`

const BackButtonLabel = styled.div`
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-weight: ${styles.Font.Weight[400]};
    margin-left: ${styles.Spacing.XS};
    color: ${styles.Color.NearBlack};
    font-style: normal;
    font-size: ${styles.Font.Size.Small};
    line-height: 140%;
    /* identical to box height, or 20px */
    letter-spacing: 0.02em;
`

const PhoneInputContainer = styled.div<PassengerInputProps>`
    border-bottom: 1px solid ${props => props.error ? 'red' : styles.Color.Grey};
    padding-bottom: 7px;
`

const SuccessContainer = styled.div`
    display: flex;
    align-items: center;
    height: min-content;
`

const CheckmarkContainer = styled.div`
    width: ${styles.Spacing.S};
    height: ${styles.Spacing.S};
    margin-right: ${styles.Spacing.S};
    display: flex;
    align-items: center;
`

const PassportImageCheckmarContainer = styled.div`
    width: ${styles.Spacing.S};
    height: ${styles.Spacing.S};
    margin-left: 10px;
`

const StyledNavLink = styled(NavLink)`
    color: white;
    width: 200px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: ${styles.Spacing.M} ${styles.Spacing.XS} 0;
    background-color: ${styles.Color.TaekusPurple};
    :hover {
        color: white;
        text-decoration: none;
    }
`

const SpinnerContainer = styled.div`
    width: 24px;
    height: 24px;
    margin: ${styles.Spacing.S} ${styles.Spacing.M};
    display: flex;
    align-items: center;
    justify-content: center;
`

const ClearButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    white-space: nowrap;
    &:hover {
        text-decoration: underline;
    }
`

const PassengerContainer = styled.div`
    padding: 0 ${styles.Spacing.S};
`

const SidebarTitle = styled.strong`
    font-size: 18px;
`

const SidebarItem = styled.div`
    min-height: ${styles.Spacing.S};
    margin-bottom: ${styles.Spacing.XS};
`

const ProgressBarLabel = styled.div`
    white-space: nowrap;
`

const Sidebar = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: ${styles.Spacing.XS};
    min-width: ${SIDEBAR_WIDTH}px;
    margin-left: ${styles.Spacing.M};
    margin-right: ${styles.Spacing.M};
    margin-top: 100px;
    font-family: ${styles.Font.Family.MonumentGrotesk};
`

const BackToFlightsButtonContainer = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    border-bottom: 1px solid ${styles.Color.GreyText};
    height: 24px;
    padding-bottom: 10px;
    margin-bottom: 10px;
`

const BackButtonContainer = styled(motion.div)`
    ${styles.MediaQueries.Mobile} {
        margin-bottom: ${styles.Spacing.XS};
    }
    ${styles.MediaQueries.Desktop} {
        margin-right: ${styles.Spacing.M};
    }
`

const InputRow = styled.div`
    ${styles.MediaQueries.Desktop} {
        margin-bottom: 45px;
        display: flex;
        justify-content: space-between;
    }
`

const DropdownRow = styled.div`
    width: 100%;
    ${styles.MediaQueries.Desktop} {
        margin-bottom: 45px;
        display: flex;
    }
`

const CostText = styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: ${styles.Font.Size.Medium};
    line-height: 138%;
    letter-spacing: 0.02em;
    color: #0E0E0E;
`

const SubText = styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    margin-top: 10px;
    line-height: 138%;
    letter-spacing: 0.02em;
    color: #0E0E0E;
`

const MiniText = styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    margin-left: 10px;
    line-height: 138%;
    letter-spacing: 0.02em;
    color: #0E0E0E;
`

type PassengerInputContainerProps = {
    marginRight?: string,
    error?: boolean,
}

const PassengerInputContainer = styled.div<PassengerInputContainerProps>`
    overflow: hidden;
    ${props => props.marginRight && `margin-right: ${props.marginRight};`}
    color: ${props => props.error ? 'red' : styles.Color.NearBlack};
    ${styles.MediaQueries.Mobile} {
        width: 100%;
        margin-top: ${styles.Spacing.XS};
    }
    ${styles.MediaQueries.Desktop} {
        width: 30%;
    }
    ${styles.Animation.transitionStyles}
`

const PassengerTitle = styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 138%;
    letter-spacing: 0.02em;
    color: ${styles.Color.NearBlack};
    margin-bottom: 27px;
    border-bottom: 1px solid ${styles.Color.TaekusPurple};
`

const PassengerLabel = styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: ${styles.Font.Size.Small};
    line-height: 140%;
    letter-spacing: 0.02em;
    margin-top: 4px;
    opacity: 0.5;
`

type PassengerInputProps = {
    error?: boolean;
}

const PassengerInput = styled.input<PassengerInputProps>`
    background-color: rgba(0,0,0,0);
    padding: 1px 2px 7px;
    font-size: 20px;
    line-height: 140%;
    height: 38px;
    letter-spacing: 0.02em;
    width: 100%;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid ${props => props.error ? 'red' : styles.Color.Grey};
    color: ${props => props.error ? 'red' : styles.Color.Black};
    outline: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    &::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
    }
    ${styles.Animation.transitionStyles}
`

const GenderSelect = styled.select<PassengerInputProps>`
    background-color: rgba(0,0,0,0);
    padding: 1px 2px 7px;
    font-size: 20px;
    line-height: 140%;
    letter-spacing: 0.02em;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    width: 100%;
    height: 38px;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid ${props => props.error ? 'red' : styles.Color.Grey};
    color: ${props => props.error ? 'red' : styles.Color.Black};
    outline: 0;
`

const ItineraryTitle = styled.div`
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 24px;
    width: 100%;
    border-bottom: 2px solid ${styles.Color.Grey};
`

const ProgressBarContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: ${styles.Spacing.M} 0;
    ${styles.MediaQueries.Mobile} {
        width: 100%;
    }
    ${styles.MediaQueries.Desktop} {
        width: 75%;
    }
`

const ProgressBubbleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    ${styles.MediaQueries.Mobile} {
        font-size: ${styles.Font.Size.Small};
    }
`

type StepBubbleProps = {
    isCompleted?: boolean
}

const StepBubble = styled.div<StepBubbleProps>`
    width: ${styles.Spacing.S};
    height: ${styles.Spacing.S};
    border: 2px solid ${styles.Color.TaekusPurple};
    background-color: ${props => props.isCompleted ? styles.Color.TaekusPurple : styles.Color.White};
    border-radius: 50%;
    z-index: 2;
    ${styles.Animation.transitionStyles}
`

const StepContainer = styled.div`
    display: flex;
    justify-content: center;
    flex: 1;
    width: 100%;
    position: relative;
    padding: 0 ${styles.Spacing.XS};
    &:not(&:last-child) {
        &::after {
            content: "";
            position: absolute;
            height: 1px;
            width: 100%;
            background-color: ${styles.Color.TaekusPurple};
            top: 10px;
            left: 50%;
            z-index: 1;
        }
    }
`

const ConfirmationTextContainer = styled.div`
    padding: 0 ${styles.Spacing.S};
    margin: ${styles.Spacing.S};
`

const StepContentContainer = styled(motion.div)`
    ${styles.MediaQueries.Mobile} {
        width: 100%;
    }
    ${styles.MediaQueries.Desktop} {
        width: 90%;
    }
`

const ButtonRowContainer = styled.div`
    margin: ${styles.Spacing.M} 0;
    ${styles.MediaQueries.Mobile} {
        width: 100%;
        padding: 0 ${styles.Spacing.S};
    }
    ${styles.MediaQueries.Desktop} {
        width: 90%;
        display: flex;
        justify-content: end;
    }
    ${styles.Animation.transitionStyles}
`

const PageContentContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    ${styles.Animation.transitionStyles}
`

const PriceModalContainer = styled.div`
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: left;
    ${styles.Animation.transitionStyles}
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    ${styles.Scrollbar.defaultScrollbarStyles}
`

const PassportUploadButton = styled.button`
    height: ${styles.Spacing.M};
    width: 150px;
    border: 0;
    flex: 1;
    background-color: ${styles.Color.TaekusPurple};
    color: ${styles.Color.White};
    padding: 5px;
`

const UploadedPassportImage = styled.div`
    display: flex;
    width: 420px;
    padding: 10px;
    border: 1px solid black;
    justify-content: space-between;
`

const PassportButtonContainer = styled.div`
    display: flex;
    width: 420px;
`
const PassportUploadContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const FlightTaxesButton = styled.button`
    padding: 0;
	background-color: transparent;
	background-repeat: no-repeat;
	border: none;
    &:hover {
        border-bottom: 1px solid ${styles.Color.Grey};
    }
`

export default FlightConfirmation
