export enum StepDirection {
    Up,
    Down,
    Left,
    Right
}

export enum HousingType {
    Own='OWN',
    Rent='RENT'
}

export enum ProductType {
    Credit='CREDIT',
    Debit='PREPAID'
}

export enum ProductCategory {
    Business='BUSINESS',
    Personal='CONSUMER'
}

export enum CompanyStructure {
    LLC='LLC',
    cCorp='C_CORP',
    sCorp='S_CORP',
    SoleProp='SOLE_PROPRIETORSHIP',
}

export type Application = {
    // Prove
    isProveVerified?: boolean,
    
    // Product
    inviteCode?: string,
    productCode?: string,

    // User
    username?: string,
    password?: string,
    firstName?: string,
    lastName?: string,
    birthDate?: string,
    googlePlacesId?: string,
    addressCity?: string,
    addressState?: string,
    addressStreet?: string,
    addressZip?: string,
    addressLineTwo?: string,
    last4SSN?: string,
    ssn?: string,
    phoneNumber?: string,
    isPhoneVerified?: boolean,
    email?: string,
    totalAnnualIncome?: string,
    residenceType?: HousingType,
    monthlyHousingPayment?: string,

    // Business info
    companyStructure?: CompanyStructure,
    companyName?: string,
    bizHasDBA?: boolean,
    dbaName?: string,
    bizHasEIN?: boolean,
    naicsCategory?: string,
    taxIdentificationNumber?: string,
    formationDate?: string,
    businessPercentOwnership?: number,
    businessAddressStreet?: string,
    businessAddressCity?: string,
    businessAddressState?: string,
    businessAddressZip?: string,
    businessAddressLineTwo?: string,
}