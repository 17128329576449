// API errors
export enum FetchLeadError {
    InvalidPhone,
    InvalidToken,
    Throttled,
}

// Application data errors
export enum StringError {
    TooShort,
    TooLong,
}

export enum SSNError {
    InvalidLength,
}

export enum AddressError {
    Required
}

export enum EmailError {
    Required,
    Format
}

export enum IncomeError {
    Required
}

export enum HousingError {
    Required,
    PaymentTooLow,
    PaymentTooHigh,
}

export enum BusinessStructureError {
    Required
}

export enum BusinessIndustryError {
    Required
}

export enum EINError {
    Required,
    Format
}

export enum FormationDateError {
    Required,
    FutureDate,
    DatePastMax
}

export enum PercentOwnershipError {
    Required,
    TooSmall,
    TooLarge,
}