import { motion } from "framer-motion"
import { styled } from "styled-components"

import { StepDirection } from "components/signup/types"
import styles from "styles/styles"

export const getStepContainerMotionProps = (direction: StepDirection) => {
    console.log(direction)
    const offset = 100
    const xOffset = direction === StepDirection.Left ? -offset : (direction === StepDirection.Right ? offset : 0)
    const yOffset = (direction === StepDirection.Down ? -offset : (direction === StepDirection.Up ? offset : 0)) / 2

    return {
        initial: { opacity: 0, x: xOffset, y: yOffset },
        exit: { opacity: 0, x: -xOffset, y: -yOffset },
        animate: { opacity: 1, x: 0, y: 0},
        transition: { type: 'Tween', duration: 0.4 },
    }
}

const StepContainer = styled(motion.div)`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 632px;
    ${styles.MediaQueries.Mobile} {
        padding-top: ${80 + 56}px;
        width: calc(100% - 32px);
        max-width: calc(100% - 32px);
        padding-bottom: 20px;
    }
    ${styles.Scrollbar.defaultScrollbarStyles}
`

export default StepContainer