import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import styles from 'styles/styles';
import { ReactComponent as TaekusIcon } from "assets/svg/TaekusIcon.svg";
import Button from '../components/Button';
import { motion } from 'framer-motion';
import { fadeInOutMotionProps } from 'styles/motionConstants';

// Styled components for game elements
const GameContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f0f0;
  overflow: hidden;
`;

const GameArea = styled.div`
  position: relative;
  width: 100%;
  max-width: 800px;
  height: 200px;
  border: 2px solid #333;
  overflow: hidden;
  background-color: ${styles.Color.TaekusGrey4};
`;

const Dino = styled.div<{ position: number }>`
  position: absolute;
  bottom: ${(props) => props.position}px;
  left: 50px;
  width: 40px;
  height: 40px;
  background-color: ${styles.Color.White};
  border: 1px solid ${styles.Color.TaekusPurple};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BonusPoint = styled.div<{ position: number }>`
    position: absolute;
    bottom: 100px;
    left: ${(props) => props.position}px;
    width: 20px;
    height: 20px;
    background-color: ${styles.Color.TaekusPurple};
    border-radius: 50%; 
`;

const Cactus = styled.div<{ position: number }>`
  position: absolute;
  bottom: 0;
  left: ${(props) => props.position}px;
  width: 20px;
  height: 50px;
  background-color: ${styles.Color.TaekusPurple};
`;

const ScoreDisplay = styled.div`
  margin-top: 20px;
  font-size: 24px;
  font-weight: bold;
  font-family: ${styles.Font.Family.MonumentGrotesk};
  color: #333;
`;

const ComboDisplay = styled.div<{ combo: number }>`
  margin-top: 4px;
  margin-bottom: 20px;
  font-size: 18px;
  font-family: ${styles.Font.Family.MonumentGrotesk};
  color: ${styles.Color.TaekusGrey2};
  opacity: ${props => props.combo >= 50 ? 1 : 0};
  ${styles.Animation.transitionStyles}
`;

const DinoGame: React.FC = () => {
  const [dinoPosition, setDinoPosition] = useState(0);
  const [cactusPosition, setCactusPosition] = useState(800);
  const [bonusPosition, setBonusPosition] = useState(1200);
  const [isJumping, setIsJumping] = useState(false);
  const [score, setScore] = useState(0);
  const [isGameOver, setIsGameOver] = useState(false);
  const [shield, setShield] = useState(false)
  
  const jumpVelocityRef = useRef(0);
  const isJumpingRef = useRef(isJumping); // Ref to track the current isJumping value
  const isGameOverRef = useRef(isGameOver)
  const shieldRef = useRef(shield)
  const invincibilityRef = useRef(0)
  const gravity = 0.5;

  // Update the ref whenever isJumping changes
  useEffect(() => {
    isJumpingRef.current = isJumping;
  }, [isJumping]);

  useEffect(() => {
    isGameOverRef.current = isGameOver;
  }, [isGameOver]);

  useEffect(() => {
    shieldRef.current = shield;
    if (shield === false) {
        invincibilityRef.current = 20
    }
  }, [shield]);

  const handleJump = () => {
    if (isGameOverRef.current) {
        restartGame()
        return;
    }

    if (!isJumpingRef.current) {
      setIsJumping(true);
      jumpVelocityRef.current = 10;
    }
  };

  const gameLoop = () => {
    // Handle dino jump mechanics
    setDinoPosition((prevPosition) => {
      if (isJumpingRef.current) {
        const newPosition = prevPosition + jumpVelocityRef.current;
        jumpVelocityRef.current -= gravity;

        if (newPosition <= 0) {
          setIsJumping(false);
          jumpVelocityRef.current = 0;
          return 0;
        }
        return newPosition;
      }
      return prevPosition;
    });

    // Move cactus
    setCactusPosition((prevPosition) => {
      if (prevPosition < -20) {
        setScore((score) => score + 1);
        return 600 + (Math.random() + 300);
      }
      return prevPosition - 10;
    });

    // Move bonus point
    if (score >=  10) {
        setBonusPosition((prevPosition) => {
            if (prevPosition < -20) {
            //   setScore((score) => score + 1);
            return 1200 + (Math.random() + 300);
            }
            return prevPosition - 8 ;
        });
    }

    // Check for collision
    if (
        bonusPosition < 110 &&
        bonusPosition > 30 &&
        dinoPosition > 80 
    ) {
        setShield(true)
        setBonusPosition(1200 + (Math.random() + 300))
    }

    if (
      cactusPosition < 110 &&
      cactusPosition > 30 &&
      dinoPosition < 58 &&
      invincibilityRef.current !== 0
    ) {
        if (shieldRef.current ) {
            setShield(false)
        } else {
            setIsGameOver(true);
        }
    }
  };

  useEffect(() => {
    let animationFrameId: number;
    
    const runGameLoop = () => {
      if (!isGameOver) {
        gameLoop();
        animationFrameId = requestAnimationFrame(runGameLoop);
      }
    };

    if (!isGameOver) {
      animationFrameId = requestAnimationFrame(runGameLoop);
    }

    return () => cancelAnimationFrame(animationFrameId);
  }, [isGameOver, cactusPosition, dinoPosition, isJumping]); // eslint-disable-line

  const restartGame = () => {
    setDinoPosition(0);
    setCactusPosition(800);
    setScore(0);
    setIsGameOver(false);
    setIsJumping(false);
    setShield(false);
    jumpVelocityRef.current = 0;
  };

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.code === 'Space') handleJump();
    };
    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, []); // eslint-disable-line

  return (
    <GameContainer {...fadeInOutMotionProps}>
      <GameArea>
        <Dino position={dinoPosition}>
            <TaekusIcon style={{ fill: styles.Color.TaekusPurple, height: '24px', width: 'auto' }}/>
        </Dino>
        <Cactus position={cactusPosition} />
        <BonusPoint position={bonusPosition}/>
      </GameArea>
      <ScoreDisplay>{score} pts.</ScoreDisplay>
      <div style={{visibility: shield ? 'visible' : 'hidden'}}>Shield Active</div>
      <ComboDisplay combo={score}>{score}x combo</ComboDisplay>
      {/* <button >Restart</button> */}
      <Button style={{visibility: isGameOver ? 'visible' : 'hidden'}} onClick={restartGame}>Restart</Button>
    </GameContainer>
  );
};

export default DinoGame;