import React, { useEffect, useState } from "react"

import { styled } from "styled-components"

import ArrowButton, { ArrowDirection } from "components/signup/components/ArrowButton";
import Button from "components/signup/components/Button";
import Input from "components/signup/components/Input";
import StepContainer, { getStepContainerMotionProps } from "components/signup/StepContainer";
import { Application, StepDirection } from "components/signup/types";

import styles from "styles/styles";
import SSNInput from "../components/SSNInput";
import { geocodeByPlaceId } from "react-google-places-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { updateApplication, updateLead, updateStep } from "../signupSlice";
import { useIsFirstRender } from "hooks/useIsFirstRender";
import { parseGoogleAddress } from "components/common/util";
import Checkbox from "../components/Checkbox";
import AddressInput from "../components/AddressInput";
import { SignupStep } from "../constants";
import { validateString, validateSSN } from "../validators";

type UserDetailsProps = {
}

const UserDetails = (props: UserDetailsProps) => {
    const dispatch = useDispatch();
    const isFirstRender = useIsFirstRender()
    
    const leadUuid = useSelector((state: any) => state.signup.leadUuid)
    const application = useSelector((state: any) => state.signup.application)
    const isLoading = useSelector((state: any) => state.signup.isLoading)
    const stepDirection = useSelector((state: any) => state.signup.direction)

    const [firstName, setFirstName] = useState(application.firstName || '')
    const [lastName, setLastName] = useState(application.lastName || '')
    const [ssn, setSSN] = useState(application.ssn || application.ssnLastFour || '')
    const [showLastFour, setShowLastFour] = useState(!application.ssn && application.ssnLastFour)
    const [isSsaAgree, setIsSsaAgreed] = useState(false)
    const [address, setAddress] = useState<any>(application.addressStreet ? {
        label: `${application.addressStreet}, ${application.addressCity}, ${application.addressState}`,
        value: undefined,
    } : undefined)
    const [addressObj, setAddressObj] = useState<any>({
        street: application.addressStreet || '',
        city: application.addressCity || '',
        state: application.addressState || '',
        zip: application.addressZip || '',
    })
    const [addressLine2, setAddressLine2] = useState(application.addressLineTwo || '')
    

    const isFirstNameInvalid = validateString(firstName)
    const isLastNameInvalid = validateString(lastName)
    const isSsnInvalid = !showLastFour && validateSSN(ssn.length)
    const isAddressInvalid = address === undefined
    const isSubmitDisabled = !!(isFirstNameInvalid || isLastNameInvalid || isSsnInvalid || isAddressInvalid || !isSsaAgree)

    const handleSubmit = async (ev: React.FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        
        await geocodeByPlaceId(address?.value?.place_id)
            .then((gAddress) => {
                const parsedAddress = parseGoogleAddress(gAddress)

                setAddressObj(parsedAddress)
                dispatch(updateLead({
                    leadUuid,
                    application: {
                        // ...application,
                        firstName,
                        lastName,
                        addressStreet: parsedAddress.street,
                        addressCity: parsedAddress.city,
                        addressState: parsedAddress.state,
                        addressZip: parsedAddress.zip,
                        addressLineTwo: addressLine2,
                        ssn: showLastFour ? undefined : ssn,
                    }
                }))
            })
            .catch((e) => {
                // Geocode fails (probably due to address being autofilled from Redux)
                // Use prefill Redux values
                dispatch(updateLead({
                    leadUuid,
                    application: {
                        // ...application,
                        firstName,
                        lastName,
                        addressStreet: addressObj?.street,
                        addressCity: addressObj?.city,
                        addressState: addressObj?.state,
                        addressZip: addressObj?.zip,
                        addressLineTwo: addressLine2,
                        ssn: showLastFour ? undefined : ssn,
                    }
                }))
            })
    }

    // onChange handlers

    const handleFirstNameChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        if (ev.target.value.length < 40) {
            setFirstName(ev.target.value)
        }
    }

    const handleLastNameChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        if (ev.target.value.length < 40) {
            setLastName(ev.target.value)
        }
    }

    const handleAddressChange = (value: any) => {
        setAddress(value)
    }

    const handleAddress2Change = (ev: React.ChangeEvent<HTMLInputElement>) => {
        if (ev.target.value.length < 40) {
            setAddressLine2(ev.target.value)
        }
    }

    const handleSsnChange = (value: string) => {
        if (showLastFour) {
            setShowLastFour(false)
            setSSN('')
        } else {
            setSSN(value)
        }
    }

    useEffect(() => {
        if (!isFirstRender && !isLoading && !isSubmitDisabled) {
            dispatch(updateApplication({
                firstName,
                lastName,
                ssn: showLastFour ? undefined : ssn,
                addressStreet: addressObj?.street,
                addressCity: addressObj?.city,
                addressState: addressObj?.state,
                addressZip: addressObj?.zip,
                addressLineTwo: addressLine2,
            }))
            dispatch(updateStep({
                step: SignupStep.Email,
                direction: StepDirection.Right,
            }))
        }
    }, [isLoading]) // eslint-disable-line

    return <StepContainer
        {...getStepContainerMotionProps(stepDirection)}
        key='UserDetails'
    >
        <form className="d-flex flex-column align-items-center" style={{maxWidth: '100%'}} onSubmit={handleSubmit}>
            <Title>Sorry, we couldn’t find your info—</Title>
            <Text>But we’d love to get to know you. Tell us about yourself!</Text>
            <InputList>
                <InputContainer>
                    <Input onChange={handleFirstNameChange}  value={firstName} label="First Name"/>
                </InputContainer>
                <InputContainer>
                    <Input onChange={handleLastNameChange}  value={lastName} label="Last Name"/>
                </InputContainer>
                <InputContainer>
                    <SSNInput lastFour={showLastFour} value={ssn} onChange={handleSsnChange}/>
                </InputContainer>
                <InputContainer>
                    <AddressInput placeholder="Address Line 1" value={address} onChange={handleAddressChange} />
                </InputContainer>
                <InputContainer>
                    <Input autocomplete="new-password" onChange={handleAddress2Change}  value={addressLine2} label="Address Line 2*"/>
                </InputContainer>
            </InputList>
            <CheckboxContainer htmlFor='agree'>
                {/* <input checked={isSsaAgree} onChange={() => { setIsSsaAgreed(!isSsaAgree) }} style={{marginRight: '16px'}} type='checkbox' id='agree'/> */}
                <div style={{marginRight: '16px'}}>
                    <Checkbox checked={isSsaAgree} onClick={(ev) => { setIsSsaAgreed(!isSsaAgree); ev?.preventDefault(); }} id='agree' />
                </div>
                <div>
                    I authorize the Social Security Administration (SSA) to verify and disclose to Stearns Bank, NA.. through Socure, LLC, their service provider for the purpose of this transaction whether the name, Social Security Number (SSN) and date of birth I have submitted matches information in SSA records. My consent is for a one-time validation within the next 90 days.
                </div>
            </CheckboxContainer>
            <div style={{marginTop: '48px', display: 'flex'}}>
                <Button isLoading={isLoading} disabled={isSubmitDisabled} type="submit">Next</Button>
            </div>
        </form>
    </StepContainer>
}

const CheckboxContainer = styled.label`
    margin-top: 48px;
    display: flex;
    align-items: start;
    width: 632px;
    border-radius: 4px;
    border: 1px solid ${styles.Color.TaekusGrey5};
    background: ${styles.Color.White};
    padding: 16px;
    color: ${styles.Color.TaekusGrey3};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    cursor: pointer;
    user-select: none;
    &:hover {
        box-shadow: 0px 0px 16px 0px rgba(124, 61, 118, 0.15);
        border: 1px solid transparent;
    }
    ${styles.MediaQueries.Mobile} {
        width: 100%;
    }
    ${styles.Animation.transitionStyles}
`

const InputList = styled.div`
    width: 416px;
    margin-top: 48px;
    ${styles.MediaQueries.Mobile} {
        width: 100%;
    }
`

const InputContainer = styled.div`
    &:not(&:last-child) {
        margin-bottom: 12px;
    }
`

const Text = styled.div`
    color: ${styles.Color.TaekusGrey2};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%; /* 22.08px */
    letter-spacing: 0.32px;
    ${styles.MediaQueries.Mobile} {
        font-size: 14px;
    }
`

const Title = styled.div`
    color: ${styles.Color.TaekusGrey1};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 49.6px */
    letter-spacing: 0.4px;
    margin-bottom: 16px;
    ${styles.MediaQueries.Mobile} {
        font-size: 24px;
    }
`

export default UserDetails