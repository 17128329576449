import React from "react"

import { styled } from "styled-components"

import StepContainer, { getStepContainerMotionProps } from "components/signup/StepContainer";
import { StepDirection } from "components/signup/types";

import styles from "styles/styles";
import { useSelector } from "react-redux";

type WelcomeProps = {
}

const Welcome = (props: WelcomeProps) => {
    const stepDirection = useSelector((state: any) => state.signup.direction)

    return <StepContainer
        {...getStepContainerMotionProps(stepDirection)}
        key='Welcome'
    >
        <div className="d-flex flex-column align-items-center">
            <Title>Welcome to Taekus!</Title>
            <Text>To expedite your application review, complete the steps below.</Text>
        </div>
    </StepContainer>
}

const Text = styled.div`
    color: ${styles.Color.TaekusGrey2};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%; /* 22.08px */
    letter-spacing: 0.32px;
    margin-bottom: 16px;
`

const Title = styled.div`
    color: ${styles.Color.TaekusGrey1};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 49.6px */
    letter-spacing: 0.4px;
    margin-bottom: 32px;
`

export default Welcome