import React from "react"

import { styled } from "styled-components"

import { StepDirection } from "components/signup/types";

import StepContainer, { getStepContainerMotionProps } from "components/signup/StepContainer";

import styles from "styles/styles";
import { useSelector } from "react-redux";

type DeniedProps = {
}

const Denied = (props: DeniedProps) => {
    const stepDirection = useSelector((state: any) => state.signup.direction)

    return <StepContainer
        {...getStepContainerMotionProps(stepDirection)}
        key='Denied'
    >
        <div className="d-flex flex-column align-items-center">
            <Title>We are unable to pre-approve you.</Title>
            <Text>Unfortunately, we are unable to pre-approve your application at this time. This will not harm your credit. We will provide you with more detailed information via email in the next few days explaining the reasons for this decision.</Text>
        </div>
    </StepContainer>
}

const Text = styled.div`
    color: ${styles.Color.TaekusGrey2};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%; /* 22.08px */
    letter-spacing: 0.32px;
    margin-bottom: 16px;
    ${styles.MediaQueries.Mobile} {
        font-size: 14px;
    }
`

const Title = styled.div`
    color: ${styles.Color.TaekusGrey1};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 49.6px */
    letter-spacing: 0.4px;
    margin-bottom: 32px;
    ${styles.MediaQueries.Mobile} {
        font-size: 24px;
    }
`

export default Denied