/**
 * Get the value for a given key in address_components
 * https://stackoverflow.com/a/40144130/2698266
 *
 * @param {Array} components address_components returned from Google maps autocomplete
 * @param type key for desired address component
 * @returns {String} value, if found, for given type (key)
 */
export const extractFromAddress = (components: any, type: any, use_short = false) => {
  return (
    components
      .filter((component: any) => component.types.indexOf(type) !== -1)
      .map((item: any) => (use_short ? item.short_name : item.long_name))
      .pop() || null
  )
}

export const parseGoogleAddress = (gAddress: any) => {
  const components = gAddress[0].address_components;
  let parsedAddress = {} as any

  parsedAddress.street = extractFromAddress(components, 'street_number') + ' ' + extractFromAddress(components, 'route')
  parsedAddress.state = extractFromAddress(components, 'administrative_area_level_1', true)
  parsedAddress.zip = extractFromAddress(components, 'postal_code')
  if (extractFromAddress(components, 'locality') !== null) {
      parsedAddress.city = extractFromAddress(components, 'locality')
  } else if (extractFromAddress(components, 'sublocality') !== null) {
      parsedAddress.city = extractFromAddress(components, 'sublocality')
  } else if (extractFromAddress(components, 'administrative_area_level_3') !== null) {
      parsedAddress.city = extractFromAddress(components, 'administrative_area_level_3')
  } else {
      parsedAddress.city = ''
  }

  return parsedAddress
}
