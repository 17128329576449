import React, { useEffect, useRef, useState } from "react"

import { styled } from "styled-components"

import Session from 'services/session'

import ArrowButton, { ArrowDirection } from "components/signup/components/ArrowButton"
import Button from "components/signup/components/Button"
import { SignupStep } from "components/signup/constants"
import Input from "components/signup/components/Input"
import StepContainer, { getStepContainerMotionProps } from "components/signup/StepContainer"
import { StepDirection } from "components/signup/types"
import { Actions as UserActions } from 'redux/currentUser'

import styles from "styles/styles"
import { useDispatch, useSelector } from "react-redux"
import { updateStep } from "../signupSlice"

type SignInProps = {
}

const SignIn = (props: SignInProps) => {
    const dispatch = useDispatch()

    const user = useSelector((state: any) => state.currentUser)
    const step = useSelector((state: any) => state.signup.step)
    const application = useSelector((state: any) => state.signup.application)
    const isInviteCodeValid = useSelector((state: any) => state.signup.isInviteCodeValid)
    const products = useSelector((state: any) => state.signup.products)
    const stepDirection = useSelector((state: any) => state.signup.direction)

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [showError, setShowError] = useState(false)
    
    const inputRef = useRef(null)

    const selectedProduct = products?.find((product: any) => product.productCode === application.productCode)
    const title = step === SignupStep.UserDetected ? `Is that you?` : 'Log In'
    const subtitle = step === SignupStep.UserDetected ? `You’re already a Taekus member! Please log in to your account to continue. If you’d like to have separate logins, we will help you split them later.` : 'If you’re already a Taekus member, please log in to your account to prevent delays in processing your application.'

    const onUsernameChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        setUsername(ev.target.value)
    }

    const onPasswordChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(ev.target.value)
    }

    const returnToApplication = () => {
        const validatedInviteCode = isInviteCodeValid
        const productSelected = !!(selectedProduct.productType && selectedProduct.productCategory)
        const nextStep = validatedInviteCode ? (productSelected ? SignupStep.FindInfo : SignupStep.ChooseProduct) : SignupStep.InviteCode
        dispatch(updateStep({ step: nextStep }))
    }

    const handleBack = (ev: React.MouseEvent) => {
        ev.stopPropagation()
        const validatedInviteCode = isInviteCodeValid
        const productSelected = !!(selectedProduct.productType && selectedProduct.productCategory)
        const nextStep = validatedInviteCode ? (productSelected ? SignupStep.FindInfo : SignupStep.ChooseProduct) : SignupStep.InviteCode
        dispatch(updateStep({ step: nextStep, direction: StepDirection.Left }))
    }

    const handleSignIn = async (ev: React.FormEvent) => {
        ev.preventDefault();
        await Session.login(
            username,
            password,
            false, //rememberMe,
            false, //appBased2Fa,
            '', //token,
            () => {}, //this.handleLoginFailure,
            () => {}, //this.handleTwoFASuccess,
            () => {}, //this.handleTwoFAFailure,
            () => {}, //this.handleNeedsTwoFARegistration,
            undefined,
            false // redirectOnSuccess
        ).then(() => {
            dispatch(UserActions.fetchCurrentUser())
        })
        setShowError(true)
    }

    useEffect(() => {
        (inputRef.current as any)?.focus()
    }, [])

    useEffect(() => {
        if (user.userLoaded) {
            returnToApplication()
        }
    }, [user.userLoaded]) // eslint-disable-line

    return <StepContainer
        {...getStepContainerMotionProps(stepDirection)}
        key='SignIn'
    >
        <form className="d-flex flex-column align-items-center" onSubmit={handleSignIn}>
            <Title>{title}</Title>
            <Text>{subtitle}</Text>
            <div style={{marginBottom: '8px'}}>
                <Input errorMessage={(showError && user.error) ? "We can't find an account matching this username." : undefined} name='username' onChange={onUsernameChange} value={username} parentRef={inputRef} label="Username"/>
            </div>
            <Input type='password' name='password' onChange={onPasswordChange} value={password} label="Password"/>
            <div style={{marginTop: '32px', display: 'flex'}}>
                <div style={{marginRight: '16px'}}>
                    <ArrowButton onClick={handleBack} direction={ArrowDirection.Left}/>
                </div>
                <Button isLoading={user.isFetching} disabled={false} type="submit">Next</Button>
            </div>
        </form>
    </StepContainer>
}

const Text = styled.div`
    color: ${styles.Color.TaekusGrey2};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%; /* 22.08px */
    letter-spacing: 0.32px;
    margin-bottom: 16px;
`

const Title = styled.div`
    color: ${styles.Color.TaekusGrey1};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 49.6px */
    letter-spacing: 0.4px;
    margin-bottom: 32px;
`

export default SignIn