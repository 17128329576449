import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import styled from "styled-components"

import { useIsFirstRender } from "hooks/useIsFirstRender"
import { Actions as BookingActions } from 'redux/features/flightBook'

import Button, { ButtonSize, ButtonType } from "components/common/Button"
import Modal from "components/common/Modal"

import styles from "styles/styles"

type Passenger = {
    firstName: string,
    lastName: string,
    uuid: string,
}

type DeletePassengerModalProps = {
    passenger: Passenger,
    onClose: () => void,
}

const DeletePassengerModal = (props: DeletePassengerModalProps) => {
    const dispatch = useDispatch()
    const isFirstRender = useIsFirstRender();
    
    const flightBook = useSelector((state: any) => state.flightBook)
    
    const { passenger, onClose } = props;

    const deletePassenger = () => {
        const travelerIndex = flightBook.bookingTravelers.findIndex((traveler: any) => traveler.uuid === passenger?.uuid)
        if (passenger && passenger?.uuid) {
            const deleteTravellerParams = { uuid: passenger.uuid }
            dispatch(BookingActions.deleteTraveler(deleteTravellerParams, travelerIndex))
        }
    }

    useEffect(() => {
        if (!isFirstRender && flightBook.updateTravelerIsLoading === false) {
            onClose();
        }
    }, [flightBook.updateTravelerIsLoading]) // eslint-disable-line

    return <Modal title={"Delete Passenger"} onClose={onClose}>
        <ModalBody>
            <Text>
                Are you sure you want to delete {passenger?.firstName} {passenger?.lastName}? You will no longer see them as an option when booking trips, and will need to re-enter their information if you want to add them again.
            </Text>
            <ButtonsRow>
                <ButtonContainer>
                    <Button
                        onClick={onClose}
                        buttonType={ButtonType.SecondaryPurple}
                        size={ButtonSize.Auto}
                        label="Cancel"
                    />
                </ButtonContainer>
                <ButtonContainer>
                    <Button
                        onClick={deletePassenger}
                        buttonType={ButtonType.Purple}
                        size={ButtonSize.Auto}
                        label="Delete Passenger"
                    />
                </ButtonContainer>
            </ButtonsRow>
        </ModalBody>
    </Modal>
}

const Text = styled.div`
    flex: 1;
    font-size: 14px;
    min-height: 120px;
`

const ModalBody = styled.div`
    max-width: 500px;
    flex: 1;
    display: flex;
    flex-direction: column;
    font-family: ${styles.Font.Family.MonumentGrotesk};
`

const ButtonsRow = styled.div`
    display: flex;
    justify-content: end;
    ${styles.MediaQueries.Mobile} {
        flex-direction: column;
    }
`

const ButtonContainer = styled.div`
    flex: 1;
    ${styles.MediaQueries.Mobile} {
        padding: ${styles.Spacing.XS} 0 0 0 !important;
    }
    &:first-child {
        padding-right: ${styles.Spacing.XS};
    }
    &:last-child {
        padding-left: ${styles.Spacing.XS};
    }
`

export default DeletePassengerModal