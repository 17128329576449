import React from "react"

import styled from "styled-components";
import styles from "styles/styles";

import { ReactComponent as CheckmarkIcon } from "assets/svg/Checkmarks/Checkmark.svg";

type CheckboxProps = {
    checked: boolean;
    onChange: (props: any) => void;
    disabled?: boolean;
    id?: string;
}

/*
 * We should rework this component to use an actual HTML input element instead of a pseudo-element
 * Can do a 
 *  CSS display: none;
 * to hide the element but we should still allow the element to control the input state logic
 */
const Checkbox = (props: CheckboxProps) => {
    return <CustomCheckbox
        id={props.id}
        isDisabled={props.disabled}
        isSelected={props.checked}
        onClick={props.disabled ? undefined : props.onChange}
    >
        {props.checked && <CustomCheckmarkIcon />}
    </CustomCheckbox>
}

type CustomCheckboxProps = {
    isSelected: boolean;
    isDisabled?: boolean;
}

const CustomCheckbox = styled.div<CustomCheckboxProps>`
    border-radius: ${styles.BorderRadius.S};
    background-color: ${props => props.isDisabled ? styles.Color.Grey : styles.Color.White};
    ${props => !props.isDisabled && 'cursor: pointer;'}
    border: 1px solid ${props => props.isSelected && !props.isDisabled ? styles.Color.TaekusPurple : styles.Color.Grey};
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
        ${props => !props.isDisabled && `border-color: ${styles.Color.TaekusPurple};`}
    }
`

const CustomCheckmarkIcon = styled(CheckmarkIcon)`
    width: 90%;
    height: 90%;
`

export default Checkbox;