import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux";

import { styled } from "styled-components"

import Button from "components/signup/components/Button";
import Input from "components/signup/components/Input";
import StepContainer, { getStepContainerMotionProps } from "components/signup/StepContainer";
import { StepDirection } from "components/signup/types";

import styles from "styles/styles";
import { updateApplication, updateStep } from "../signupSlice";
import { SignupStep } from "../constants";

type PasswordProps = {
}

const Password = (props: PasswordProps) => {
    const dispatch = useDispatch()
    
    const isLoading = useSelector((state: any) => state.signup.isLoading)
    const stepDirection = useSelector((state: any) => state.signup.direction)

    const inputRef = useRef(null)

    const [password, setPassword] = useState('')
    const [showError, setShowError] = useState<boolean>(false)

    const hasUppercase = /[A-Z]/.test(password)
    const hasDigit = /\d/.test(password)
    const isCorrectLength = password.length >= 12
    const isPasswordValid = hasUppercase && hasDigit && isCorrectLength

    const handleSubmit = (ev: React.FormEvent) => {
        ev.preventDefault();
        if (isPasswordValid) {
            setShowError(false)
            dispatch(updateApplication({ password }))
            dispatch(updateStep({ step: SignupStep.VerifyEmail, direction: StepDirection.Right }))
        } else {
            setShowError(true)
        }
    }

    const handleInputChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        if (ev.target.value.length <= 28) {
            setPassword(ev.target.value)
        }
    }

    // Focus password input on mount
    useEffect(() => {
        (inputRef.current as any)?.focus()
    }, [])

    return <StepContainer
        {...getStepContainerMotionProps(stepDirection)}
        key='Password'
    >
        <form className="d-flex flex-column align-items-center" onSubmit={handleSubmit}>
            <Title>Choose your password</Title>
            <Input 
                type="password" 
                onChange={handleInputChange} 
                value={password} 
                setValue={setPassword} 
                parentRef={inputRef} 
                label="Password"
            />
            <Rules>
                <div>Passwords must:</div>
                <Rule error={showError} valid={hasUppercase}>  ⦁  Contain at least one upper case letter.</Rule>
                <Rule error={showError} valid={hasDigit}>  ⦁  Contain at least one digit.</Rule>
                <Rule error={showError} valid={isCorrectLength}>  ⦁  Be at least 12 characters long.</Rule>
            </Rules>
            <ButtonRow>
                <Button isLoading={isLoading} type="submit">Next</Button>
            </ButtonRow>
        </form>
    </StepContainer>
}

type RuleProps = {
    valid: boolean,
    error: boolean,
}

const Rule = styled.div<RuleProps>`
    color: ${props => props.valid ? styles.Color.TaekusGreenText : (props.error ? styles.Color.TaekusRed : 'auto')};
    ${styles.Animation.transitionStyles}
`

const Rules = styled.div`
    margin-top: 8px;
    padding: 0 16px;
    min-width: 308px;
    color: ${styles.Color.TaekusGrey2};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    white-space: pre-wrap;
`

const ButtonRow = styled.div`
    margin-top: 32px;
`

const Title = styled.div`
    color: ${styles.Color.TaekusGrey1};
    text-align: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 49.6px */
    letter-spacing: 0.4px;
    margin-bottom: 32px;
`

export default Password