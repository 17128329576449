import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import moment from 'moment';

import { Modal as BootstrapModal } from 'react-bootstrap';
import { List, Grid } from "react-virtualized";

import { AnimatePresence, motion } from 'framer-motion'
import styled from 'styled-components'

import { FaRegTimesCircle } from 'react-icons/fa'

import { Actions as PaymentCardActions } from 'redux/features/banking/paymentCards'

import { mccCodes } from 'utils/mccCodes'

import { useIsFirstRender } from 'hooks/useIsFirstRender';

import Footer from 'components/common/Footer'
import Modal from 'components/common/Modal';
import Select from "components/common/Select/Select"
import selectStyles from "components/common/Select/selectStyles"
import Spinner from 'components/common/Spinner';
import StatusDisplay from 'components/common/StatusDisplay';
import Navigation, { NavColor } from 'components/navbar/Navigation'

import CardStatusDropdown from 'components/pages/Cards/CardStatusDropdown';
import { CardStatus, SIDEBAR_WIDTH, CardType } from 'components/pages/Cards/constants'
import { messages as cardTypeMessages } from 'components/pages/Cards/constants'
import CardMobileItem from 'components/pages/Cards/MappedCardItems/CardMobileItem';
import CardGridItem from 'components/pages/Cards/MappedCardItems/CardGridItem'
import CardListItem from 'components/pages/Cards/MappedCardItems/CardListItem'
import CardMock from 'components/pages/Cards/CardMock';

import { fadeInOutMotionProps } from 'styles/motionConstants';
import styles from 'styles/styles';

import { ReactComponent as CaretDown } from "assets/svg/CaretDown.svg";
import { ReactComponent as Checkmark } from "assets/svg/Checkmarks/Checkmark.svg";
import { ReactComponent as Close } from "assets/svg/Close.svg";
import { ReactComponent as GridIcon } from "assets/svg/GridIcon.svg";
import { ReactComponent as ListIcon } from "assets/svg/ListIcon.svg";

const GRID_ITEM_WIDTH = 380
const GRID_ITEM_HEIGHT = 410
const LIST_ITEM_HEIGHT = 140

enum View {
    Grid = 'Grid',
    List = 'List'
}

enum SpendControlLock {
    FreeUse = 'FREE_USE',
    SingleUseLocked = 'SINGLE_USE_LOCKED',
    OnceMonthLocked = 'ONCE_MONTH_LOCKED',
    MerchantLocked = 'MERCHANT_LOCKED',
    MccLocked = 'MCC_LOCKED',
    MaxLimitLocked = 'MAX_LIMIT_LOCKED',
}

const messages = {
    Title: 'Cards',
    SearchPlaceholder: 'Search Cards',
    ListViewHeader: {
        CardName: 'Card Name',
        Type: 'Type',
        Status: 'Status',
        Spend7Day: 'Spend 7 Day',
        DateCreated: 'Date Created',
    },
    Filters: {
        Title: 'Tags',
        Retail: 'Retail',
        Entertainment: 'Entertainment',
        Fashion: 'Fashion',
        Status: 'Status',
        Active: 'Active',
        Terminated: 'Terminated',
        Locked: 'Suspended',
        Type: 'Type',
        FreeUse: 'Free Use',
        SingleUseLocked: 'Single Use',
        OnceMonthLocked: 'Once Per Month',
        MerchantLocked: 'Merchant Locked',
        MccLocked: 'MCC Locked',
        MaxLimitLocked: 'Maximum Limit',
        AddCard: '+ Add new card'
    }
}

const defaultCardNickname = `User Generated on ${moment().format('MMM D')}`;

const spendControlOptions = [
    { value: SpendControlLock.FreeUse, label: 'Free Use' },
    { value: SpendControlLock.SingleUseLocked, label: 'Single Use' },
    { value: SpendControlLock.OnceMonthLocked, label: 'Once Per Month' },
    // { value: SpendControlLock.MerchantLocked, label: 'Merchant Locked' },
    { value: SpendControlLock.MccLocked, label: 'MCC Locked' },
    { value: SpendControlLock.MaxLimitLocked, label: 'Maximum Limit' }
  ];

const defaultSpendControlData = {
    freeUse: true,
    singleUseLocked: false,
    oncePerMonthLocked: false,
    merchantLocked: false,
    mccLocked: false,
    maxLimitLocked: false,
    maxLimitAmount: null,
    mccs: [],
}

const mccOptions = Object.entries(mccCodes).map(([key, value]) => ({
    value: key,
    label: value.readable_description,
  }));

interface SpendControlsData {
    freeUse: boolean;
    singleUseLocked: boolean;
    oncePerMonthLocked: boolean;
    merchantLocked: boolean;
    mccLocked: boolean,
    maxLimitLocked: boolean;
    maxLimitAmount: string | null;
    mccs: string[];
}

type UpdateFunction = (value: boolean) => void;

const Cards = () => {
    // Redux store
    const dispatch = useDispatch();
    const currentUser = useSelector((state: any) => state.currentUser.currentUser)
    const parentUser = currentUser.parentUser
    const mainUser = parentUser ? parentUser : currentUser
    const banking = useSelector((state: any) => state.banking)
    // First card is always a null placeholder for some reason -,-
    const cards = useSelector((state: any) => state.paymentCards.paymentCards.paymentCards).slice(1)
    const lastFetchedAccountUuid = useSelector((state: any) => state.paymentCards.paymentCards.lastFetchedAccountUuid)
    const paymentCardDetailsReport = useSelector((state: any) => state.paymentCards.paymentCardDetailsReport)
    const userHasMultipleAddresses = useSelector((state: any) => mainUser.otherAddresses).length > 0
    const selectedPreviewCard = useSelector((state: any) => state.cards.selectedPreviewCard)
    const isCreatingVirtualCard =  useSelector((state: any) => state.paymentCards.createPaymentCard.isLoading)
    const createVirtualCardError = useSelector((state: any) => state.paymentCards.createPaymentCard.error)
    const isLoadingCards = useSelector((state: any) => state.paymentCards.paymentCards.isLoading)
    const isMobile = useSelector((state: any) => state.global.isMobile)

    // Local state
    const [view, setView] = useState(localStorage.getItem('cardsView') || View.Grid)
    const [isMockCardModalOpen, setIsMockCardModalOpen] = useState(false)
    const [isNewCardModalOpen, setIsNewCardModalOpen] = useState(false)
    const [newCardAddressUuid, setNewCardAddressUuid] = useState(mainUser.primaryAddress.uuid)
    const [isCSVModalOpen, setIsCSVModalOpen] = useState(false)
    const [isTerminateCardsModalOpen, setIsTerminateCardsModalOpen] = useState(false)
    const [cardStatusFilter, setCardStatusFilter] = useState<string[]>([ CardStatus.ACTIVE, CardStatus.SUSPENDED ])
    const [cardTypeFilter, setCardTypeFilter] = useState<string[]>([])
    const [cardTagFilter, setCardTagFilter] = useState<string[]>([])
    const [searchValue, setSearchValue] = useState('')
    const [selectedCardUuids, setSelectedCardUuids] = useState<string[]>([])
    const [mobileVirtualizedListSize, setMobileVirtualizedListSize] = useState(0)
    const [cardNickname, setCardNickname] = useState(defaultCardNickname);
    const [cardType, setCardType] = useState(SpendControlLock.FreeUse);
    const [spendControls, setSpendControls] = useState<SpendControlsData>(defaultSpendControlData);
    const [virtualizedGridWidth, setvirtualizedGridSize] = useState(0)
    const [hoveredCardUuid, setHoveredCardUuid] = useState<string | null>(null)

    const [showCardError, setShowCardError] = useState(false)
    let addressOptions = [mainUser.primaryAddress, ...mainUser.otherAddresses].map((address: any) => ({
        value: address.uuid,
        label: `${address.street}${address.unitNum && ` ${address.unitNum}`}, ${address.city} ${address.state}, ${address.zip}`
    }))

    const isFirstRender = useIsFirstRender()
    
    const toggleIsNewCardModalOpen = () => { setIsNewCardModalOpen(!isNewCardModalOpen) }
    const toggleIsTerminateCardsModalOpen = () => { setIsTerminateCardsModalOpen(!isTerminateCardsModalOpen) }
    const toggleIsCSVModalOpen =() => { 
        // Clear status message on modal close
        dispatch(PaymentCardActions.clearCardDetailsReport())
        setIsCSVModalOpen(!isCSVModalOpen) 
    }
    const handleNewCardAddressChange = (option: any) => { setNewCardAddressUuid(option.value) }
    const virtualizedListContainerRef = useRef<any>(null)
    const gridContainerRef = useRef(null);

    const filterVisibleCards = (card: any) => {
        return (
            (cardStatusFilter.length === 0 || cardStatusFilter.includes(card.status)) && 
            (cardTypeFilter.length === 0 || cardTypeFilter.includes(card.cardType)) &&
            (cardTagFilter.length === 0 || cardTagFilter.some((filter: string) => card.tags.includes(filter))) && 
            (searchValue === '' || 
                card.nickname.toLowerCase().includes(searchValue.toLowerCase()) ||
                card.lastFour.includes(searchValue) ||
                card.postalCode.includes(searchValue)
            ) 
        )
    }

    const sortCards = (a: any, b: any) => {
        if ((a.formFactor === 'PHYSICAL') !== (b.formFactor === 'PHYSICAL')) {
            return a.formFactor === 'PHYSICAL' ? -1 : 1;   
        }
        return a.nickname - b.nickname;
    }

    const visibleCards = cards.filter(filterVisibleCards).sort(sortCards)
    const isAllVisibleCardsSelected = !visibleCards.some((card: any) => !selectedCardUuids.includes(card.uuid))

    useEffect(() => {
        if (mainUser.primaryAddress.uuid !== '') {
            setNewCardAddressUuid(mainUser.primaryAddress.uuid)
        }
    }, [mainUser])

    // On first load or card account change, fetch payment cards and unselect all card uuids
    useEffect(() => {
        const isCardsAlreadyLoaded = isFirstRender && lastFetchedAccountUuid === banking.account.uuid

        if (!isCardsAlreadyLoaded && banking?.account.uuid && lastFetchedAccountUuid !== banking?.account.uuid) {
            dispatch(PaymentCardActions.fetchPaymentCards({ cardAccountUuid: banking?.account.uuid }, false))
            setSelectedCardUuids([])
        }
    }, [banking?.account.uuid, dispatch]) // eslint-disable-line

    const handleCardNicknameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCardNickname(event.target.value);
    }

    const terminateCards = () => {
        dispatch(PaymentCardActions.batchUpdatePaymentCards({
            cardAccountUuid: banking.account.uuid,
            cards: selectedCardUuids.map((cardUuid: string) => {
                const card = cards?.find((card: any) => card.uuid === cardUuid)

                return {
                    ...card,
                    status: CardStatus.TERMINATED,
                    oldCardStatus: card.status,
                    tags: Array.from(card.tags)
                }
            })
        }))
        setSelectedCardUuids([])
        toggleIsTerminateCardsModalOpen()
    }

    const mapCardToTile = (props: any) => {
        const card = visibleCards[(props.rowIndex * Math.floor(virtualizedGridWidth / GRID_ITEM_WIDTH)) + props.columnIndex]
        return card && <CardGridItem
            style={props.style}
            {...fadeInOutMotionProps}
            key={`${props.key}:${card.uuid}`}
            card={card}
            hoveredCardUuid={hoveredCardUuid}
            setHoveredCardUuid={setHoveredCardUuid}
        />
    }

    const mapCardToListItem = (props: any) => {
        const card = visibleCards[props.index]
        return <CardListItem 
            selected={selectedCardUuids.includes(card?.uuid)}
            selectCard={selectCard}
            key={`card-${card?.uuid}`}
            card={card}
            setIsMockCardModalOpen={setIsMockCardModalOpen}
            style={props.style}
        />
    }

    const getCSVStatusMessage = () => {
        if (paymentCardDetailsReport.uploadError) {
            return <StatusText color={styles.Color.FailureRed} {...fadeInOutMotionProps}>
                <IconContainer>
                    <FaRegTimesCircle className='w-100 h-auto' fill={styles.Color.FailureRed}/>
                </IconContainer>
                There was an issue uploading your CSV file.
            </StatusText>
        } else if (paymentCardDetailsReport.uploadSuccess) {
            return <StatusText color={styles.Color.TaekusPurple} {...fadeInOutMotionProps}>
                <IconContainer>
                    <Checkmark className='w-100 h-auto' fill={styles.Color.SuccessGreen}/>
                </IconContainer>  
                Your CSV was uploaded successfully.
            </StatusText>
        }
    }

    const selectCard = (uuid: string) => {
        if (selectedCardUuids.includes(uuid)) {
            // JS splice operates in-place so we should make a copy
            const copyOfCardUuids = [...selectedCardUuids]
            copyOfCardUuids.splice(selectedCardUuids.indexOf(uuid), 1)
            setSelectedCardUuids(copyOfCardUuids)
        } else {
            setSelectedCardUuids([...selectedCardUuids, uuid])
        }
    }

    

    const handleLocalDownload = () => {
        const { error, data, filename } = paymentCardDetailsReport
    
        if (!error) {
          const fixedData = data.join('')
          const url = window.URL.createObjectURL(new Blob([fixedData], { type: 'text/csv' }))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', filename)
          link.setAttribute('target', '_blank')
          document.body.appendChild(link)
          link.click()
        }
    }

    const updateFreeUse = (value: boolean) => {
        setSpendControls(prevState => ({ ...prevState, freeUse: value }));
    }

    const updateSingleUseLocked = (value: boolean) => {
        setSpendControls(prevState => ({ ...prevState, singleUseLocked: value }));
    }

    const updateOncePerMonthLocked = (value: boolean) => {
        setSpendControls(prevState => ({ ...prevState, oncePerMonthLocked: value }));
    }

    const updateMerchantLocked = (value: boolean) => {
        setSpendControls(prevState => ({ ...prevState, merchantLocked: value }));
    }

    const updateMccLocked = (value: boolean) => {
        setSpendControls(prevState => ({ ...prevState, mccLocked: value }));
    }

    const updateMaxLimitLocked = (value: boolean) => {
        setSpendControls(prevState => ({ ...prevState, maxLimitLocked: value }));
    }

    const updateMaxLimitAmount = (value: number) => {
        const roundedValue = (Math.round(value * 100)) / 100;
        setSpendControls(prevState => ({ ...prevState, maxLimitAmount: roundedValue.toString() }));
    }

    const updateMccs = (selectedOptions: any) => {
        const selectedMCCs = selectedOptions.map((option: any) => option.value);
        setSpendControls(prevState => ({ ...prevState, mccs: selectedMCCs }));
    }

    const spendControlUpdates: Record<SpendControlLock, UpdateFunction[]> = {
        [SpendControlLock.FreeUse]: [updateFreeUse],
        [SpendControlLock.SingleUseLocked]: [updateSingleUseLocked],
        [SpendControlLock.OnceMonthLocked]: [updateOncePerMonthLocked],
        [SpendControlLock.MerchantLocked]: [updateMerchantLocked],
        [SpendControlLock.MccLocked]: [updateMccLocked],
        [SpendControlLock.MaxLimitLocked]: [updateMaxLimitLocked]
    };

    function isSpendControlLock(key: string): key is SpendControlLock {
        return (Object.values(SpendControlLock) as string[]).includes(key);
    }

    const handleSpendControlChange = (newValue: any) => {
        setSpendControls(defaultSpendControlData);

        if (newValue?.value && isSpendControlLock(newValue.value)) {
            spendControlUpdates[newValue.value as SpendControlLock].forEach(func => func(true));
            setCardType(newValue.value as SpendControlLock);
        }

        setCardType(newValue?.value as SpendControlLock);
    };

    const downloadCSV = () => {
        dispatch(PaymentCardActions.downloadCardDetailsReport({ cardAccountUuid: banking?.account.uuid }))
    }

    const uploadCardDetailsReport = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && !!event.target.files.length) {
            const fileToUpload = event.target.files[0]
            const fileForm = new FormData()
        
            // use FormData to manage file uploading
            fileForm.append('card_report_upload', fileToUpload, fileToUpload.name)
            fileForm.append('card_account_uuid', banking.account.uuid)
            dispatch(PaymentCardActions.uploadCardDetailsReport(fileForm))
        }
    }

    useEffect(() => {
        if (paymentCardDetailsReport.downloadSuccess === true) {
            handleLocalDownload()
        }
    }, [paymentCardDetailsReport]) // eslint-disable-line

    const selectGridView = () => {
        setView(View.Grid)
        localStorage.setItem('cardsView', View.Grid)
    }

    const selectListView = () => {
        setView(View.List)
        localStorage.setItem('cardsView', View.List)
    }
    
    const handleCreateVirtualCard = () => {
        dispatch(PaymentCardActions.createVirtualCard({
            cardAccountUuid: banking?.account.uuid,
            addressUuid: newCardAddressUuid,
            cardNickname: cardNickname === '' ? defaultCardNickname : cardNickname,
            cardType: cardType,
            mccList: spendControls.mccs,
            maxLimitAmount: spendControls.maxLimitAmount
        }))
    }

    const closeMockCardModal = () => {
        setIsMockCardModalOpen(false)
        dispatch(PaymentCardActions.clearPaymentCardToken())
    }

    const handleSearchValueChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(ev.target.value)
    }

    const handleCardStatusFilterChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        if (cardStatusFilter.includes(ev.target.id)) {
            const filtersCopy = [...cardStatusFilter]
            filtersCopy.splice(cardStatusFilter.indexOf(ev.target.id)!, 1)
            setCardStatusFilter(filtersCopy)
        } else {
            setCardStatusFilter([ ...cardStatusFilter, ev.target.id ])
        }
    }

    const handleCardTypeFilterChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        if (cardTypeFilter.includes(ev.target.id)) {
            const filtersCopy = [...cardTypeFilter]
            filtersCopy.splice(cardTypeFilter.indexOf(ev.target.id)!, 1)
            setCardTypeFilter(filtersCopy)
        } else {
            setCardTypeFilter([ ...cardTypeFilter, ev.target.id ])
        }
    }

    const handleCardTagFilterChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        // If the selected tag value exists on the card already, remove it, otherwise, add it
        if (cardTagFilter.includes(ev.target.id)) {
            const filtersCopy = [...cardTagFilter]
            filtersCopy.splice(cardTagFilter.indexOf(ev.target.id)!, 1)
            setCardTagFilter(filtersCopy)
        } else {
            setCardTagFilter([ ...cardTagFilter, ev.target.id ])
        }
    }

    const selectAllCards = () => {
        if (!isAllVisibleCardsSelected) {
            const ids = visibleCards.map((card: any) => card.uuid)
            setSelectedCardUuids(ids)
        } else {
            setSelectedCardUuids([])
        }
    }
    
    const updateSelectedCardsStatus = (selectedOption: any) => {
        dispatch(PaymentCardActions.batchUpdatePaymentCards({
            cardAccountUuid: banking.account.uuid,
            cards: selectedCardUuids.map((cardUuid: string) => {
                const card = cards?.find((card: any) => card.uuid === cardUuid)

                return {
                    ...card,
                    status: selectedOption.value,
                    oldCardStatus: card.status,
                    tags: Array.from(card.tags)
                }
            })
        }))
        setSelectedCardUuids([])
    }

    const getCardContent = (cards: any[]) => {
        if (isMobile) {
            return <MobileCardsContainer ref={virtualizedListContainerRef}>
                {!!cards.length && <StyledList
                    height={mobileVirtualizedListSize}
                    rowHeight={94}
                    rowCount={cards.filter(filterVisibleCards).length}
                    width={1}
                    containerStyle={{ width: "100%", maxWidth: "100%" }}
                    style={{ width: "100%" }}
                    rowRenderer={(props: any) => {
                        const card = visibleCards[props.index]
                        return <CardMobileItem setIsMockCardModalOpen={setIsMockCardModalOpen} style={props.style} key={`card-${card.uuid}`} card={card}/>
                    }}
                />}
            </MobileCardsContainer>
        }

        const cardsPerRow = Math.floor(virtualizedGridWidth / GRID_ITEM_WIDTH) || 1
        const dynamicColumnWidth = virtualizedGridWidth / Math.floor(virtualizedGridWidth / GRID_ITEM_WIDTH)
        const totalRowCount = Math.ceil(visibleCards.length / cardsPerRow)

        return (view === View.Grid ? <motion.div
            key='grid'
            {...fadeInOutMotionProps}
            ref={gridContainerRef}
        >
            {!!visibleCards.length && !!virtualizedGridWidth && <StyledGrid
                key={virtualizedGridWidth}
                height={window.innerHeight * 0.9}
                columnCount={cardsPerRow}
                columnWidth={dynamicColumnWidth}
                rowCount={totalRowCount}
                rowHeight={GRID_ITEM_HEIGHT}
                width={virtualizedGridWidth || 1}
                containerStyle={{ width: "100%", maxWidth: "100%"}}
                style={{ width: "100%"}}
                cellRenderer={mapCardToTile}
            />}
        </motion.div> : <motion.div
            style={{flex: 1}}
            key='list'
            {...fadeInOutMotionProps}
        >
            <ListViewContainer>
                <ListViewActions>
                    <div>
                        <AnimatePresence>
                            {!!selectedCardUuids.length && <motion.div className='d-flex align-items-center' {...fadeInOutMotionProps}>
                                <SelectedCardCount>{selectedCardUuids.length} cards selected</SelectedCardCount>
                                <CardStatusDropdown onChange={updateSelectedCardsStatus}/>
                                <TerminateCardsButton onClick={toggleIsTerminateCardsModalOpen}>Terminate Selected Cards</TerminateCardsButton>
                            </motion.div>}
                        </AnimatePresence>
                    </div>
                    <SelectAllButton onClick={selectAllCards}>{isAllVisibleCardsSelected ? 'Deselect Cards' : 'Select All Cards'}</SelectAllButton>
                </ListViewActions>
                <ListViewHeader>
                    <div className='w-100 d-flex'>
                        <CardHeader width={userHasMultipleAddresses ? `${100 * 2 / 7}%` : `${100 / 3}%`}>{messages.ListViewHeader.CardName}</CardHeader>
                        {userHasMultipleAddresses && <CardHeader width={`${100 / 7}%`}>Postal Code</CardHeader>}
                        <CardHeader width={userHasMultipleAddresses ? `${100 / 7}%` : `${100 / 6}%`}>{messages.ListViewHeader.Type}</CardHeader>
                        <CardHeader width={userHasMultipleAddresses ? `${100 / 7}%` : `${100 / 6}%`}>{messages.ListViewHeader.Status}</CardHeader>
                        <CardHeader width={userHasMultipleAddresses ? `${100 / 7}%` : `${100 / 6}%`}>{messages.ListViewHeader.Spend7Day}</CardHeader>
                        <CardHeader width={userHasMultipleAddresses ? `${100 / 7}%` : `${100 / 6}%`}>{messages.ListViewHeader.DateCreated}</CardHeader>
                    </div>
                </ListViewHeader>
                {/* max height includes 5.5 rows  */}
                {!!cards.length && <StyledList
                    height={window.innerHeight * 0.9}
                    rowHeight={LIST_ITEM_HEIGHT}
                    rowCount={visibleCards.length}
                    width={1}
                    containerStyle={{ width: "100%", maxWidth: "100%"}}
                    style={{ width: "100%"}}
                    rowRenderer={mapCardToListItem}
                />}
                
            </ListViewContainer>
        </motion.div>)
    }

    useEffect(() => {
        !!(virtualizedListContainerRef.current as any)?.clientHeight && setMobileVirtualizedListSize((virtualizedListContainerRef.current as any)?.clientHeight)
    }, [])

    window.addEventListener('resize', () => {
        (gridContainerRef.current as any)?.clientWidth && setvirtualizedGridSize((gridContainerRef.current as any)?.clientWidth || 0)
    })

    useEffect(() => {
        view === View.Grid && !!(gridContainerRef.current as any)?.clientWidth && setvirtualizedGridSize((gridContainerRef.current as any)?.clientWidth || 0)
    }, [view])

    useEffect(() => {
        if (!isFirstRender && isCreatingVirtualCard === false) {
            setShowCardError(!!createVirtualCardError)
            if (!createVirtualCardError) {
                setIsNewCardModalOpen(!isNewCardModalOpen)
                setCardNickname(defaultCardNickname);
            }
        }
    }, [isCreatingVirtualCard]) // eslint-disable-line

    return <Container>
        <Navigation color={NavColor.Black}/>
        <Content>
            {!isMobile ? <Sidebar>
                <CardSearch value={searchValue} onChange={handleSearchValueChange} placeholder={messages.SearchPlaceholder} />
                <LineBreak />
                <FilterTitle>{messages.Filters.Status}</FilterTitle>
                <Flex>
                    <Checkbox checked={cardStatusFilter.includes(CardStatus.ACTIVE)} id={CardStatus.ACTIVE} onChange={handleCardStatusFilterChange} type='checkbox'/>
                    <FilterLabel htmlFor={CardStatus.ACTIVE}>{messages.Filters.Active}</FilterLabel>
                </Flex>
                <Flex>
                    <Checkbox checked={cardStatusFilter.includes(CardStatus.SUSPENDED)} id={CardStatus.SUSPENDED} onChange={handleCardStatusFilterChange} type='checkbox'/>
                    <FilterLabel htmlFor={CardStatus.SUSPENDED}>{messages.Filters.Locked}</FilterLabel>
                </Flex>
                <Flex>
                    <Checkbox checked={cardStatusFilter.includes(CardStatus.TERMINATED)} id={CardStatus.TERMINATED} onChange={handleCardStatusFilterChange} type='checkbox'/>
                    <FilterLabel htmlFor={CardStatus.TERMINATED}>{messages.Filters.Terminated}</FilterLabel>
                </Flex>
                <LineBreak />
                <FilterTitle>{messages.Filters.Type}</FilterTitle>
                <Flex>
                    <Checkbox checked={cardTypeFilter.includes(CardType.FREE_USE)} id={CardType.FREE_USE} onChange={handleCardTypeFilterChange} type='checkbox'/>
                    <FilterLabel htmlFor={CardType.FREE_USE}>{cardTypeMessages.CardTypeLabel.FREE_USE}</FilterLabel>
                </Flex>
                <Flex>
                    <Checkbox checked={cardTypeFilter.includes(CardType.SINGLE_USE_LOCKED)} id={CardType.SINGLE_USE_LOCKED} onChange={handleCardTypeFilterChange} type='checkbox'/>
                    <FilterLabel htmlFor={CardType.SINGLE_USE_LOCKED}>{cardTypeMessages.CardTypeLabel.SINGLE_USE_LOCKED}</FilterLabel>
                </Flex>
                <Flex>
                    <Checkbox checked={cardTypeFilter.includes(CardType.ONCE_MONTH_LOCKED)} id={CardType.ONCE_MONTH_LOCKED} onChange={handleCardTypeFilterChange} type='checkbox'/>
                    <FilterLabel htmlFor={CardType.ONCE_MONTH_LOCKED}>{cardTypeMessages.CardTypeLabel.ONCE_MONTH_LOCKED}</FilterLabel>
                </Flex>
                {/* <Flex>
                    <Checkbox checked={cardTypeFilter.includes(CardType.MERCHANT_LOCKED)} id={CardType.MERCHANT_LOCKED} onChange={handleCardTypeFilterChange} type='checkbox'/>
                    <FilterLabel htmlFor={CardType.MERCHANT_LOCKED}>{cardTypeMessages.CardTypeLabel.MERCHANT_LOCKED}</FilterLabel>
                </Flex> */}
                <Flex>
                    <Checkbox checked={cardTypeFilter.includes(CardType.MCC_LOCKED)} id={CardType.MCC_LOCKED} onChange={handleCardTypeFilterChange} type='checkbox'/>
                    <FilterLabel htmlFor={CardType.MCC_LOCKED}>{cardTypeMessages.CardTypeLabel.MCC_LOCKED}</FilterLabel>
                </Flex>
                <Flex>
                    <Checkbox checked={cardTypeFilter.includes(CardType.MAX_LIMIT_LOCKED)} id={CardType.MAX_LIMIT_LOCKED} onChange={handleCardTypeFilterChange} type='checkbox'/>
                    <FilterLabel htmlFor={CardType.MAX_LIMIT_LOCKED}>{cardTypeMessages.CardTypeLabel.MAX_LIMIT_LOCKED}</FilterLabel>
                </Flex>
                <LineBreak />
                <FilterTitle>{messages.Filters.Title}</FilterTitle>
                {(isCreatingVirtualCard || banking.isLoading) ? <motion.div className='d-flex justify-content-center' key='spinner'>
                    <SpinnerContainer>
                        <Spinner size={20}/>
                    </SpinnerContainer>
                </motion.div> : <>
                    <TagFilters>
                        {banking?.account?.tags?.length > 0 ? banking?.account?.tags?.map((tag: string) => <Flex key={tag}>
                            <Checkbox 
                                checked={cardTagFilter.includes(tag)}
                                id={tag}
                                onChange={handleCardTagFilterChange}
                                type='checkbox'
                            />
                            <FilterLabel htmlFor={tag}>{tag}</FilterLabel>
                        </Flex>) : <EmptyTagFilterText>Add a tag to start filtering</EmptyTagFilterText>}
                    </TagFilters>
                    <NewCardButton key='addCardButton' onClick={toggleIsNewCardModalOpen}>
                        {messages.Filters.AddCard}
                    </NewCardButton>
                </>}
                {<TotalCardCount isVisible={!!banking.account.cardLimit && !!cards.filter((card: any) => card.formFactor !== 'PHYSICAL').length}>
                    <div>You have {cards?.filter((card: any) => card.status !== CardStatus.TERMINATED)?.length?.toLocaleString()}/{banking?.account?.cardLimit?.toLocaleString()}</div>
                    <div>total cards</div>
                </TotalCardCount>}
            </Sidebar> : <div className='d-flex justify-content-between align-items-center' style={{margin: '0 10px'}}>
                <CardSearch value={searchValue} onChange={handleSearchValueChange} placeholder={messages.SearchPlaceholder} />
                {(isCreatingVirtualCard || banking.isLoading) ? 
                    <SpinnerContainer>
                        <Spinner size={20}/>
                    </SpinnerContainer> : 
                <div onClick={toggleIsNewCardModalOpen} style={{fontSize: '30px', lineHeight: '30px'}}>+</div>}
            </div>}
            <CardsContainer>
                <Header>
                    <div className='d-flex align-items-end'>
                        <Title>{messages.Title}</Title>
                        {cards.length ? <CardCount {...fadeInOutMotionProps}>{cards.filter((card: any) => card.status === CardStatus.ACTIVE).length.toLocaleString()} Active, {cards.filter((card: any) => card.status === CardStatus.SUSPENDED).length.toLocaleString()} Suspended</CardCount> : ''}
                    </div>
                    {!isMobile && <ViewIconsContainer>
                        {currentUser.hasCardCsvAccess && <DownloadIconContainer onClick={toggleIsCSVModalOpen}>
                            CSV
                        </DownloadIconContainer>}
                        <ViewIconWrapper 
                            isSelected={view === View.Grid} 
                            onClick={selectGridView}
                        >
                            <GridIcon/>
                        </ViewIconWrapper>
                        <ViewIconWrapper 
                            isSelected={view === View.List} 
                            onClick={selectListView}
                        >
                            <ListIcon/>
                        </ViewIconWrapper>
                    </ViewIconsContainer>}
                </Header>
                {(isLoadingCards || banking.isLoading) ? <SpinnerWrapper key='spinner' {...fadeInOutMotionProps}>
                    <Spinner size={40}/>
                    <div style={{ marginTop: styles.Spacing.S, opacity: 0.5 }}>Fetching Cards</div>
                </SpinnerWrapper> : getCardContent(cards?.filter(filterVisibleCards))}
                <BootstrapModal centered className='CardSummaryMock' show={isMockCardModalOpen}>
                    <CardMock
                        cardAccountUuid={banking.account.uuid}
                        cardUuid={selectedPreviewCard?.uuid}
                        cardName={selectedPreviewCard?.nickname}
                        userName={`${mainUser.firstName} ${mainUser.lastName}`}
                        error={false}
                        onClose={closeMockCardModal}
                    />
                </BootstrapModal>
                {isNewCardModalOpen && <Modal title='Add Virtual Card' onClose={toggleIsNewCardModalOpen}>
                    <ModalBody>
                        <NewCardModalContent>
                            <div>Name</div>
                            <TextInput 
                                value={cardNickname}
                                onChange={handleCardNicknameChange}
                            />
                            {<>
                                <div>Card Type</div>
                                <Select
                                    onChange={handleSpendControlChange}
                                    options={spendControlOptions}
                                    dropdownIndicator={<CaretWrapper><StyledCaretDown/></CaretWrapper>}
                                    styleType={selectStyles.virtualCards}
                                    value={spendControlOptions.find((option: any) => option.value === cardType) as any}
                                />
                            </>}
                            {cardType === SpendControlLock.MccLocked && (
                                <>
                                    <div>MCC</div>
                                    <Select
                                        isMulti={true}
                                        onChange={updateMccs}
                                        options={mccOptions}
                                        dropdownIndicator={<CaretWrapper><StyledCaretDown/></CaretWrapper>}
                                        getOptionLabel={(option: { value: string, label: string }) => `${option.value} - ${option.label}`}
                                        styleType={selectStyles.virtualCards}
                                        isSearchable={true}
                                    />
                                </>
                            )}
                            {cardType === SpendControlLock.MaxLimitLocked && (
                                <>
                                    <div>Maximum Limit</div>
                                    <MaximumLimitTextInput
                                        onChange={(e) => {
                                            const value = parseFloat(e.target.value);
                                            if (!isNaN(value) && value >= 0) {
                                                updateMaxLimitAmount(value);
                                            }
                                        }}
                                    />
                                </>
                            )}
                            {userHasMultipleAddresses && (
                                <>
                                    <div>Address</div>
                                    <Select
                                        onChange={handleNewCardAddressChange}
                                        value={addressOptions.find((option: any) => option.value === newCardAddressUuid) as any}
                                        options={addressOptions}
                                        dropdownIndicator={<CaretWrapper><StyledCaretDown/></CaretWrapper>}
                                        styleType={selectStyles.virtualCards}
                                    />
                                </>
                            )}
                            {showCardError && <StatusDisplay isLoading={false} isError label={'There was an error creating your card'}/>}
                            <NewCardButtonContainer>
                                <Button onClick={handleCreateVirtualCard}>Create Virtual Card</Button>
                            </NewCardButtonContainer>
                        </NewCardModalContent>
                    </ModalBody>
                </Modal>}
                <BootstrapModal centered show={isCSVModalOpen}>
                    <ModalBody>
                        <div className='w-100 d-flex justify-content-between align-items-center'>
                            <ModalTitle>CSV Card Actions</ModalTitle>
                            <CloseButton onClick={toggleIsCSVModalOpen}>
                                <CustomClose/>
                            </CloseButton>
                        </div>
                        <CSVModalDescription>
                            <div>Below you can interact with your virtual cards via a CSV format. You can download a CSV of your card and its details (card number, expiration date, and CVV are provided only if you have privileged access) and you can upload a CSV with altered details or new rows to create additional cards.</div> 
                            <br/>
                            <div>For more instructions: <PurpleLink target='_blank' href='https://taekus.com/using-virtual-cards-csvs'>https://taekus.com/using-virtual-cards-csvs</PurpleLink></div>
                            <br/>
                        </CSVModalDescription>
                        {getCSVStatusMessage()}
                        <div className='pl-4 w-100 d-flex align-items-center justify-content-between'>
                            <Button onClick={downloadCSV}>
                                Download Card Report
                            </Button>
                            <CSVInput onChange={uploadCardDetailsReport} accept='.csv' id='fileInput' type='file'/>
                            <CSVUpload htmlFor='fileInput'>Update Cards via Upload</CSVUpload>
                        </div>
                    </ModalBody>
                </BootstrapModal>
                <BootstrapModal centered show={isTerminateCardsModalOpen}>
                    <ModalBody>
                        <div className='w-100 d-flex justify-content-between align-items-center'>
                            <ModalTitle>Terminate Cards</ModalTitle>
                            <CloseButton onClick={toggleIsTerminateCardsModalOpen}>
                                <Close/>
                            </CloseButton>
                        </div>
                        <TerminateCardsModalBody>
                            <div className='w-100'>Are you sure you want to terminate the following cards?</div>
                            <ScrollableCardList>
                                {selectedCardUuids.map((uuid: string) => <div>{cards.find((card: any) => card.uuid === uuid)?.nickname}</div>)}
                            </ScrollableCardList>
                        </TerminateCardsModalBody>
                        <div className='w-100 d-flex align-items-center justify-content-end'>
                            <Button color={styles.Color.FailureRed} onClick={terminateCards}>
                                Terminate Cards
                            </Button>
                        </div>
                    </ModalBody>
                </BootstrapModal>
            </CardsContainer>
        </Content>
        <Footer/>
    </Container>
}

const CustomClose = styled(Close)`
    width: 24px;
    height: 24px;
`

const TerminateCardsButton = styled.div`
    margin-left: ${styles.Spacing.XS};
    color: ${styles.Color.FailureRed};
    cursor: pointer;
`

const TagFilters = styled.div`
    max-height: 160px;
    overflow-y: auto;
    ${styles.Scrollbar.defaultScrollbarStyles}
`

type StatusTextProps = {
    color: string;
}

const StatusText = styled(motion.div)<StatusTextProps>`
    display: flex;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    width: 100%;
    height: ${styles.Spacing.L};
    color: ${props => props.color};
    padding: ${styles.Spacing.XS} ${styles.Spacing.S};
`

const ScrollableCardList = styled.div`
    margin: ${styles.Spacing.XS} 0;
    max-height: 180px;
    width: 100%;
    overflow-y: scroll;
    ${styles.Scrollbar.defaultScrollbarStyles}
`

const EmptyTagFilterText = styled.div`
    font-size: 14px;
    opacity: 0.5;
    text-align: center;
`

const IconContainer = styled.div`
    width: ${styles.Spacing.S};
    margin-right: ${styles.Spacing.XS};
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
`

const ModalTitle = styled.div`
    padding: 0 ${styles.Spacing.S};
    font-size: 20px;
    display: flex;
    align-items: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
`

const StyledList = styled(List)`
    &::-webkit-scrollbar {
        width: 0.4em;
        height: 0.4em;
        background-color: #D9D9D9;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #7C3D76;
        border: 4px solid transparent;
        border-radius: 16px;
    }
    padding-right: ${styles.Spacing.XS};
    width: 100%;
    ${styles.MediaQueries.Mobile} {
        padding-bottom: 20px;
    }
`

const SelectedCardCount = styled.div`
    opacity: 0.5;
    margin-right: ${styles.Spacing.XS};
`

type TotalCardCountProps = {
    isVisible: boolean;
}

const TotalCardCount = styled.div<TotalCardCountProps>`
    margin: ${styles.Spacing.XS} 0;
    text-align: center;
    opacity: 0.5;
    visibility: ${props => props.isVisible ? 'visible' : 'hidden'};
    font-size: ${styles.Font.Size.Small};
    font-family: ${styles.Font.Family.MonumentGrotesk};
`

const CSVUpload = styled.label`
    cursor: pointer;
    margin: 0 ${styles.Spacing.S};
    flex: 1;
    height: ${styles.Spacing.M};
    border: 0;
    background-color: ${styles.Color.TaekusPurple};
    color: ${styles.Color.White};
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
`

const CSVInput = styled.input`
    display: none;
`

const PurpleLink = styled.a`
    color: ${styles.Color.TaekusPurple};
    &:hover{
        color: ${styles.Color.TaekusPurple};
    }
`

const TerminateCardsModalBody = styled.div`
    width: 100%;
    padding: ${styles.Spacing.XS} ${styles.Spacing.S};
`

const CSVModalDescription = styled.div`
    margin: 0 ${styles.Spacing.S};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: ${styles.Font.Size.Small};
`

const NewCardButtonContainer = styled.div`
    display: flex;
    justify-content: end;
    margin-top: ${styles.Spacing.S};
`

const NewCardModalContent = styled.div`
    font-family: ${styles.Font.Family.MonumentGrotesk};
    width: 100%;
`

const CloseButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${styles.Spacing.M};
    height: ${styles.Spacing.M};
    cursor: pointer;
    margin: ${styles.Spacing.XS};
`

const ModalBody = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: min-content;
    width: 100%;
    margin-bottom: ${styles.Spacing.S};
`

const CardCount = styled(motion.div)`
    margin: 5px ${styles.Spacing.XS};
`

const ListViewHeader = styled.div`
    display: flex;
    padding-right: calc(0.4em + ${styles.Spacing.XS});
`

const ListViewActions = styled.div`
    display: flex;
    margin-bottom: ${styles.Spacing.XS};
    padding: 0 ${styles.Spacing.XS};
    justify-content: space-between;
    align-items: center;
    min-height: ${styles.Spacing.M};
`

const TextInput = styled.input`
    background-color: transparent;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid ${styles.Color.GreyText};
    outline: 0;
    font-size: ${styles.Spacing.S};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    width: 100%;
    margin-bottom: ${styles.Spacing.S};
    ${styles.Animation.transitionStyles}
    &:hover, &:focus {
        border-bottom: 1px solid ${styles.Color.TaekusPurple};
    }
`

const MaximumLimitTextInput = styled.input.attrs({
    type: 'number',
    min: '0',
    step: '0.01'
})`
    background-color: transparent;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid ${styles.Color.TaekusPurple};
    outline: 0;
    font-size: ${styles.Spacing.S};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    width: 100%;
    margin-bottom: ${styles.Spacing.XXS};
`

const CaretWrapper = styled.div`
    height: 100%;
    margin-right: ${styles.Spacing.XS};
`

const StyledCaretDown = styled(CaretDown)`
    width: 10px;
    height: 5px;
`

type ButtonProps = {
    color?: string;
}

const Button = styled.button<ButtonProps>`
    height: ${styles.Spacing.M};
    border: 0;
    flex: 1;
    background-color: ${props => props.color || styles.Color.TaekusPurple};
    color: ${styles.Color.White};
    white-space: nowrap;
`

const MobileCardsContainer = styled.div`
    width: 100%;
    height: 1;
    min-height: 100vh;
    flex: 1;
    padding: 0 ${styles.Spacing.XS};
`

const SpinnerWrapper = styled(motion.div)`
    height: 400px;
    width: 100%;
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const SpinnerContainer = styled.div`
`

const Checkbox = styled.input`
    border-radius: ${styles.BorderRadius.S};
    width: 18px;
    height: 18px;
`

const NewCardButton = styled.div`
    background-color: ${styles.Color.NearBlack};
    font-weight: ${styles.Font.Weight[400]};
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FAF8EE;
    font-style: normal;
    font-size: ${styles.Font.Size.Small};
    line-height: 138%;
    cursor: pointer;
    ${styles.MediaQueries.Desktop} {
        margin-top: 16px;
        width: 160px;
    }
    ${styles.MediaQueries.Mobile} {
        margin: 0 ${styles.Spacing.XS};
        flex: 1;
    }
`

const SelectAllButton = styled.div`
    font-family: ${styles.Font.Family.MonumentGrotesk};
    cursor: pointer;
    color: ${styles.Color.TaekusPurple};
    user-select: none;
    &:hover {
        text-decoration: underline;
    }
`

const ViewIconsContainer = styled.div`
    display: flex;
    align-items: center;
`

const DownloadIconContainer = styled.div`
    height: 24px;
    border: 2px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 4px ${styles.Spacing.S};
    padding: 4px;
    cursor: pointer;
    &:hover {
        color: ${styles.Color.TaekusPurple};
        border-color: ${styles.Color.TaekusPurple};
    }
`

type ViewIconWrapperProps = {
    isSelected: boolean
}

const ViewIconWrapper = styled.div<ViewIconWrapperProps>`
    opacity: ${props => props.isSelected ? 1 : 0.25};
    height: ${styles.Spacing.S};
    width: ${styles.Spacing.S};
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 4px;
    cursor: pointer;
`

const ListViewContainer = styled.div`
    padding-top: ${styles.Spacing.S};
    display: flex;
    flex-direction: column;
    height: 100%;
`

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid ${styles.Color.Grey};
    ${styles.MediaQueries.Desktop} {
        margin: 0;
    }
    ${styles.MediaQueries.Mobile} {
        max-width: 100%;
        margin: 0 ${styles.Spacing.XS};
    }
`

type WidthProps = {
    width: string
}

const CardHeader = styled.div<WidthProps>`
    min-width: ${props => props.width};
    font-family: ${styles.Font.Family.MonumentGrotesk};
    color: ${styles.Color.Black};
    font-weight: ${styles.Font.Weight[400]};
    font-style: normal;
    font-size: ${styles.Font.Size.Small};
    line-height: 140%;
    /* identical to box height, or 20px */
    letter-spacing: 0.02em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

const FilterLabel = styled.label`
    color: ${styles.Color.Black};
    flex: 1;
    font-weight: ${styles.Font.Weight[400]};
    margin-left: ${styles.Spacing.XS};
    margin-bottom: 0;
    font-style: normal;
    font-size: ${styles.Font.Size.Small};
    line-height: 140%;
    letter-spacing: 0.02em;
`

const Flex = styled.div`
    display: flex;
    align-items: center;
    ${styles.Animation.transitionStyles}
    &:not(&:last-child) {
        margin-bottom: ${styles.Spacing.XS};   
    }
`

const FilterTitle = styled.div`
    color: ${styles.Color.Black};
    margin-bottom: ${styles.Spacing.XS};
    font-weight: ${styles.Font.Weight[400]};
    font-style: normal;
    font-size: ${styles.Font.Size.Small};
    line-height: 140%;
    letter-spacing: 0.02em;
`

const LineBreak = styled.hr`
    border-top: 1px solid ${styles.Color.Grey};
    margin: ${styles.Spacing.XS} 0;
    width: 160px;
`

const CardSearch = styled.input`
    border: 1px solid ${styles.Color.Grey};
    padding: ${styles.Spacing.XS};
    color: ${styles.Color.Black};
    
    font-weight: ${styles.Font.Weight[400]};
    width: 160px;
    outline: none;
    font-style: normal;
    font-size: ${styles.Font.Size.Small};
    line-height: 138%;
    letter-spacing: 0.02em;
    ${styles.MediaQueries.Desktop} {
        margin-bottom: ${styles.Spacing.S};
    }
`

const StyledGrid = styled(Grid)`
    ${styles.Scrollbar.defaultScrollbarStyles}
`

const Title = styled.div`
    font-weight: ${styles.Font.Weight[400]};
    font-style: normal;
    font-size: ${styles.Font.Size.Medium};
`

const Container = styled.div`
    background-color: ${styles.Color.White};
    height: 100%;
    background-size: cover;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    ${styles.Scrollbar.defaultScrollbarStyles}
`

const Sidebar = styled.div`
    width: ${SIDEBAR_WIDTH}px;
    padding-left: ${styles.Spacing.M};
    padding-right: ${styles.Spacing.M};
    display: flex;
    flex-direction: column;
    padding-top: 48px;
`

const Content = styled.div`
    flex: 1 1 auto;
    display: flex;
    ${styles.MediaQueries.Desktop} {
        margin-top: ${styles.Spacing.M};
        padding-right: ${styles.Spacing.M};
    }
    ${styles.MediaQueries.Mobile} {
        margin-top: ${styles.Spacing.XS};
        flex-direction: column;
    }
`

const CardsContainer = styled.div`
    flex: 1;
    display: flex;
    min-height: 100vh;
    flex-direction: column;
`

export default Cards